/* eslint-disable operator-linebreak */
import { IAlgoliaWorkingSiteModel, IAssignmentModel, ITagModel, IWorkingSiteModel, StatusCode } from '@sultan/shared'
import { createQrCodeCallable, db, usersCollection, workingSitesCollection, workingSitesDocumentsCollection, workingSitesIndex } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IWorkingSitesStoreState } from '../interfaces'
import { v4 as UUID } from 'uuid'
import firebase from 'firebase'

const hitsPerPage = 15

export const WorkingSitesStore: Module<IWorkingSitesStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    workingSite: undefined as IWorkingSiteModel | undefined,
    workingSites: [] as IWorkingSiteModel[],
    workingSitesSearchQuery: '',
    workingSitesPage: 0
  },

  mutations: {
    ...vuexfireMutations,

    setWorkingSites (state, { workingSites, page }) {
      console.log('WorkingSitesStore - setWorkingSites', { workingSites, page })
      state.workingSites = workingSites
      state.workingSitesPage = page
    },
    setWorkingSitesSearchQuery (state, workingSitesSearchQuery) {
      state.workingSitesSearchQuery = workingSitesSearchQuery
    },
    resetWorkingSite (state, workingSites) {
      state.workingSites = workingSites
      state.workingSitesSearchQuery = ''
      state.workingSitesPage = 0
    }
  },

  actions: {
    async reloadWorkingSites (
      context: ActionContext<IWorkingSitesStoreState, unknown>,
      params?: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number
      }
    ): Promise<boolean> {
      console.log('WorkingSitesStore - reloadWorkingSites', { params })
      try {
        const result = await workingSitesIndex.search(
          '',
          {
            // eslint-disable-next-line eqeqeq
            hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined ? params.facetFilters : undefined,
            cacheable: false
          }
        )
        console.log('WorkingSitesStore - result', { result })
        context.commit('resetWorkingSite', result.hits)
      } catch (error) {
        console.error('WorkingSitesStore - error searching inside the workingSites index', { context, error })
        return false
      }

      return true
    },

    async paginateWorkingSites (
      context: ActionContext<IWorkingSitesStoreState, unknown>,
      params: { forceReload?: boolean, query: string }
    ): Promise<void> {
      console.log('WorkingSitesStore - paginateWorkingSites', params)
      context.commit('setWorkingSitesSearchQuery', params.query)

      const page = params.query === context.state.workingSitesSearchQuery && !params.forceReload
        ? context.state.workingSitesPage + 1
        : 0

      try {
        const result = await workingSitesIndex.search(params.query, { hitsPerPage: hitsPerPage, page, cacheable: false })
        console.log('WorkingSitesStore - paginateWorkingSites - result', result)
        context.commit(
          'setWorkingSites',
          {
            page,
            workingSites: params.forceReload === true ? result.hits : [...context.state.workingSites, ...result.hits]
          }
        )
      } catch (error) {
        console.error('WorkingSitesStore - error searching inside the workingSites index', { context, params, error })
      }
    },

    // WorkingSite
    getWorkingSite: firestoreAction(async (context, id: string): Promise<IWorkingSiteModel | undefined> => {
      console.debug('getWorkingSite', { id })

      try {
        const result = await workingSitesCollection.doc(id).get()
        console.log('WorkingSitesStore - instantWorkingSites - result', result)

        if (!result.exists) { return undefined }

        return result.data() as IWorkingSiteModel
      } catch (error) {
        console.error('WorkingSitesStore - getWorkingSite - error getting a working site', { id, error })
        console.error(error)

        return undefined
      }
    }),
    bindWorkingSiteRef: firestoreAction((context, payload) => {
      console.debug(payload)
      console.debug('bindWorkingSiteRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('workingSite', workingSitesCollection.doc(payload.id))
    }),
    unbindWorkingSiteRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindWorkingSiteRef')
      unbindFirestoreRef('workingSite')
    }),
    createNewWorkingSite: firestoreAction(async (context, payload: {
      startTs: number,
      endTs?: number,
      name: string,
      address: string,
      addressLatitude?: number,
      addressLongitude?: number,
      customer?: string,
      link?: string,
      orderNumber?: string,
      isSpecialWorkingSite: boolean,
      userIds: string[],
      foremanIds: string[]
    }): Promise<IWorkingSiteModel | undefined> => {
      console.debug('createWorkingSite')
      if (payload === undefined || payload.name === undefined) { return }

      const workingSite: IWorkingSiteModel = {
        name: payload.name,
        startTs: payload.startTs,
        id: UUID(),
        createdTs: (new Date()).valueOf() / 1000,
        isArchived: false,
        tags: {},
        requiredTools: {},
        isSpecialWorkingSite: payload.isSpecialWorkingSite,
        address: {
          address: payload.address,
          lat: payload.addressLatitude,
          lng: payload.addressLongitude
        }
      }

      // eslint-disable-next-line eqeqeq
      if (payload.endTs != undefined) {
        workingSite.endTs = payload.endTs
      }
      if (payload.customer !== undefined && payload.customer.length > 0) {
        workingSite.customer = payload.customer
      }
      if (payload.link !== undefined && payload.link.length > 0) {
        workingSite.link = payload.link
      }
      if (payload.orderNumber !== undefined && payload.orderNumber.length > 0) {
        workingSite.orderNumber = payload.orderNumber
      }

      const batch = db.batch()

      // Create the Working Site
      batch.set(workingSitesCollection.doc(workingSite.id), workingSite)

      // Assign user to the Working Site
      for (const userId of payload.userIds) {
        batch.update(
          usersCollection.doc(userId),
          {
            [`workSiteAssignments.${workingSite.id}`]: {
              id: workingSite.id,
              createdTs: (new Date()).valueOf() / 1000,
              userId: userId,
              isForeman: payload.foremanIds.includes(userId),
              isEnabled: true
            } as IAssignmentModel
          }
        )
      }

      await batch.commit()

      console.debug('createWorkingSite - success')

      return workingSite as any as IWorkingSiteModel
    }),
    updateWorkingSite: firestoreAction(async (context, payload: {
      id: string,
      startTs?: number,
      endTs?: number,
      name?: string,
      address?: string,
      isArchived?: boolean,
      addressLatitude?: number,
      addressLongitude?: number,
      customer?: string,
      link?: string,
      orderNumber?: string,
      isSpecialWorkingSite?: boolean,
      userIds?: string[],
      removedUserIds?: string[],
      foremanIds?: string[]
    }): Promise<boolean> => {
      console.debug('updateWorkingSite', { payload })
      if (payload === undefined || payload.id === undefined) { return false }

      const workingSite: { [key: string]: any } = {
        id: payload.id
      }

      if (payload.name !== undefined) { workingSite.name = payload.name }
      if (payload.address !== undefined) { workingSite['address.address'] = payload.address }
      if (payload.addressLatitude !== undefined && payload.addressLongitude !== undefined) {
        workingSite['address.lat'] = payload.addressLatitude
        workingSite['address.lng'] = payload.addressLongitude
      }
      if (payload.customer !== undefined) { workingSite.customer = payload.customer }
      if (payload.link !== undefined) { workingSite.link = payload.link }
      if (payload.orderNumber !== undefined) { workingSite.orderNumber = payload.orderNumber }
      if (payload.isSpecialWorkingSite !== undefined) { workingSite.isSpecialWorkingSite = payload.isSpecialWorkingSite }
      if (payload.startTs !== undefined) { workingSite.startTs = payload.startTs }
      if (payload.isArchived !== undefined) { workingSite.isArchived = payload.isArchived }
      if (payload.endTs !== undefined) { workingSite.endTs = payload.endTs }

      const batch = db.batch()

      // return the promise so we can await this action
      batch.update(
        workingSitesCollection.doc(workingSite.id),
        workingSite
      )

      if (payload.userIds !== undefined && payload.foremanIds !== undefined) {
        // Assign user to the Working Site
        for (const userId of payload.userIds) {
          batch.update(
            usersCollection.doc(userId),
            {
              [`workSiteAssignments.${workingSite.id}`]: {
                id: workingSite.id,
                createdTs: (new Date()).valueOf() / 1000,
                userId: userId,
                isForeman: payload.foremanIds.includes(userId),
                isEnabled: true
              } as IAssignmentModel
            }
          )
        }
      }

      if (payload.removedUserIds !== undefined) {
        // Assign user to the Working Site
        for (const userId of payload.removedUserIds) {
          batch.update(
            usersCollection.doc(userId),
            {
              [`workSiteAssignments.${workingSite.id}`]: firebase.firestore.FieldValue.delete()
            }
          )
        }
      }

      await batch.commit()

      console.debug('updateWorkingSite - success')

      return true
    }),
    addWorkingSiteTag: firestoreAction(async (context, payload: {
      workingSiteId: string,
      name: string
    }): Promise<ITagModel | undefined> => {
      console.debug('addWorkingSiteTag', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.name === undefined
      ) { return undefined }

      const tag: ITagModel = {
        id: UUID(),
        createdTs: (new Date()).valueOf() / 1000,
        name: payload.name
      }

      workingSitesCollection.doc(payload.workingSiteId).update({
        [`tags.${tag.id}`]: tag
      })

      console.debug('addWorkingSiteTag - success')

      return tag
    }),
    updateWorkingSiteTag: firestoreAction(async (context, payload: {
      workingSiteId: string,
      tagId: string
      name: string
    }): Promise<boolean> => {
      console.debug('updateWorkingSiteTag', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.tagId === undefined
        || payload.name === undefined
      ) { return false }

      workingSitesCollection.doc(payload.workingSiteId).update({
        [`tags.${payload.tagId}.name`]: payload.name
      })

      console.debug('updateWorkingSiteTag - success')

      return true
    }),
    deleteWorkingSiteTag: firestoreAction(async (context, payload: {
      workingSiteId: string,
      tagId: string
    }): Promise<boolean> => {
      console.debug('deleteWorkingSiteTag', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.tagId === undefined
      ) { return false }

      const documents = await workingSitesDocumentsCollection(payload.workingSiteId).get()

      const batch = db.batch()

      batch.update(
        workingSitesCollection.doc(payload.workingSiteId),
        {
          [`tags.${payload.tagId}`]: firebase.firestore.FieldValue.delete()
        }
      )

      for (const document of documents.docs) {
        const docData = document.data()

        if (docData.tagIds !== undefined && docData.tagIds.includes(payload.tagId)) {
          batch.update(
            document.ref,
            { tagIds: firebase.firestore.FieldValue.arrayRemove(payload.tagId) }
          )
        }
      }

      await batch.commit()

      console.debug('deleteWorkingSiteTag - success')

      return true
    }),
    addWorkingSiteTiming: firestoreAction(async (context, payload: {
      workingSiteId: string,
      timing: number
    }): Promise<boolean> => {
      console.debug('WorkingSitesStore - addWorkingSiteTiming', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.timing === undefined
        || typeof payload.timing !== 'number'
        || Number.isNaN(payload.timing)
      ) { return false }
      try {
        workingSitesCollection.doc(payload.workingSiteId).update({
          timings: firebase.firestore.FieldValue.arrayUnion(payload.timing)
        })
      } catch (error) {
        console.error('WorkingSitesStore - addWorkingSiteTiming - error adding a working site timing', { context, payload, error })
        return false
      }

      console.debug('WorkingSitesStore - addWorkingSiteTiming - success')

      return true
    }),
    deleteWorkingSiteTiming: firestoreAction(async (context, payload: {
      workingSiteId: string,
      timing: number
    }): Promise<boolean> => {
      console.debug('WorkingSitesStore - deleteWorkingSiteTiming', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.timing === undefined
      ) { return false }

      try {
        await workingSitesCollection.doc(payload.workingSiteId).update({
          timings: firebase.firestore.FieldValue.arrayRemove(payload.timing)
        })
      } catch (error) {
        console.error('WorkingSitesStore - deleteWorkingSiteTiming - error deleting the working site Timing', { context, payload, error })
        return false
      }

      console.debug('WorkingSitesStore - deleteWorkingSiteTiming - success')

      return true
    }),
    addWorkingSitePpe: firestoreAction(async (context, payload: {
      workingSiteId: string,
      ppe: string
    }): Promise<boolean> => {
      console.debug('WorkingSitesStore - addWorkingSiteTag', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.ppe === undefined
      ) { return false }

      try {
        workingSitesCollection.doc(payload.workingSiteId).update({
          ppe: firebase.firestore.FieldValue.arrayUnion(payload.ppe)
        })
      } catch (error) {
        console.error('WorkingSitesStore - addWorkingSiteTag - error adding a working site PPE', { context, payload, error })
        return false
      }

      console.debug('WorkingSitesStore - addWorkingSiteTag - success')

      return true
    }),
    deleteWorkingSitePpe: firestoreAction(async (context, payload: {
      workingSiteId: string,
      ppe: string
    }): Promise<boolean> => {
      console.debug('WorkingSitesStore - deleteWorkingSitePpe', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.ppe === undefined
      ) { return false }

      try {
        await workingSitesCollection.doc(payload.workingSiteId).update({
          ppe: firebase.firestore.FieldValue.arrayRemove(payload.ppe)
        })
      } catch (error) {
        console.error('WorkingSitesStore - deleteWorkingSitePpe - error deleting the working site PPE', { context, payload, error })
        return false
      }

      console.debug('WorkingSitesStore - deleteWorkingSitePpe - success')

      return true
    }),
    updateWorkingSiteAssignmentStatus: firestoreAction(async (context, payload: {
      workingSiteId: string,
      userId: string,
      isEnabled: boolean
    }): Promise<boolean> => {
      console.debug('updateWorkingSiteAssignmentStatus', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.userId === undefined
        || payload.isEnabled === undefined
      ) { return false }

      usersCollection.doc(payload.userId).update({
        [`workSiteAssignments.${payload.workingSiteId}.isEnabled`]: payload.isEnabled
      })

      console.debug('updateWorkingSiteAssignmentStatus - success')

      return true
    }),
    removeWorkingSiteAssignment: firestoreAction(async (context, payload: {
      workingSiteId: string,
      userId: string
    }): Promise<boolean> => {
      console.debug('removeWorkingSiteAssignment', { payload })
      if (payload === undefined || payload.workingSiteId === undefined || payload.userId === undefined) { return false }

      usersCollection.doc(payload.userId).update({
        [`workSiteAssignments.${payload.workingSiteId}`]: firebase.firestore.FieldValue.delete()
      })

      console.debug('removeWorkingSiteAssignment - success')

      return true
    }),

    async instantWorkingSites (
      context, ids: string[]
    ): Promise<IAlgoliaWorkingSiteModel[]> {
      console.log('WorkingSitesStore - instantWorkingSites', { ids })

      try {
        const result = await workingSitesIndex.getObjects(ids, { cacheable: false })
        console.log('WorkingSitesStore - instantWorkingSites - result', result)

        return result.results as IAlgoliaWorkingSiteModel[]
      } catch (error) {
        console.error('WorkingSitesStore - instantWorkingSites - error getting the users from the index', { context, ids, error })
        console.error(error)

        return []
      }
    },

    async createQrCode (
      context: ActionContext<IWorkingSitesStoreState, unknown>
    ): Promise<{ code: StatusCode; jwt?: string, image?: string } | undefined> {
      console.debug('WorkingSitesStore - createQRCode', { context })

      if (context.state.workingSite === undefined) { return undefined }

      const payload: any = {
        i: context.state.workingSite.id,
        n: context.state.workingSite.name
      }

      // eslint-disable-next-line eqeqeq
      if (context.state.workingSite.address != undefined) {
        if ((context.state.workingSite.address as any).address !== undefined) {
          payload.a = (context.state.workingSite.address as any).address
        }
        if ((context.state.workingSite.address as any).lat !== undefined) {
          payload.lat = (context.state.workingSite.address as any).lat
        }
        if ((context.state.workingSite.address as any).lng !== undefined) {
          payload.lng = (context.state.workingSite.address as any).lng
        }
      }

      // eslint-disable-next-line eqeqeq
      if (context.state.workingSite.ppe != undefined) {
        payload.ppe = context.state.workingSite.ppe
      }

      // eslint-disable-next-line eqeqeq
      if (context.state.workingSite.timings != undefined) {
        payload.timings = context.state.workingSite.timings
      }

      // unsubscribe can be called to stop listening for changes
      const result = await createQrCodeCallable(payload)

      console.debug('WorkingSitesStore - createQRCode - result', { result })

      return result.data
    }
  }
}
