
import { defineComponent } from 'vue'
import { AssignUser, UserAssignedTool, MenuButton } from '@/components'
import { AssignToolItem } from '@/components/dialogs'
import { IAssignmentModel, IToolItemModel } from '@sultan/shared'

export default defineComponent({
  name: 'UserAssignedTools',

  components: {
    AssignUser, UserAssignedTool, MenuButton, AssignToolItem
  },

  props: {
    modelValue: {
      type: Array as () => IAssignmentModel[],
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isShowingAddAssignment: false,
      assignedToolItems: [] as Array<IAssignmentModel & { toolItem: IToolItemModel }>
    }
  },

  mounted () {
    console.log('UserAssignedTools - mounted', { modelValue: this.modelValue })
    this.enrichToolItemsData()
  },

  methods: {
    enrichToolItemsData: async function () {
      try {
        console.log('UserAssignedTools - mounted', { ids: (this as any).modelValue.map((mv: any) => mv.id) })
        const toolItems = await this.$store.dispatch(
          'tools/instantGetToolItems', (this as any).modelValue.map((mv: any) => mv.id)
        )
        console.log('UserAssignedTools - mounted - tool items', { toolItems })

        this.assignedToolItems = (this as any).modelValue.map((mv: any) => {
          const toolItem = toolItems.find((ws: any) => ws.id === mv.id)
          if (toolItem === undefined) { return mv }

          return { ...mv, toolItem }
        })
      } catch (error) {
        console.log('error fetching the user assigned tool items')
      }
    }
  },

  watch: {
    modelValue: {
      handler: async function (value) {
        console.log(value)
        await this.enrichToolItemsData()
      },
      deep: true,
      immediate: true
    }
  }
})
