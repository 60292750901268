/* eslint-disable operator-linebreak */
import { IAlgoliaClockingInModel, IClockingInModel } from '@sultan/shared'
import { clockingInsIndex, usersClockingInCollection } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { vuexfireMutations } from '@xquick-code/vuexfire'
import { IClockingInsStoreState } from '../interfaces'

const hitsPerPage = 15

export const ClockingInsStore: Module<IClockingInsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    clockingIn: undefined as IClockingInModel| undefined,
    clockingIns: [] as IClockingInModel[],
    clockingInsSearchQuery: '',
    clockingInsPage: 0
  },

  mutations: {
    ...vuexfireMutations,

    setClockingIns (state, { clockingIns, page }) {
      console.log('ClockingInsStore - setClockingIns', { clockingIns, page })
      state.clockingIns = clockingIns
      state.clockingInsPage = page
    },
    setClockingInsSearchQuery (state, clockingInsSearchQuery) {
      state.clockingInsSearchQuery = clockingInsSearchQuery
    },
    resetClockingIn (state, clockingIns) {
      state.clockingIns = clockingIns
      state.clockingInsSearchQuery = ''
      state.clockingInsPage = 0
    }
  },

  actions: {
    async reloadClockingIns (
      context: ActionContext<IClockingInsStoreState, unknown>,
      params: { facetFilters?: string | readonly string[] | undefined }
    ): Promise<boolean> {
      console.log('ClockingInsStore - reloadClockingIns')
      try {
        const result = await clockingInsIndex.search('', { hitsPerPage: hitsPerPage, cacheable: false, facetFilters: params.facetFilters })
        context.commit('resetClockingIn', result.hits)
      } catch (error) {
        console.error('ClockingInsStore - error searching inside the clockingIns index', { context, error })
        return false
      }

      return true
    },

    async paginateClockingIns (
      context: ActionContext<IClockingInsStoreState, unknown>,
      params: { forceReload?: boolean, query: string, facetFilters?: string | readonly string[] | undefined, }
    ): Promise<void> {
      console.log('ClockingInsStore - paginateClockingIns', params)
      context.commit('setClockingInsSearchQuery', params.query)

      const page = params.query === context.state.clockingInsSearchQuery && !params.forceReload
        ? context.state.clockingInsPage + 1
        : 0

      try {
        const result = await clockingInsIndex.search(params.query, { hitsPerPage: hitsPerPage, page, cacheable: false, facetFilters: params.facetFilters })
        console.log('ClockingInsStore - paginateClockingIns - result', result)
        context.commit(
          'setClockingIns',
          {
            page,
            clockingIns: params.forceReload === true ? result.hits : [...context.state.clockingIns, ...result.hits]
          }
        )
      } catch (error) {
        console.error('ClockingInsStore - error searching inside the clockingIns index', { context, params, error })
      }
    },

    async instantSearchClockingIns (
      context: ActionContext<IClockingInsStoreState, unknown>,
      params: {
        query: string,
        facetFilters?: string | readonly string[] | undefined,
        hitsPerPage?: number | undefined,
        page?: number | undefined
      }
    ): Promise<IAlgoliaClockingInModel[]> {
      console.log('ClockingInsStore - instantSearchClockingIns', params)

      try {
        const result = await clockingInsIndex.search(
          params.query,
          {
            facetFilters: params.facetFilters,
            hitsPerPage: params.hitsPerPage,
            page: params.page,
            cacheable: false
          }
        )
        console.log('ClockingInsStore - instantSearchClockingIns - result', result)

        return result.hits as IAlgoliaClockingInModel[]
      } catch (error) {
        console.error('ClockingInsStore - instantSearchClockingIns - error searching inside the users index', { context, params, error })

        return []
      }
    },

    async instantGetUserClockingInsInPeriod (
      context: ActionContext<IClockingInsStoreState, unknown>,
      params: {
        userId: string,
        from: Date,
        to: Date
      }
    ): Promise<IClockingInModel[]> {
      console.log('ClockingInsStore - instantGetClockingInsInPeriod', params)

      try {
        const from = params.from.valueOf() / 1000
        const to = params.to.valueOf() / 1000
        console.log('ClockingInsStore - instantGetClockingInsInPeriod', { from, to })
        const result = await usersClockingInCollection(params.userId)
          .where('createdTs', '>=', from)
          .where('createdTs', '<=', to)
          .get()
        console.log('ClockingInsStore - instantSearchClockingIns - result', result)

        return result.docs.map(d => d.data())
      } catch (error) {
        console.error('ClockingInsStore - instantSearchClockingIns - error searching inside the users index', { context, params, error })

        return []
      }
    }
  }
}
