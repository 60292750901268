import { IEventCheckModel, IEventModel } from '@sultan/shared'
import { eventChecksCollectionGroup, eventsCollection } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IEventsStoreState } from '@/interfaces/events-store-state.interface'
import { v4 as UUID } from 'uuid'
import firebase from 'firebase'
import moment from 'moment'

export const EventsStore: Module<IEventsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    event: undefined as IEventModel | undefined,
    events: [] as IEventModel[],
    eventsSearchQuery: '',
    eventsPage: 0,
    periodUom: 'month',
    selectedDate: new Date()
  },

  mutations: {
    ...vuexfireMutations,
    setEventsQueryParams (state, { periodUom, selectedDate }) {
      console.log('EventsStore - setEvents', { periodUom, selectedDate })
      state.periodUom = periodUom
      state.selectedDate = selectedDate
    },
    setEvents (state, { events }) {
      console.log('EventsStore - setEvents', { events })
      state.events = events
    },
    resetEvent (state) {
      state.events = []
    }
  },

  actions: {
    async queryEvents (
      context: ActionContext<IEventsStoreState, unknown>,
      params: {
        periodUom: 'month' | 'year' | 'week',
        selectedDate: Date
      }
    ): Promise<void> {
      console.log('EventsStore - queryEvents')
      this.commit('events/setEventsQueryParams', { periodUom: params.periodUom, selectedDate: params.selectedDate })
      this.dispatch('events/fetchEvents')
    },

    async fetchEvents (context: ActionContext<IEventsStoreState, unknown>): Promise<void> {
      console.log('EventsStore - fetchEvents')
      this.commit('events/setEventsQueryParams', { periodUom: context.state.periodUom, selectedDate: context.state.selectedDate })

      let eventDocuments: IEventModel[] = []

      try {
        let firstDayOfMonth = 0
        let lastDayOfMonth = 0
        switch (context.state.periodUom) {
          case 'year': {
            firstDayOfMonth = moment(context.state.selectedDate).startOf('year').set('hour', 0).set('minute', 0).set('second', 0).unix()
            lastDayOfMonth = moment(context.state.selectedDate).endOf('year').set('hour', 23).set('minute', 59).set('second', 59).unix()
            break
          }
          case 'week': {
            firstDayOfMonth = moment(context.state.selectedDate).startOf('isoWeek').set('hour', 0).set('minute', 0).set('second', 0).unix()
            lastDayOfMonth = moment(context.state.selectedDate).endOf('isoWeek').set('hour', 23).set('minute', 59).set('second', 59).unix()

            break
          }
          case 'month': {
            firstDayOfMonth = moment(context.state.selectedDate).startOf('month').set('hour', 0).set('minute', 0).set('second', 0).unix()
            lastDayOfMonth = moment(context.state.selectedDate).endOf('month').set('hour', 23).set('minute', 59).set('second', 59).unix()

            break
          }
          default: {}
        }
        console.log(moment.unix(firstDayOfMonth).format())
        console.log(moment.unix(lastDayOfMonth).format())

        eventDocuments = (await eventsCollection
          .orderBy('startTs', 'desc')
          .where('startTs', '>=', firstDayOfMonth)
          .where('startTs', '<=', lastDayOfMonth)
          .get()
        )
          .docs
          .map(doc => doc.data() as IEventModel)

        eventDocuments = [
          ...eventDocuments,
          ...(await eventsCollection
            .orderBy('endTs', 'desc')
            .where('endTs', '>=', firstDayOfMonth)
            .get()
          )
            .docs
            .map(doc => doc.data() as IEventModel)
        ]

        eventDocuments = eventDocuments.reduce(
          (acc: IEventModel[], ev: IEventModel): IEventModel[] => {
            if (acc.find(e => e.id === ev.id) !== undefined) { return acc }
            acc.push(ev)
            return acc
          },
          []
        )
      } catch (error) {
        console.error('EventsStore - error fetching the events', { context, error })
      }

      context.commit('setEvents', { events: eventDocuments })
    },

    // Event
    bindEventRef: firestoreAction((context, payload) => {
      console.debug(payload)
      console.debug('bindEventRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('event', eventsCollection.doc(payload.id))
    }),
    unbindEventRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindEventRef')
      unbindFirestoreRef('event')
    }),

    createNewEvent: firestoreAction(async (context, payload: {
      title: string;
      startDate: Date;
      endDate?: Date;
      address?: string;
      addressLatitude?: number;
      addressLongitude?: number;
      isGlobal:boolean;
      description?: string;
      recipients?: string[];
    }): Promise<IEventModel | undefined> => {
      console.debug('createEvent', { payload })
      if (payload === undefined || payload.title === undefined) { return }

      const eventDocument: Partial<IEventModel> | any = {
        title: payload.title,
        startTs: Math.round(payload.startDate.valueOf() / 1000),
        id: UUID(),
        // recipients: payload.recipients,
        createdTs: (new Date()).valueOf(),
        isGlobal: payload.isGlobal
      }

      if (payload.endDate !== undefined) {
        eventDocument.endTs = Math.round(payload.endDate.valueOf() / 1000)
      }
      if (payload.description !== undefined && payload.description.length > 0) {
        eventDocument.description = payload.description
      }
      const address: any = {}
      if (payload.address !== undefined) {
        address.address = payload.address
      }
      if (
        payload.addressLatitude !== undefined
        && payload.addressLongitude !== undefined
      ) {
        address.lat = payload.addressLatitude
        address.lng = payload.addressLongitude
      }
      if (Object.keys(address).length > 0) {
        eventDocument.address = address
      }

      if (payload.recipients !== undefined) {
        eventDocument.recipients = payload.recipients
      }

      const updatePayload = JSON.parse(JSON.stringify(eventDocument))
      console.log(updatePayload)
      await eventsCollection.doc(eventDocument.id).set(updatePayload)

      console.debug('createWorkingSite - success')

      return eventDocument as any as IEventModel
    }),

    addEventAssignment: firestoreAction(async (context, payload: {
      eventId: string,
      userId: string
    }): Promise<boolean> => {
      console.debug('removeEventAssignment', { payload })
      if (payload === undefined || payload.eventId === undefined || payload.userId === undefined) { return false }

      await eventsCollection.doc(payload.eventId).update({
        recipients: firebase.firestore.FieldValue.arrayUnion(payload.userId)
      })

      console.debug('removeEventAssignment - success')

      return true
    }),

    removeEventAssignment: firestoreAction(async (context, payload: {
      eventId: string,
      userId: string
    }): Promise<boolean> => {
      console.debug('removeEventAssignment', { payload })
      if (payload === undefined || payload.eventId === undefined || payload.userId === undefined) { return false }

      await eventsCollection.doc(payload.eventId).update({
        recipients: firebase.firestore.FieldValue.arrayRemove(payload.userId)
      })

      console.debug('removeEventAssignment - success')

      return true
    }),

    async updateEvent (
      context: ActionContext<IEventsStoreState, unknown>,
      params: Partial<IEventModel> & {
        addressLatitude?: number,
        addressLongitude?: number
      }
    ): Promise<boolean> {
      console.debug('EventsStore - updateEvent', { context, params })

      // eslint-disable-next-line eqeqeq
      if (params.id == undefined || typeof params.id !== 'string' || params.id.length < 1) {
        return false
      }

      const evt: { [key: string]: any } = {
        id: params.id
      }

      if (params.title !== undefined) { evt.title = params.title }
      if (params.address !== undefined) { evt['address.address'] = params.address }
      if (params.addressLatitude !== undefined && params.addressLongitude !== undefined) {
        evt['address.lat'] = params.addressLatitude
        evt['address.lng'] = params.addressLongitude
      }
      if (params.startTs !== undefined) { evt.startTs = params.startTs }
      if (params.endTs !== undefined) { evt.endTs = params.endTs }
      if (params.recipients !== undefined) { evt.recipients = params.recipients }
      if (params.isGlobal !== undefined) { evt.isGlobal = params.isGlobal }
      if (params.description !== undefined) { evt.description = params.description }

      console.log('updates', evt)

      try {
        await eventsCollection.doc(params.id).update(evt)
      } catch (error) {
        console.error('EventsStore - updateEvent - error creating/updating the Firestore document', error)

        return false
      }

      return true
    },

    deleteEvent: firestoreAction(async (context, payload: {
      id: string
    }): Promise<boolean> => {
      console.debug('deleteEvent', { payload })
      if (payload === undefined || payload.id === undefined) { return false }

      await eventsCollection.doc(payload.id).delete()

      console.debug('deleteEvent - success')

      return true
    }),

    // Event Check
    getEventChecks: firestoreAction(async (context, payload: {
      id: string,
    }): Promise<IEventCheckModel[] | undefined> => {
      console.debug('getEventChecks', { payload })
      if (payload === undefined || payload.id === undefined) { return undefined }

      try {
        return (await eventChecksCollectionGroup.where('id', '==', payload.id).get())
          .docs
          .map(d => d.data() as IEventCheckModel)
      } catch (error) {
        console.error('EventsStore - getEventChecks - error getting the eventChecksCollectionGroup', { payload, error })
      }

      return undefined
    })
  }
}
