
import { defineComponent } from 'vue'
import { DeleteIcon } from '@/components/icons'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
  title: 'DeleteEventDialog',

  emits: ['close'],

  components: {
    DeleteIcon, ModalDialog, PrimaryButton, Loader
  },

  props: {
    modelValue: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  methods: {
    deleteEvent: async function () {
      if (!this.modelValue) { return }
      try {
        await this.$store.dispatch('events/deleteEvent', { id: this.modelValue })
        await this.$store.dispatch('events/fetchEvents')
        this.$emit('close')
      } catch (e) {
        console.error('error deleting the firestore event')
        console.error(e)
      }
    }
  }
})
