
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import { WorkingSiteTimingCard } from '@/components'
import { IWorkingSitesStoreState } from '@/interfaces'
import { createNamespacedHelpers } from 'vuex'
import Loader from '@/components/Loader.vue'
import { AlertType } from '@/enums'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { parseHoursAndMinutesToTimingSeconds } from '@/utilities'

const { mapState, mapActions } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSiteTimings',

  components: { LeftArrow, PrimaryButton, WorkingSiteTimingCard, Loader },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      searchQuery: '',
      isShowingUserAssignment: false,
      loading: false,
      timing: ''
    }
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      workingSite: (state: IWorkingSitesStoreState) => state.workingSite,
      // eslint-disable-next-line eqeqeq
      timings: (state: IWorkingSitesStoreState) => state.workingSite != undefined && (state.workingSite as any).timings != undefined ? (state.workingSite as any).timings : []
    }),
    id: function () {
      return this.$route.params.workingSiteId
    }
  },

  validations () {
    return {
      timing: { required, format: helpers.regex(/^([0-9]+):([0-9][0-9])$/) }
    }
  },

  watch: {
    '$route.params.workingSiteId': {
      handler: async function (id) {
        console.log(id)
        await this.bindWorkingSite()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.workingSiteId}`)
    await this.bindWorkingSite()
  },

  async beforeUnmount () {
    await this.unbindWorkingSite()
  },

  methods: {
    ...mapActions(['addWorkingSiteTiming']),
    goBack () {
      return this.$router.go(-1)
    },
    addTiming: async function () {
      console.debug('addTiming')
      this.loading = true
      const timingParsed = parseHoursAndMinutesToTimingSeconds(this.timing)
      console.debug(`addTiming - ${timingParsed}`)

      // eslint-disable-next-line eqeqeq
      if (timingParsed == undefined) {
        this.loading = false
        return
      }

      try {
        const result = await this.addWorkingSiteTiming({ timing: timingParsed, workingSiteId: this.id })

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'aggiunta ore di viaggio fallita',
            type: AlertType.error
          })
          return
        }

        this.timing = ''
        this.v$.$reset()
        await this.$store.dispatch('alerts/create', { message: 'aggiunta ore di viaggio riuscita', type: AlertType.success })
      } catch (error) {
        console.error('error creating the event', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'aggiunta ore di viaggio fallita',
          type: AlertType.error
        })
      }
    },
    bindWorkingSite: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.workingSiteId == undefined || typeof this.$route.params.workingSiteId !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('workingSites/bindWorkingSiteRef', { id: this.$route.params.workingSiteId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSiteRef')
        console.error(e)
      }
    },
    unbindWorkingSite: async function () {
      try {
        await this.$store.dispatch('workingSites/unbindWorkingSiteRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    }
  }
})
