/* eslint-disable operator-linebreak */
import { chatGroupMesssagesCollection, chatGroupsCollection, clockingInsIndex, usersCollection } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IChatStoreState, IStoreState } from '../interfaces'
import moment from 'moment'
import { v4 as UUID } from 'uuid'
import firebase from 'firebase'
import { ChatGroupType, ChatMessageType, IChatGroupModel, IMessageModel, IUserModel } from '@sultan/shared'

const hitsPerPage = 10

export const ChatStore: Module<IChatStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    chatGroups: [],
    lastPaginatedMessageRef: undefined,
    newMessages: [],
    paginatedMessages: [],
    messagesUsers: []
  },

  getters: {
    messages (state: IChatStoreState) {
      const obj: IMessageModel[] = []

      for (const msg of state.paginatedMessages) {
        obj.push(msg)
      }
      for (const msg of state.newMessages) {
        obj.push(msg)
      }

      return (Object.values(obj) as IMessageModel[])
        .sort((a, b) => a.createdTs - b.createdTs)
    }
  },

  mutations: {
    ...vuexfireMutations,

    addMessagesUsers (state, { users }) {
      console.debug('ChatStore - setMessagesUsers', { users })
      state.messagesUsers = [...state.messagesUsers, ...users]
    },
    setPaginatedMessages (state, { paginatedMessages, lastPaginatedMessageRef }) {
      console.debug('ChatStore - setPaginatedMessages', { lastPaginatedMessageRef, paginatedMessages })
      state.lastPaginatedMessageRef = lastPaginatedMessageRef
      state.paginatedMessages = paginatedMessages
        .reduce((acc, msg) => {
          if (acc.find(m => m.id === msg.id) === undefined) {
            acc.push(msg)
          }

          return acc
        }, [])
    }
  },

  actions: {
    async reloadChat (
      context: ActionContext<IChatStoreState, unknown>,
      params: { facetFilters?: string | readonly string[] | undefined }
    ): Promise<boolean> {
      console.log('ChatStore - reloadChat')
      try {
        const result = await clockingInsIndex.search('', { hitsPerPage: hitsPerPage, cacheable: false, facetFilters: params.facetFilters })
        context.commit('resetChat', result.hits)
      } catch (error) {
        console.error('ChatStore - error searching inside the clockingIns index', { context, error })
        return false
      }

      return true
    },

    async createChatGroup (
      { rootState },
      params: { name: string, memberUserIds: string[] }
    ): Promise<boolean> {
      console.log('ChatStore - createChatGroup', params)

      const authUser = (rootState as IStoreState).auth.authUser
      // eslint-disable-next-line eqeqeq
      if (authUser == undefined) {
        return false
      }

      const chatGroup: IChatGroupModel = {
        name: params.name,
        createdTs: moment().unix(),
        id: UUID(),
        isArchived: false,
        memberUserIds: params.memberUserIds,
        type: ChatGroupType.GROUP,
        createdByUserId: authUser.uid
      }

      // eslint-disable-next-line eqeqeq
      try {
        await chatGroupsCollection
          .doc(chatGroup.id)
          .set(chatGroup)

        return true
      } catch (error) {
        console.error('ChatStore - error creating the new chat group', { context, params, error })
      }

      return false
    },

    async sendMessage (
      { rootState },
      params: { text: string; chatGroupId: string; type: ChatMessageType, attachmentUrl?: string | undefined, attachmentMimeType?: string | undefined }
    ): Promise<boolean> {
      console.log('ChatStore - sendMessage', params)

      const authUser = (rootState as IStoreState).auth.authUser
      // eslint-disable-next-line eqeqeq
      if (authUser == undefined) {
        return false
      }

      const message: IMessageModel = {
        chatGroupId: params.chatGroupId,
        createdTs: moment().valueOf(),
        id: UUID(),
        createdByUserId: authUser.uid,
        type: params.type,
        attachmentUrl: params.attachmentUrl,
        mimeType: params.attachmentMimeType,
        readByUserIds: [],
        text: params.text
      }

      // eslint-disable-next-line eqeqeq
      try {
        await chatGroupMesssagesCollection(message.chatGroupId)
          .doc(message.id)
          .set(JSON.parse(JSON.stringify(message)))

        return true
      } catch (error) {
        console.error('ChatStore - error creating the new message', { context, params, error })
      }

      return false
    },

    async joinChatGroup ({ rootState }, params: { chatGroupId: string }): Promise<boolean> {
      console.log('ChatStore - joinChatGroup', params)

      const authUser = (rootState as IStoreState).auth.authUser
      // eslint-disable-next-line eqeqeq
      if (authUser == undefined) {
        console.log('ChatStore - joinChatGroup - auth user undefined')
        return false
      }

      // eslint-disable-next-line eqeqeq
      try {
        await chatGroupsCollection
          .doc(params.chatGroupId)
          .update({
            memberUserIds: firebase.firestore.FieldValue.arrayUnion(authUser.uid)
          })

        return true
      } catch (error) {
        console.error('ChatStore - error joining the chat group', { context, params, error })
      }

      return false
    },

    async fetchChatGroupUsers (
      { state, commit },
      params: { chatGroupId: string }
    ): Promise<void> {
      console.log('ChatStore - fetchChatGroupUsers', params)

      const chatGroup = state.chatGroups.find(g => g.id === params.chatGroupId)
      // eslint-disable-next-line eqeqeq
      if (chatGroup == undefined) { return }

      try {
        const users = await usersCollection.where('id', 'in', chatGroup.memberUserIds).get()

        console.log('ChatStore - fetchChatGroupUsers - result', { users })
        commit(
          'addMessagesUsers',
          { users: users.docs.map(u => u.data()) }
        )
      } catch (error) {
        console.error('ChatStore - error getting the users', { context, params, error })
      }
    },

    async paginateChatGroupMessages (
      { state, commit },
      params: { chatGroupId: string, forceReload?: boolean }
    ): Promise<{ success: boolean; complete?: boolean }> {
      console.log('ChatStore - paginateChatGroupMessages', params)

      // eslint-disable-next-line eqeqeq
      if (params.chatGroupId == undefined) { return { success: false } }

      try {
        let query = chatGroupMesssagesCollection(params.chatGroupId)
          .orderBy('createdTs', 'desc')
          .limit(hitsPerPage)

        // eslint-disable-next-line eqeqeq
        if (params.forceReload !== true && state.lastPaginatedMessageRef != undefined) {
          query = query.startAfter(state.lastPaginatedMessageRef)
        }

        const messages = await query.get()
        console.log('ChatStore - paginateChatGroupMessages - result', { messages })
        commit(
          'setPaginatedMessages',
          {
            paginatedMessages: params.forceReload
              ? messages.docs.map(d => d.data())
              : [...state.paginatedMessages, ...messages.docs.map(d => d.data())],
            lastPaginatedMessageRef: messages.docs[messages.docs.length - 1]
          }
        )

        return { success: true, complete: messages.empty }
      } catch (error) {
        console.error('ChatStore - error searching inside the clockingIns index', { context, params, error })
      }

      return { success: false }
    },

    async getWorkerChatOrCreateIt (
      { rootState, state },
      params: { user: IUserModel }
    ): Promise<IChatGroupModel | undefined> {
      console.log('ChatStore - getWorkerChatOrCreateIt', params)

      const authUser = (rootState as IStoreState).auth.authUser
      // eslint-disable-next-line eqeqeq
      if (authUser == undefined) {
        return undefined
      }

      let chatGroup: IChatGroupModel | undefined = state.chatGroups.find(c =>
        c.memberUserIds.includes(params.user.id)
        && c.type === ChatGroupType.ADMINS_WORKER_GROUP
        && c.name === `${params.user.name} ${params.user.surname}`
      )

      // eslint-disable-next-line eqeqeq
      if (chatGroup == undefined) {
        chatGroup = {
          name: `Admin - ${params.user.name ? params.user.name : ''}${params.user.name ? ' ' + (params.user.surname ? params.user.surname : '') : (params.user.surname ? params.user.surname : '')}`,
          createdTs: moment().unix(),
          id: UUID(),
          isArchived: false,
          memberUserIds: [
            authUser.uid,
            params.user.id
          ],
          type: ChatGroupType.ADMINS_WORKER_GROUP,
          createdByUserId: authUser.uid
        }

        try {
          await chatGroupsCollection
            .doc(chatGroup.id)
            .set(JSON.parse(JSON.stringify(chatGroup)))
        } catch (error) {
          console.error('ChatStore - error creating the new chat group', { context, params, error })

          return undefined
        }
      }

      // eslint-disable-next-line eqeqeq
      return chatGroup
    },

    bindChatGroupsRef: firestoreAction(async (context) => {
      console.debug('bindChatGroupsRef')
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('chatGroups', chatGroupsCollection, { reset: true })
    }),
    unbindChatGroupsRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindChatGroupsRef')
      unbindFirestoreRef('chatGroups')
    }),

    bindChatGroupMessagesRef: firestoreAction(({ bindFirestoreRef }, { chatGroupId }) => {
      console.debug('bindChatGroupMessagesRef', { chatGroupId })
      // eslint-disable-next-line eqeqeq
      if (chatGroupId == undefined) { return }
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return bindFirestoreRef(
        'newMessages',
        chatGroupMesssagesCollection(chatGroupId)
          .orderBy('createdTs', 'asc')
          .where('createdTs', '>', moment().valueOf()),
        { reset: true, wait: false }
      )
    }),
    unbindChatGroupMessagesRef: firestoreAction(({ commit, unbindFirestoreRef }) => {
      console.debug('unbindChatGroupMessagesRef')
      unbindFirestoreRef('newMessages')
      commit(
        'setPaginatedMessages',
        {
          paginatedMessages: [],
          lastPaginatedMessageRef: undefined
        }
      )
    })
  }
}
