
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import { ITimeReportEntry, IUsersStoreState } from '@/interfaces'
import { PrimaryButton } from '@/components/buttons'
import { Loader } from '@/components'
import { DownloadReportDialog } from '@/components/dialogs'
import { AlertType } from '@/enums'
import { capitalizeFirstLetter, durationHumanized, humanizedClockingInTiming, userTimeReportComputer } from '@/utilities'
import isSameDay from 'date-fns/isSameDay'
import * as XLSX from 'xlsx'

const { mapState } = createNamespacedHelpers('users')

export default defineComponent({
  name: 'Report',

  components: {
    PrimaryButton, Loader, DownloadReportDialog
  },

  data () {
    return {
      downloadReport: false,
      loading: false,
      selectedDate: {
        start: moment().subtract(1, 'months').startOf('month').toDate(),
        end: moment().subtract(1, 'months').endOf('month').toDate()
      },
      reports: [] as any[],
      tableColumns: [
        'Giorno', 'Cantiere',
        'Entrata',
        'Uscita',
        'Totole',
        'Ore viaggio',
        'Ore ordinarie',
        'Ore straordinarie 25',
        'Ore straordinarie 50',
        'Buoni pasto',
        'Entrate timbrata da',
        'Uscita timbrata da',
        'Entrata arrotondata',
        'Uscita arrotondata',
        'Totole arratondato',
        'Ore viaggio arrotondate',
        'Ore ordinarie arrotondate',
        'Ore straordinarie 25 arrotondate',
        'Ore straordinarie 50 arrotondate',
        'Buoni pasto arrotondati'
      ],
      tableOptions: {
        dateColumns: [
          'Giorno', 'Entrata', 'Entrata arrotondata', 'Uscita', 'Uscita arrotondata'
        ],
        dateFormatPerColumn: {
          Giorno: 'DD/MM/YYYY', Entrata: 'HH:mm', 'Entrata arrotondata': 'HH:mm', Uscita: 'HH:mm', 'Uscita arrotondata': 'HH:mm'
        },
        filterable: ['Cantiere'],
        sortIcon: { base: 'glyphicon', up: 'glyphicon-chevron-up', down: 'glyphicon-chevron-down', is: 'glyphicon-sort' },
        stickyHeader: true,
        texts: {
          count: 'Di {count} elementi, visibili da {from} a {to}|{count} elementi|Un elemento',
          first: 'Primo',
          last: 'Ultimo',
          filter: 'Filtra per cantiere:',
          filterPlaceholder: 'nome cantiere',
          limit: 'Elementi per pagina:',
          page: 'Pagina:',
          noResults: 'Non sono presenti elementi',
          filterBy: 'Filtra per {column}',
          loading: 'Caricamento...',
          defaultOption: 'Seleziona {column}',
          columns: 'Colonne'
        },
        pagination: {
        }
      },
      moment,
      totals: {
        workingHours: 0,
        workingHoursRounded: 0,
        overtime25: 0,
        overtime25Rounded: 0,
        overtime50: 0,
        overtime50Rounded: 0,
        foodStamps: 0,
        foodStampsRounded: 0,
        travellingSeconds: 0,
        travellingSecondsRounded: 0,
        days: 0
      },
      humanizedClockingInTiming
    }
  },

  computed: {
    ...mapState<IUsersStoreState>({
      employee: (state: IUsersStoreState) => state.user
    }),
    userId: function () { return this.$route.params.id }
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindUser()
        await this.bindUser()
        await this.computeReports()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindUser()
    await this.computeReports()
  },

  async beforeUnmount () {
    await this.unbindUser()
  },

  methods: {
    toggleEnabling: async function (enabled: boolean) {
      console.log('toggleEnabling', enabled)

      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      this.loading = true
      const logStartMessage = `${enabled ? 'abilitazione' : 'disabilitazione'} utente`
      try {
        const result = await this.$store.dispatch(
          'users/updateUser',
          {
            id: this.$route.params.id,
            isEnabled: enabled
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch('alerts/create', { message: `${logStartMessage} fallita`, type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: `${logStartMessage} riuscita`, type: AlertType.success })
        await this.$store.dispatch('users/reloadUsers')
        this.$emit('close')
      } catch (error) {
        console.error('error updating the user', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: `${logStartMessage} fallita`, type: AlertType.error })
      }
    },

    downloadSelectedReport: function () {
      // eslint-disable-next-line eqeqeq
      if ((this as any).employee == undefined) { return }

      const fileName = `${moment(this.selectedDate.start as any).format('DD/MM/YYYY')}-${moment(this.selectedDate.end as any).format('DD/MM/YYYY')}${
        String((this as any).employee.name).trim().replaceAll(' ', '.')
      }-${
        String((this as any).employee.surname).trim().replaceAll(' ', '.')
      }.xlsx`

      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
        [
          ['Dipendente', (this as any).employee.name, (this as any).employee.surname],
          ['Periodo', capitalizeFirstLetter(moment(this.selectedDate.start as any).format('DD/MM/YYYY')), moment(this.selectedDate.end as any).format('DD/MM/YYYY')],
          ['']
        ]
      )
      XLSX.utils.sheet_add_json(
        ws,
        this.reports.map(r => ({
          ...r,
          /* eslint-disable eqeqeq */
          Giorno: r != undefined && moment.isMoment(r.Giorno) ? r.Giorno.format('DD/MM/YYYY') : r.Giorno,
          Entrata: r != undefined && moment.isMoment(r.Entrata) ? r.Entrata.format('HH:mm') : r.Entrata,
          Uscita: r != undefined && moment.isMoment(r.Uscita) ? r.Uscita.format('HH:mm') : r.Uscita,
          'Entrata arrotondata': r != undefined && moment.isMoment(r['Entrata arrotondata']) ? r['Entrata arrotondata'].format('HH:mm') : r['Entrata arrotondata'],
          'Uscita arrotondata': r != undefined && moment.isMoment(r['Uscita arrotondata']) ? r['Uscita arrotondata'].format('HH:mm') : r['Uscita arrotondata']
          /* eslint-enable eqeqeq */
        })),
        { origin: -1 }
      )
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [],
          [
            'Totali', '', '', '', '', '',
            durationHumanized(this.totals.travellingSeconds, 'seconds'),
            durationHumanized(this.totals.workingHours, 'hours'),
            durationHumanized(this.totals.overtime25, 'hours'),
            durationHumanized(this.totals.overtime50, 'hours'),
            this.totals.foodStamps,
            '', '', '', '',
            durationHumanized(this.totals.travellingSecondsRounded, 'seconds'),
            durationHumanized(this.totals.workingHoursRounded, 'hours'),
            durationHumanized(this.totals.overtime25Rounded, 'hours'),
            durationHumanized(this.totals.overtime50Rounded, 'hours'),
            this.totals.foodStampsRounded
          ]
        ],
        { origin: -1 }
      )

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

      /* save to file */
      XLSX.writeFile(wb, fileName)
    },
    hideDownloadReportDialog: function () {
      this.downloadReport = false
    },

    bindUser: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('users/bindUserRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindUserRef')
        console.error(e)
      }
    },
    unbindUser: async function () {
      try {
        await this.$store.dispatch('users/unbindUserRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindUserRef')
        console.error(e)
      }
    },

    secondsToFormattedHours: function (seconds?: number): string {
      // eslint-disable-next-line eqeqeq
      return Number(seconds == undefined ? 0 : seconds / 60 / 60).toFixed(2)
    },

    computeReports: async function () {
      console.debug('computeReports - init')
      const entries = (await userTimeReportComputer({
        store: this.$store,
        userId: this.userId as string,
        startDate: moment(this.selectedDate.start as any).toDate(),
        endDate: moment(this.selectedDate.end as any).toDate()
      }))
      console.debug('computeReports - entries', { entries })
      this.reports = entries
        .map((r: ITimeReportEntry) => {
          /* eslint-disable eqeqeq */
          const dateIn = r.in != undefined ? moment(r.in) : undefined
          const dateInRounded = r.inRounded != undefined ? moment(r.inRounded) : undefined
          const dateOut = r.out != undefined ? moment(r.out) : undefined
          const dateOutRounded = r.outRounded != undefined ? moment(r.outRounded) : undefined

          return {
            Giorno: moment(r.day),
            Cantiere: r.workingSiteName,
            '': '',
            Entrata: r.in != undefined ? dateIn : '-',
            Uscita: r.out != undefined ? dateOut : '-',
            Totole: durationHumanized(r.diffMinutes, 'minutes'),
            'Ore viaggio': durationHumanized(r.travellingSeconds, 'seconds'),
            'Ore ordinarie': durationHumanized(r.workingHours, 'hours'),
            'Ore straordinarie 25': durationHumanized(r.overtime25, 'hours'),
            'Ore straordinarie 50': durationHumanized(r.overtime50, 'hours'),
            'Buoni pasto': r.foodStamps,
            ' ': '',
            'Entrate timbrata da': r.inUser ? `${r.inUser.name} ${r.inUser.surname}` : '-',
            'Uscita timbrata da': r.outUser ? `${r.outUser.name} ${r.outUser.surname}` : '-',
            '  ': '',
            'Entrata arrotondata': r.inRounded != undefined ? dateInRounded : '-',
            'Uscita arrotondata': r.outRounded != undefined ? dateOutRounded : '-',
            'Totole arratondato': durationHumanized(r.diffMinutesRounded, 'minutes'),
            'Ore viaggio arrotondate': durationHumanized(r.travellingSecondsRounded, 'seconds'),
            'Ore ordinarie arrotondate': durationHumanized(r.workingHoursRounded, 'hours'),
            'Ore straordinarie 25 arrotondate': durationHumanized(r.overtime25Rounded, 'hours'),
            'Ore straordinarie 50 arrotondate': durationHumanized(r.overtime50Rounded, 'hours'),
            'Buoni pasto arrotondati': r.foodStampsRounded
          }
          /* eslint-enable eqeqeq */
        }) as any[]

      this.totals.workingHours = 0
      this.totals.workingHoursRounded = 0
      this.totals.overtime25 = 0
      this.totals.overtime25Rounded = 0
      this.totals.overtime50 = 0
      this.totals.overtime50Rounded = 0
      this.totals.foodStamps = 0
      this.totals.foodStampsRounded = 0
      this.totals.travellingSeconds = 0
      this.totals.travellingSecondsRounded = 0
      this.totals.days = 0

      let lastDay: Date | undefined
      for (const entry of entries) {
        this.totals.foodStamps += entry.foodStamps
        this.totals.foodStampsRounded += entry.foodStampsRounded
        this.totals.workingHours += entry.workingHours
        this.totals.workingHoursRounded += entry.workingHoursRounded
        this.totals.overtime25 += entry.overtime25
        this.totals.overtime25Rounded += entry.overtime25Rounded
        this.totals.overtime50 += entry.overtime50
        this.totals.overtime50Rounded += entry.overtime50Rounded
        this.totals.travellingSeconds += entry.travellingSeconds
        this.totals.travellingSecondsRounded += entry.travellingSecondsRounded

        if (lastDay === undefined || !isSameDay(lastDay, entry.day)) {
          this.totals.days += 1
        }

        lastDay = entry.day
      }
    },
    onSelectedDateValue: function (val: any) {
      console.log('onSelectedDateValue')
      console.log(val)
      this.selectedDate = val
      this.computeReports()
    }
  }
})
