
import { defineComponent } from 'vue'
import { PrimaryButton, SecondaryButton } from '@/components/buttons'
import { RelatedDocumentsSection } from '@/components'

export default defineComponent({
  name: 'ArchivedDocumentDetails',

  components: {
    PrimaryButton, SecondaryButton, RelatedDocumentsSection
  },

  data () {
    return {
      editDocument: false
    }
  },

  methods: {
    showEditDocumentDialog: function () {
      this.editDocument = true
    },
    hideEditDocumentDialog: function () {
      this.editDocument = false
    }
  }

})
