
import { defineComponent } from 'vue'
import { Loader } from '@/components'
import { ForemanIcon, WorkerIcon } from '@/components/icons'
import { RemoveWorkingSiteUserAssignment, ChangeUserRoleDialog } from '@/components/dialogs'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'WorkingSiteAssignedUser',

  components: {
    RemoveWorkingSiteUserAssignment, Loader, ForemanIcon, WorkerIcon, ChangeUserRoleDialog
  },

  emits: ['update'],

  props: {
    workingSiteId: { type: String, required: true },
    workingSiteName: { type: String, required: true },
    userId: { type: String, required: true },
    userName: { type: String, reuired: true },
    isForeman: { type: Boolean, default: false, required: false },
    isSuspended: { type: Boolean, default: false, required: false }
  },

  data () {
    return {
      removeUser: false,
      loading: false,
      changeUserRole: false
    }
  },

  methods: {
    isChangeUserRole: function () {
      this.changeUserRole = true
    },
    hideChangeUserRole: function () {
      this.changeUserRole = false
    },
    showRemoveUserDialog: function () {
      this.removeUser = true
    },
    hideRemoveUserDialog: function () {
      this.removeUser = false

      // Add a delay to ensure that the Algolia Index is updated
      setTimeout(() => {
        // Then we reload the users
        this.$emit('update')
      }, 4000)
    },
    toggleUserAssignmentSospension: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'workingSites/updateWorkingSiteAssignmentStatus',
          {
            workingSiteId: this.workingSiteId,
            userId: this.userId,
            isEnabled: this.isSuspended // the opposite of the opposite
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch(
            'alerts/create',
            {
              message: 'errore nell\'aggiornamento dello stato di assegnazione utente al cantiere',
              type: AlertType.error
            }
          )
          return
        }

        await this.$store.dispatch(
          'alerts/create',
          {
            message: this.isSuspended
              ? 'sospensione utente dal cantiere annullata'
              : 'utente sospeso dal cantiere',
            type: AlertType.success
          }
        )
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$emit('update')
        }, 4000)
      } catch (error) {
        console.error('error updating the user assignment status', error)
        this.loading = false
        await this.$store.dispatch(
          'alerts/create',
          {
            message: 'errore nell\'aggiornamento dello stato di assegnazione utente al cantiere',
            type: AlertType.error
          }
        )
      }
    }
  }
})
