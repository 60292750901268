
import { defineComponent } from 'vue'
import { WorkingSiteAssignedUser } from '@/components'

export default defineComponent({
  name: 'ViewMoreUsers',

  components: {
    WorkingSiteAssignedUser
  },

  data () {
    return {
      showMore: false,
      hideButton: true
    }
  },

  methods: {
    showMoreUsers: function () {
      this.showMore = true
      this.hideButton = false
    }
  }

})
