import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b35535f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document bg-white relative overflow-y-scroll" }
const _hoisted_2 = {
  key: 0,
  class: "body"
}
const _hoisted_3 = { class: "title-section-spacing" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold block text-left"
}
const _hoisted_5 = {
  key: 1,
  class: "font-bold grey-text text-left subtitle"
}
const _hoisted_6 = { class: "block date" }
const _hoisted_7 = {
  key: 2,
  class: "content text-left"
}
const _hoisted_8 = { class: "options w-full text-right fixed relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!
  const _component_RelatedDocumentsSection = _resolveComponent("RelatedDocumentsSection")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_DeleteCommunicationDialog = _resolveComponent("DeleteCommunicationDialog")!
  const _component_AddEditCommunicationDialog = _resolveComponent("AddEditCommunicationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.communication)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SecondaryButton, {
                class: "edit-document text-primary block float-right text-primary block text-black-600 bg-primary-50",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowingEditDialog = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Modifica comunicazione")
                ]),
                _: 1
              }),
              (_ctx.communication.title)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.communication.title), 1))
                : _createCommentVNode("", true),
              (_ctx.communication.subtitle)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.communication.subtitle), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, "Data inserimento: " + _toDisplayString(_ctx.moment.unix(_ctx.communication.createdTs).format('DD/MM/YYYY')), 1),
              (_ctx.communication.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.communication.description), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_SecondaryButton, {
                  class: "delete text-primary block float-right text-primary block text-red-600 bg-red-100",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowingDeleteDialog = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Elimina")
                  ]),
                  _: 1
                }),
                _createVNode(_component_SecondaryButton, {
                  class: "archive text-primary block float-right text-primary block text-primary-600 bg-primary-100",
                  onClick: _ctx.onToggleArchive
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.communication.isArchived ? 'Ripristina' : 'Archivia'), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createVNode(_component_RelatedDocumentsSection, {
                modelValue: _ctx.commDocuments,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commDocuments) = $event)),
                "communication-id": _ctx.communication.id,
                scope: _ctx.DocumentScope.companyCommunicationDocument
              }, null, 8, ["modelValue", "communication-id", "scope"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          message: "Salvataggio..."
        }))
      : _createCommentVNode("", true),
    (_ctx.isShowingDeleteDialog)
      ? (_openBlock(), _createBlock(_component_DeleteCommunicationDialog, {
          key: 1,
          title: _ctx.communication.title,
          "communication-id": _ctx.communication.id,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShowingDeleteDialog = false))
        }, null, 8, ["title", "communication-id"]))
      : _createCommentVNode("", true),
    (_ctx.isShowingEditDialog)
      ? (_openBlock(), _createBlock(_component_AddEditCommunicationDialog, {
          key: 2,
          modelValue: _ctx.communication,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.communication) = $event)),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isShowingEditDialog = false))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}