
/* eslint-disable eqeqeq */
import { defineComponent } from 'vue'
import { SultanLogo } from '@/components'
import moment from 'moment'
import { IChatGroupModel } from '@sultan/shared'
import { getChatGroupName } from '@/utilities'

export default defineComponent({
  name: 'ChatGroupCard',

  components: {
    SultanLogo
  },

  props: {
    modelValue: {
      type: Object as () => IChatGroupModel,
      required: true
    }
  },

  computed: {
    chatName (): string {
      return getChatGroupName(this.modelValue)
    }
  },

  created: function () {
    (this as any).moment = moment
  }
})
