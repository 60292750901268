
import { defineComponent } from 'vue'
import { BlueDocumentIcon } from '@/components/icons'
import { AddEditDocumentModalDialog, DeleteDocumentDialog } from '@/components/dialogs'
import { IDocumentModel, ITagModel } from '@sultan/shared'
import { getFileMimeTypeSlug } from '@/utilities'
import { createNamespacedHelpers } from 'vuex'
import { AlertType, DocumentScope } from '@/enums'
const { mapActions } = createNamespacedHelpers('documents')

export default defineComponent({
  name: 'DocumentCard',

  components: {
    BlueDocumentIcon, AddEditDocumentModalDialog, DeleteDocumentDialog
  },

  emits: ['update:modelValue'],

  props: {
    scope: { type: Number as () => DocumentScope, required: true, default: DocumentScope.workingSiteDocument },
    modelValue: { type: Object as () => IDocumentModel, required: true },
    tags: { type: Object as () => { [key: string]: ITagModel }, default: {} as any, required: false },
    isDocumentToBeApproved: { type: Boolean, default: false, required: false },
    isReadOnly: { type: Boolean, default: false, required: false },

    workingSiteId: { type: String, required: false },
    communicationId: { type: String, required: false },
    userId: { type: String, required: false }
  },

  data () {
    return {
      isShowingDeleteDialog: false,
      isShowingEditDialog: false,
      loading: false
    }
  },

  methods: {
    ...mapActions(['updateDocument']),
    openDocument: function () {
      window.open(this.modelValue.documentUrl, '_blank')
    },
    openSignedDocument: function () {
      window.open(this.modelValue.signedDocument?.url, '_blank')
    },
    onApproveDocument: async function () {
      console.log('onApproveDocument')
      this.loading = true

      try {
        const result = await this.updateDocument({
          workingSiteId: this.workingSiteId,
          scope: DocumentScope.workingSiteDocument,
          document: {
            id: this.modelValue.id,
            approved: true
          }
        })
        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'approvazione documento fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'approvazione documento riuscita', type: AlertType.success })
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'approvazione documento fallita', type: AlertType.error })
      }
    }
  },

  computed: {
    modelValueTags: function (): ITagModel[] {
      // eslint-disable-next-line eqeqeq
      return this.modelValue.tagIds == undefined || this.tags == undefined
        ? []
        : this.modelValue.tagIds.reduce(
          (acc, id) => {
            if (Object.keys(this.tags!).includes(id)) {
              acc.push(this.tags![id])
            }

            return acc
          },
          [] as ITagModel[]
        )
    },
    mimeTypeSlag: function (): string { return getFileMimeTypeSlug((this.modelValue as any).mimeType) },
    documentIsSigned: function (): boolean {
      // eslint-disable-next-line eqeqeq
      return this.modelValue != undefined && this.modelValue.signedDocument != undefined
    }
  }
})
