
import { defineComponent } from 'vue'
import { SultanLogo, MenuButton, SecondaryButton, Loader } from '@/components'

export default defineComponent({
  name: 'Home',

  components: {
    SultanLogo, MenuButton, SecondaryButton, Loader
  },

  data: function () {
    return {
      loading: false
    }
  },

  methods: {
    onLogout: async function () {
      console.log('Home - onLogout')
      this.loading = true
      await this.$store.dispatch('auth/signOut')
      await this.$router.replace('/auth/login')
      this.loading = false
    },
    onMap: async function () {
      console.log('Home - onMap')
      await this.$router.push('/map')
    }
  }
})
