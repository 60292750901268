
import { defineComponent } from 'vue'
import { AssignUser } from '@/components/dialogs'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import { WorkingSiteAssignedUser } from '@/components'
import { IWorkingSitesStoreState } from '@/interfaces'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSiteAssignedUsers',

  components: { LeftArrow, PrimaryButton, WorkingSiteAssignedUser, AssignUser },

  data () {
    return {
      users: [],
      searchQuery: '',
      isShowingUserAssignment: false
    }
  },

  mounted () {
    this.search()
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      workingSite: (state: IWorkingSitesStoreState) => state.workingSite
    }),
    id: function () {
      return this.$route.params.workingSiteId
    }
  },

  watch: {
    '$route.params.workingSiteId': {
      handler: async function (id) {
        console.log(id)
        await this.bindWorkingSite()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.workingSiteId}`)
    await this.bindWorkingSite()
  },

  async beforeUnmount () {
    await this.unbindWorkingSite()
  },

  methods: {
    goBack () {
      return this.$router.go(-1)
    },
    search: async function () {
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        facetFilters: `workSiteAssignmentIds:${this.id}`,
        page: 0,
        query: this.searchQuery
      })
      console.debug('searchUsers', { users: this.users })
    },
    bindWorkingSite: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.workingSiteId == undefined || typeof this.$route.params.workingSiteId !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('workingSites/bindWorkingSiteRef', { id: this.$route.params.workingSiteId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSiteRef')
        console.error(e)
      }
    },
    unbindWorkingSite: async function () {
      try {
        await this.$store.dispatch('workingSites/unbindWorkingSiteRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },
    showUserAssignmentDialog: function () {
      console.debug('showUserAssignmentDialog')
      this.isShowingUserAssignment = true
    },
    hideUserAssignmentDialog: function () {
      // Add a delay to ensure that the Algolia Index is updated
      setTimeout(() => {
        // Then we reload the users
        this.search()
      }, 4000)
      this.isShowingUserAssignment = false
    }
  }
})
