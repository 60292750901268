import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57f1b050"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex",
  id: "calendar"
}
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCalendarViewHeader = _resolveComponent("CustomCalendarViewHeader")!
  const _component_CalendarView = _resolveComponent("CalendarView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AddEditEventModalDialog = _resolveComponent("AddEditEventModalDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["bg-white left-panel sidebar overflow-hidden text-center section h-full flex-grow", _ctx.isExactRoute])
    }, [
      _createVNode(_component_CalendarView, {
        class: "calendar-view",
        "show-date": _ctx.showDate,
        items: _ctx.events,
        "starting-day-of-week": 1,
        "enable-drag-drop": true,
        "display-period-uom": _ctx.displayPeriodUom,
        "show-times": false,
        "item-content-height": '23px',
        onClickDate: _ctx.onDayClick,
        onClickItem: _ctx.onItemClick
      }, {
        header: _withCtx(({ headerProps }) => [
          _createVNode(_component_CustomCalendarViewHeader, {
            "header-props": headerProps,
            onInput: _ctx.setShowDate,
            "display-period-uom": _ctx.displayPeriodUom,
            "onUpdate:display-period-uom": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayPeriodUom) = $event))
          }, null, 8, ["header-props", "onInput", "display-period-uom"])
        ]),
        _: 1
      }, 8, ["show-date", "items", "display-period-uom", "onClickDate", "onClickItem"])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "slide-fade",
            mode: "in-out"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    (_ctx.addEventDialogIsPresented)
      ? (_openBlock(), _createBlock(_component_AddEditEventModalDialog, {
          key: 0,
          "selected-date": _ctx.addEventDialogDate,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideNewEventDialog()))
        }, null, 8, ["selected-date"]))
      : _createCommentVNode("", true)
  ]))
}