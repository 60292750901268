
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import { DocumentCard } from '@/components'
import { AddEditDocumentModalDialog } from '@/components/dialogs'
import { createNamespacedHelpers } from 'vuex'
import { IDocumentsStoreState } from '@/interfaces'
import { IDocumentModel } from '@sultan/shared'
import { DocumentScope } from '@/enums'
const documentsHelper = createNamespacedHelpers('documents')

export default defineComponent({
  name: 'UserDocuments',

  components: {
    LeftArrow, PrimaryButton, DocumentCard, AddEditDocumentModalDialog
  },

  data () {
    return {
      searchQuery: '',
      filter: '',
      isShowingAddNewDocument: false,
      DocumentScope
    }
  },

  computed: {
    ...documentsHelper.mapState<IDocumentsStoreState>({
      documents: function (state: IDocumentsStoreState) {
        console.log('computed documents', { state })
        const s: string | undefined = (this as any).filter.trim()
        let docs = s !== undefined && s.length > 0
          ? state.documents.filter(d => d.title.trim().toLowerCase().includes(s.toLowerCase()))
          : state.documents

        docs = docs.filter((document: IDocumentModel) => (this as any).isDocumentsToBeApproved ? document.approved === false : document.approved)

        return docs
      }
    }),
    userId: function (): string | undefined {
      return this.$route.params.userId as string
    },
    isDocumentsToBeApproved: function (): boolean {
      return this.$route.path.substr(this.$route.path.length - 20) === 'unapproved-documents'
    }
  },

  watch: {
    '$route.params.userId': {
      handler: async function (id) {
        console.log(id)
        await this.getUserDocuments()
      },
      deep: true,
      immediate: true
    }
  },

  async mounted () {
    await this.getUserDocuments()
  },

  methods: {
    ...documentsHelper.mapActions(['fetchDocuments']),

    goBack () {
      return this.$router.go(-1)
    },
    search () {
      this.filter = this.searchQuery
    },

    getUserDocuments: async function () {
      console.log(`getUserDocuments: ${this.$route.params.userId}`)
      try {
        await this.fetchDocuments({ scope: DocumentScope.userDocument, userId: this.$route.params.userId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSitesRef')
        console.error(e)
      }
    }
  }

})
