
import { UserExpenseScope } from '@/enums'
import { creditCardToolId } from '@/utilities'
import { BlueDocumentIcon } from '@/components'
import { IUserModel, IWorkingSiteModel, IUserExpenseModel, IToolItemModel } from '@sultan/shared'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserExpenseCard',

  components: {
    BlueDocumentIcon
  },

  created: function () {
    (this as any).moment = moment
  },

  props: {
    scope: { type: Number as () => UserExpenseScope, required: true },
    modelValue: { type: Object as () => IUserExpenseModel, required: true }
  },

  data () {
    return {
      workingSiteName: '',
      userName: '',
      toolName: '',
      UserExpenseScope
    }
  },

  async mounted () {
    if (
      (this as any).scope === UserExpenseScope.user
      && (this as any).modelValue !== undefined
      && (this as any).modelValue.workingSiteId !== undefined
    ) {
      const fetchedWs = (this.$store.state.workingSites.workingSites as IWorkingSiteModel[]).find(w => w.id === (this as any).modelValue.workingSiteId)

      if (fetchedWs !== undefined) {
        this.workingSiteName = fetchedWs.name
      } else {
        try {
          const result = await this.$store.dispatch(
            'workingSites/getWorkingSite', this.modelValue.workingSiteId
          ) as IWorkingSiteModel
          console.log('getWorkingSiteName - result', { result })

          if (result !== undefined) {
            this.workingSiteName = result.name
          }
        } catch (error) {
          console.error('UserExpenseCard - getWorkingSiteName - error getting the working site name', { error })
          console.error(error)
        }
      }
    }
    if (
      (this as any).scope === UserExpenseScope.workingSite
      && (this as any).modelValue !== undefined
      && (this as any).modelValue.userId !== undefined
    ) {
      const fetchedWs = (this.$store.state.users.users as IUserModel[]).find(u => u.id === (this as any).modelValue.userId)

      if (fetchedWs !== undefined) {
        this.userName = `${fetchedWs.name} ${fetchedWs.surname}`
      } else {
        try {
          const result = await this.$store.dispatch(
            'users/getUser', this.modelValue.userId
          ) as IUserModel
          console.log('getUserName - result', { result })

          if (result !== undefined) {
            this.userName = `${result.name} ${result.surname}`
          }
        } catch (error) {
          console.error('UserExpenseCard - getUserName - error getting the user name and surname', { error })
          console.error(error)
        }
      }
    }
    if (
      (this as any).modelValue !== undefined
      && (this as any).modelValue.toolId !== undefined
    ) {
      const fetchedTi = (this.$store.state.tools.toolItems as IToolItemModel[]).find(ti => ti.id === (this as any).modelValue.toolId)

      if (fetchedTi !== undefined) {
        this.toolName = `${fetchedTi.primaryField} - ${fetchedTi.secondaryField}`
      } else {
        try {
          const result = await this.$store.dispatch(
            'tools/getToolItem', { toolId: creditCardToolId, id: this.modelValue.toolId }
          ) as IToolItemModel
          console.log('getToolItem - result', { result })

          if (result !== undefined) {
            this.toolName = `${result.primaryField} - ${result.secondaryField}`
          } else {
            this.toolName = 'Carta eliminata'
          }
        } catch (error) {
          console.error('UserExpenseCard - getToolItem - error getting the user name and surname', { error })
          console.error(error)
        }
      }
    }
  },

  methods: {
    onAttachmentClick: function (attachment) {
      console.log('openAttachment', this.modelValue.pictureUrls)
      window.open(attachment, '_blank')
    }
  }
})
