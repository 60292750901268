
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import { DocumentCard } from '@/components'
import { AddEditDocumentModalDialog } from '@/components/dialogs'
import { createNamespacedHelpers } from 'vuex'
import { IDocumentsStoreState, IWorkingSitesStoreState } from '@/interfaces'
import { IDocumentModel } from '@sultan/shared'
import { DocumentScope } from '@/enums'
const workingSiteHelper = createNamespacedHelpers('workingSites')
const documentsHelper = createNamespacedHelpers('documents')

export default defineComponent({
  name: 'WorkingSiteDocuments',

  components: {
    LeftArrow, PrimaryButton, DocumentCard, AddEditDocumentModalDialog
  },

  data () {
    return {
      searchQuery: '',
      filter: '',
      unapprovedDocuments: undefined as number | undefined,
      isShowingAddNewDocument: false,
      DocumentScope
    }
  },

  computed: {
    ...documentsHelper.mapState<IDocumentsStoreState>({
      documents: function (state: IDocumentsStoreState) {
        console.log('computed documents', { state })
        const s: string | undefined = (this as any).filter.trim()
        let docs = s !== undefined && s.length > 0
          ? state.documents.filter(d => d.title.trim().toLowerCase().includes(s.toLowerCase()))
          : state.documents

        docs = docs.filter((document: IDocumentModel) => (this as any).isDocumentsToBeApproved ? document.approved === false : document.approved)

        return docs
      }
    }),
    ...workingSiteHelper.mapState<IWorkingSitesStoreState>({
      tags: function (state: IWorkingSitesStoreState) {
        // eslint-disable-next-line eqeqeq
        return state.workingSite == undefined ? {} : state.workingSite.tags
      },
      workingSiteName: function (state: IWorkingSitesStoreState) {
        // eslint-disable-next-line eqeqeq
        return state.workingSite == undefined ? '' : state.workingSite.name
      }
    }),
    workingSiteId: function (): string | undefined {
      return this.$route.params.workingSiteId as string
    },
    isDocumentsToBeApproved: function (): boolean {
      return this.$route.path.substr(this.$route.path.length - 20) === 'unapproved-documents'
    }
  },

  watch: {
    '$route.params.workingSiteId': {
      handler: async function (id) {
        console.log(id)
        await this.bindWorkingSite()
        await this.getWorkingSiteDocuments()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    await this.bindWorkingSite()
    await this.fetchDocumentCouters()
    await this.getWorkingSiteDocuments()
  },

  async beforeUnmount () {
    await this.unbindWorkingSite()
  },

  methods: {
    ...workingSiteHelper.mapActions(['bindWorkingSiteRef', 'unbindWorkingSiteRef']),
    ...documentsHelper.mapActions(['fetchDocuments']),

    onUnapprovedDocuments: function () {
      this.$router.push(`/working-sites/${this.workingSiteId}/unapproved-documents`)
    },

    fetchDocumentCouters: async function () {
      try {
        this.unapprovedDocuments = await this.$store.dispatch('documents/listDocuments', { scope: DocumentScope.workingSiteDocument, workingSiteId: this.workingSiteId, approved: false })
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },

    goBack () {
      return this.$router.go(-1)
    },
    search () {
      this.filter = this.searchQuery
    },

    bindWorkingSite: async function () {
      console.log('bindWorkingSite')
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.workingSiteId == undefined || typeof this.$route.params.workingSiteId !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.bindWorkingSiteRef({ id: this.$route.params.workingSiteId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSiteRef')
        console.error(e)
      }
    },
    unbindWorkingSite: async function () {
      console.log('unbindWorkingSite')
      try {
        await this.unbindWorkingSiteRef()
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },
    getWorkingSiteDocuments: async function () {
      console.log(`getWorkingSiteDocuments: ${this.$route.params.workingSiteId}`)
      try {
        await this.fetchDocuments({ scope: DocumentScope.workingSiteDocument, workingSiteId: this.$route.params.workingSiteId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSitesRef')
        console.error(e)
      }
    }
  }

})
