import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5df64608"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-sites h-full bg-primary-50" }
const _hoisted_2 = { class: "left-panel sidebar fixed overflow-hidden" }
const _hoisted_3 = { class: "w-full text-right" }
const _hoisted_4 = { class: "searchbar flex" }
const _hoisted_5 = { class: "list overflow-auto" }
const _hoisted_6 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArchiveIcon = _resolveComponent("ArchiveIcon")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_WorkingSiteCard = _resolveComponent("WorkingSiteCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AddEditWorkingSiteModalDialog = _resolveComponent("AddEditWorkingSiteModalDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PrimaryButton, {
          class: "archived-users text-primary-600",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowingArchivedWorkingSites = !_ctx.isShowingArchivedWorkingSites))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ArchiveIcon, { class: "icon" }),
            _createTextVNode(_toDisplayString(_ctx.isShowingArchivedWorkingSites ? 'Vedi cantieri attivi' : 'Vedi cantieri archiviati'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_PrimaryButton, {
          class: "bg-primary-900 text-white new-working-site-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNewWorkingSiteDialog()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Crea nuovo cantiere")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: "text px-4 py-3 flex-grow",
          type: "text",
          placeholder: "Cerca cantiere",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event)),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.search()), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.searchQuery]
        ]),
        _createVNode(_component_PrimaryButton, {
          class: "button bg-white text-primary flex-none",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.search()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cerca")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workingSites, (workingSite) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            class: "link bg-white text-primary-600 justify-between flex flex-row font-bold align-middle focus:outline-none focus:shadow-outline",
            key: workingSite.id,
            to: `/working-sites/${workingSite.id}`,
            type: "button"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_WorkingSiteCard, { modelValue: workingSite }, null, 8, ["modelValue"])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_view)
    ]),
    (_ctx.addWorkingSite)
      ? (_openBlock(), _createBlock(_component_AddEditWorkingSiteModalDialog, {
          key: 0,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hideNewWorkingSiteDialog()))
        }))
      : _createCommentVNode("", true)
  ]))
}