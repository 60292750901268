import { ClockingInScope, DocumentScope, UserExpenseScope, WorkReportScope } from '@/enums'
import { globalAuthGuard } from '@/guards'
import { Settings } from '@/views'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'users',
        name: 'Users',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
        children: [
          {
            path: ':id',
            name: 'User',
            component: () => import('../views/User.vue')
          },
          {
            path: ':userId/clocking-ins',
            name: 'User Clocking Ins',
            props: { scope: ClockingInScope.user },
            component: () => import('../views/ClockingIns.vue')
          },
          {
            path: ':userId/work-reports',
            name: 'User Work Reports',
            props: { scope: WorkReportScope.user },
            component: () => import('../views/WorkReports.vue')
          },
          {
            path: ':userId/documents',
            name: 'User Documents',
            component: () => import('../views/UserDocuments.vue')
          },
          {
            path: ':userId/expenses',
            name: 'User expenses',
            props: { scope: UserExpenseScope.user },
            component: () => import('../views/UserExpenses.vue')
          }
        ]
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        children: [
          {
            path: 'communications/:id',
            name: 'Dashboard Comunication',
            component: () => import('../views/CompanyCommunication.vue')
          },
          {
            path: 'documents/:id',
            name: 'Dashboard Document',
            props: { scope: DocumentScope.companyDocument },
            component: () => import('../views/DocumentDetails.vue')
          },
          {
            path: 'archived/:id',
            name: 'Archived',
            component: () => import('../views/ArchivedDocumentDetails.vue')
          }
        ]
      },
      {
        path: 'working-sites',
        name: 'Working sites',
        component: () => import('../views/WorkingSites.vue'),
        children: [
          {
            path: ':id',
            name: 'Working site',
            component: () => import('../views/WorkingSite.vue')
          },
          {
            path: ':workingSiteId/users',
            name: 'Working site users',
            component: () => import('../views/WorkingSiteAssignedUsers.vue')
          },
          {
            path: ':workingSiteId/documents',
            name: 'Working site documents',
            component: () => import('../views/WorkingSiteDocuments.vue')
          },
          {
            path: ':workingSiteId/unapproved-documents',
            name: 'Working site unapproved documents',
            component: () => import('../views/WorkingSiteDocuments.vue')
          },
          {
            path: ':workingSiteId/tags',
            name: 'Working site tags',
            component: () => import('../views/WorkingSiteTags.vue')
          },
          {
            path: ':workingSiteId/timings',
            name: 'Working site timings',
            component: () => import('../views/WorkingSiteTimings.vue')
          },
          {
            path: ':workingSiteId/ppes',
            name: 'Working site PPEs',
            component: () => import('../views/WorkingSitePpes.vue')
          },
          {
            path: ':workingSiteId/clocking-ins',
            name: 'Working site clocking ins',
            props: { scope: ClockingInScope.workingSite },
            component: () => import('../views/ClockingIns.vue')
          },
          {
            path: ':workingSiteId/work-reports',
            name: 'Working site work reports',
            props: { scope: WorkReportScope.workingSite },
            component: () => import('../views/WorkReports.vue')
          },
          {
            path: ':workingSiteId/expenses',
            name: 'Working site user expenses',
            props: { scope: UserExpenseScope.workingSite },
            component: () => import('../views/UserExpenses.vue')
          }
        ]
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('../views/Notifications.vue'),
        children: [
          {
            path: ':id',
            name: 'Notification',
            component: () => import('../views/Notification.vue')
          }
        ]
      },
      {
        path: 'tools',
        name: 'Tools',
        component: () => import(/* webpackChunkName: "tools" */ '../views/Tools.vue'),
        children: [
          {
            path: ':id',
            name: 'Tool',
            component: () => import('../views/Tool.vue')
          }
        ]
      },
      {
        path: 'map',
        name: 'Map',
        component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue')
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('../views/CalendarEvents.vue'),
        children: [
          {
            path: ':id',
            name: 'Evenet',
            component: () => import('../views/CalendarEvent.vue')
          }
        ]
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('../views/Reports.vue'),
        children: [
          {
            path: ':id',
            name: 'Report',
            component: () => import('../views/Report.vue')
          },
          {
            path: 'select-report',
            name: 'Select',
            component: () => import('../views/ReportSelection.vue')
          }
        ]
      },
      {
        path: 'chats',
        name: 'Chats',
        component: () => import('../views/ChatsPage.vue'),
        children: [
          {
            path: ':id',
            name: 'Chat',
            component: () => import('../views/ChatPage.vue')
          }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
        children: [
          {
            path: 'info',
            name: 'Setting Information',
            component: () => import('../views/SettingInfo.vue')
          },
          {
            path: 'users',
            name: 'Setting Users',
            component: () => import('../views/SettingUsers.vue')
          }
        ]
      },
      {
        path: 'support',
        name: 'Support',
        component: Settings,
        beforeEnter (to, from, next) {
          window.open('https://support.smartsquad.io/', '_blank')
        }
      }
    ]
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/dashboard' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(globalAuthGuard)

export default router
