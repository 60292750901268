
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccordionElement',

  props: {
    link: String,
    text: String,
    key: String
  }
})
