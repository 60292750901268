
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { SuccessIcon } from '@/components/icons'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'RemoveToolUserAssignment',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, SuccessIcon
  },

  props: {
    toolItemId: { type: String, required: true },
    toolName: { type: String, required: true },
    userId: { type: String, required: true }
  },

  data: function () {
    return {
      loading: false
    }
  },

  methods: {
    removeAssignment: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'tools/removeUserAssignment',
          { toolItemId: this.toolItemId, userId: this.userId }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'remozione assegnazione fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'rimzione assegnazione riuscita',
          type: AlertType.success
        })

        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('tools/reloadToolItems')
        }, 4000)
        this.$emit('close')
      } catch (error) {
        console.error('error removing the user from the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'rimzione utente fallita',
          type: AlertType.error
        })
      }
    }
  }
})
