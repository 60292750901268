
import { INotificationModel } from '@sultan/shared'
import { defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
  name: 'NotificationCard',

  props: {
    modelValue: {
      type: Object as () => INotificationModel,
      required: true
    }
  },

  created: function () {
    (this as any).moment = moment
  },

  methods: {
    datePrettyPrinted: function (): string {
      const not = (this as any).notification
      if (not === undefined) { return '' }
      if (moment().isSame(moment.unix(not.createdTs), 'day')) { return 'Oggi' }
      if (moment().subtract(1, 'day').isSame(moment.unix(not.createdTs), 'day')) { return 'Ieri' }

      return moment.unix(not.createdTs).format('DD/MM/YYYY')
    },

    timePrettyPrinted: function (): string {
      return (this as any).notification === undefined
        ? ''
        : moment.unix((this as any).notification.createdTs).format('HH:mm')
    }
  }
})
