/* eslint-disable eqeqeq */
import { ChatGroupType, IChatGroupModel } from '@sultan/shared'

export const getChatGroupName = (chatGroup: IChatGroupModel): string => {
  switch (chatGroup.type) {
    case ChatGroupType.GROUP: return chatGroup.name != undefined ? chatGroup.name : 'Gruppo'
    case ChatGroupType.CHANNEL: return chatGroup.name != undefined ? chatGroup.name : 'Canale'
    case ChatGroupType.ONE_TO_ONE: return chatGroup.name != undefined ? chatGroup.name : 'Chat diretta'
    case ChatGroupType.ADMINS_WORKER_GROUP: {
      if (chatGroup.name == undefined) { return '' }
      return chatGroup.name.replace('Admin - ', '')
    }
    default: return chatGroup.name != undefined ? chatGroup.name : ''
  }
}
