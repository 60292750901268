
import { defineComponent } from 'vue'
import ModalDialog from '@/components/dialogs/ModalDialog.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Toggle from '@/components/Toggle.vue'
import Loader from '../Loader.vue'
import UploadForm from '@/components/UploadForm.vue'
import { IAlgoliaUserModel, IDocumentModel, ITagModel } from '@sultan/shared'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { DocumentScope, AlertType } from '@/enums'
import { companyCommunicationDocumentsFolder, companyDocumentsFolder, uploadDocument, userDocumentsFolder, workingSitesDocumentsFolder } from '@/utilities'
import { v4 as UUID } from 'uuid'
import firebase from 'firebase'

export default defineComponent({
  name: 'AddEditDocumentModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, UploadForm, Toggle
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data: function () {
    return {
      users: [] as IAlgoliaUserModel[],
      selectedUsers: [] as IAlgoliaUserModel[],
      usersSearchFilter: '',
      step1: {
        title: '',
        file: undefined,
        requiresSignature: false
      },
      step2: {
        isGlobal: true,
        userIds: []
      },
      tagIds: [] as string[],
      loading: false,

      // Step management
      step: 0,
      isEditing: true,

      // Constants
      DocumentScope
    }
  },

  validations () {
    return {
      step1: {
        title: { required },
        file: { required }
      }
    }
  },

  props: {
    workingSiteId: { type: String, required: false },
    communicationId: { type: String, required: false },
    userId: { type: String, required: false },

    modelValue: {
      type: Object as () => IDocumentModel | undefined,
      requried: false,
      default: undefined
    },
    tags: {
      type: Object as () => { [key: string]: ITagModel } | undefined,
      required: false,
      default: {} as any
    },
    scope: {
      type: Number as () => DocumentScope,
      required: true,
      default: DocumentScope.workingSiteDocument
    }
  },

  computed: {
    steps: function (): string {
      if (this.step > 1 || this.scope !== DocumentScope.workingSiteDocument) { return '' }
      return `Step ${this.step + 1} di 2`
    },
    tagsAllowed: function (): boolean {
      // eslint-disable-next-line eqeqeq
      return this.scope == DocumentScope.workingSiteDocument
    },
    titleText: function (): string {
      return (this.modelValue as any) === undefined
        ? 'Carica nuova documentazione'
        : 'Modifica documentazione'
    },
    subtitleText: function (): string {
      return this.step === 0
        ? 'Documento'
        : (
          this.step === 1
            ? 'Visibilità'
            : ''
        )
    },
    actionText: function (): string {
      return (this.modelValue as any) === undefined
        ? 'Carica documento'
        : 'Aggiorna documento'
    },
    fileIsNotPDF: function (): boolean {
      if ((this.modelValue as any) !== undefined && (this.modelValue as IDocumentModel).mimeType !== 'application/pdf') {
        return true
      }

      if ((this.step1.file as any) !== undefined && (this.step1.file as any).f !== undefined && (this.step1.file as any).f.type !== 'application/pdf') {
        return true
      }

      return false
    },

    unselectedUsers: function (): IAlgoliaUserModel[] {
      return this.users.filter((u: IAlgoliaUserModel) => {
        return !(this.step2.userIds as any).includes(u.id) && (
          // eslint-disable-next-line eqeqeq
          this.workingSiteId == undefined || (u.workSiteAssignmentIds != undefined && u.workSiteAssignmentIds.includes(this.workingSiteId))
        )
      })
    }
  },

  async mounted () {
    // eslint-disable-next-line eqeqeq
    if (this.modelValue != undefined) {
      // eslint-disable-next-line vue/no-mutating-props
      this.step1.title = this.modelValue.title
      this.tagIds = this.modelValue.tagIds !== undefined ? this.modelValue.tagIds : []
      this.step1.file = { d: this.modelValue } as any
      this.step1.requiresSignature = (this.modelValue as any).requiresSignature
      // eslint-disable-next-line eqeqeq
      this.step2.isGlobal = this.modelValue.readAllowedToUserIds == undefined
      if (!this.step2.isGlobal) {
        this.step2.userIds = (this.modelValue.readAllowedToUserIds || []) as any
        this.selectedUsers = await this.$store.dispatch('users/instantGetUsers', (this.modelValue.readAllowedToUserIds || []))
      }
    }
  },

  methods: {
    onConfirm: async function (): Promise<void> {
      console.log('AddEditDocumentModalDialog - on confirm', {
        modelValue: this.modelValue,
        file: this.step1.file,
        scope: this.scope,
        workingSiteId: this.workingSiteId,
        userId: this.userId,
        communicationId: this.communicationId
      })

      if (this.step === 0 && this.scope === DocumentScope.workingSiteDocument) {
        this.v$.step1.$touch()

        if (this.v$.step1.$error) {
          this.loading = false
          return
        }

        await this.searchUsers()
        this.step++
      } else {
        let destination: firebase.storage.Reference | undefined
        switch (this.scope) {
          case DocumentScope.workingSiteDocument:
            if (this.workingSiteId !== undefined) {
              destination = workingSitesDocumentsFolder(this.workingSiteId)
            }
            break
          case DocumentScope.companyCommunicationDocument:
            if (this.communicationId !== undefined) {
              destination = companyCommunicationDocumentsFolder
            }
            break
          case DocumentScope.companyDocument:
            destination = companyDocumentsFolder
            break
          case DocumentScope.userDocument:
            if (this.userId !== undefined) {
              destination = userDocumentsFolder(this.userId)
            }
            break
        }

        if (destination === undefined) {
          this.loading = false
          await this.$store.dispatch('alerts/create', { message: 'caricamento documento fallito', type: AlertType.error })
          return
        }

        let result: boolean | undefined

        if (
          // eslint-disable-next-line eqeqeq
          (this.modelValue == undefined || this.modelValue.id == undefined)
          // eslint-disable-next-line eqeqeq
          && this.step1.file != undefined && (this.step1.file as any).f != undefined
        ) {
          // New Upload case
          console.log('AddEditDocumentModalDialog - New upload')
          this.loading = true

          const doc: Partial<IDocumentModel> | any = {
            id: UUID(),
            title: this.step1.title,
            requiresSignature: this.fileIsNotPDF ? false : this.step1.requiresSignature,
            createdTs: (new Date()).valueOf() / 1000,
            tagIds: this.tagIds,
            approved: true,
            documentUrl: ''
          }

          doc.documentUrl = await uploadDocument(destination, (this.step1.file as any).f, doc);
          (doc as any).mimeType = (this.step1.file as any).f.type

          try {
            switch (this.scope) {
              case DocumentScope.workingSiteDocument:
                if (!this.step2.isGlobal) {
                  doc.readAllowedToUserIds = this.step2.userIds
                }
                result = await this.$store.dispatch(
                  'documents/createDocument',
                  {
                    workingSiteId: this.workingSiteId,
                    document: doc,
                    scope: this.scope
                  }
                )
                break
              case DocumentScope.companyDocument:
                result = await this.$store.dispatch(
                  'documents/createDocument', { document: doc, scope: this.scope }
                )
                break
              case DocumentScope.companyCommunicationDocument:
                result = await this.$store.dispatch(
                  'documents/createDocument',
                  {
                    communicationId: this.communicationId,
                    document: doc,
                    scope: this.scope
                  }
                )
                break
              case DocumentScope.userDocument:
                result = await this.$store.dispatch(
                  'documents/createDocument',
                  {
                    userId: this.userId,
                    document: doc,
                    scope: this.scope
                  }
                )
                break
            }

            this.loading = false
            if (result === undefined) {
              await this.$store.dispatch('alerts/create', { message: 'caricamento documento fallito', type: AlertType.error })
              return
            }

            await this.$store.dispatch('alerts/create', { message: 'caricamento documento riuscito', type: AlertType.success })

            this.$emit('close')
          } catch (error) {
            console.error('error creating the document', error)
            this.loading = false
            await this.$store.dispatch('alerts/create', { message: 'caricamento documento fallito', type: AlertType.error })
          }
        } else {
          // Update case
          console.log('AddEditDocumentModalDialog - Update', { m: this.modelValue })

          // eslint-disable-next-line eqeqeq
          if (this.modelValue == undefined || this.modelValue.id == undefined) {
            this.loading = false
            await this.$store.dispatch('alerts/create', { message: 'caricamento documento fallito', type: AlertType.error })
            return
          }

          // New Upload case
          this.loading = true

          const doc: IDocumentModel | any = {
            ...this.modelValue,
            tagIds: this.tagIds,
            title: this.step1.title,
            requiresSignature: this.fileIsNotPDF ? false : this.step1.requiresSignature
          }

          // eslint-disable-next-line eqeqeq
          if ((this.step1.file as any).f != undefined) {
            doc.documentUrl = await uploadDocument(destination, (this.step1.file as any).f, doc);
            (doc as any).mimeType = (this.step1.file as any).f.type
          }

          try {
            switch (this.scope) {
              case DocumentScope.workingSiteDocument:
                if (!this.step2.isGlobal) {
                  doc.readAllowedToUserIds = this.step2.userIds
                }
                result = await this.$store.dispatch(
                  'documents/updateDocument',
                  {
                    workingSiteId: this.workingSiteId,
                    scope: this.scope,
                    document: doc
                  }
                )
                break
              case DocumentScope.companyDocument:
                result = await this.$store.dispatch('documents/updateDocument', { scope: this.scope, document: doc })
                break
              case DocumentScope.companyCommunicationDocument:
                result = await this.$store.dispatch(
                  'documents/updateDocument',
                  {
                    communicationId: this.communicationId,
                    scope: this.scope,
                    document: doc
                  }
                )
                break
              case DocumentScope.userDocument:
                result = await this.$store.dispatch(
                  'documents/updateDocument',
                  {
                    userId: this.userId,
                    scope: this.scope,
                    document: doc
                  }
                )
                break
            }

            this.loading = false
            if (result === undefined) {
              await this.$store.dispatch('alerts/create', { message: 'aggiornamento documento fallito', type: AlertType.error })
              return
            }

            await this.$store.dispatch('alerts/create', { message: 'aggiornamento documento riuscito', type: AlertType.success })

            this.$emit('close')
          } catch (error) {
            console.error('error creating the document', error)
            this.loading = false
            await this.$store.dispatch('alerts/create', { message: 'aggiornamento documento fallito', type: AlertType.error })
          }
        }
      }
    },
    onCancel: function () {
      if (this.step === 0) {
        return this.closeModal()
      }

      this.step--
    },
    closeModal: function () {
      console.log('on close')
      this.$emit('close')
    },
    isAdded: function (tag: ITagModel): boolean {
      return this.tagIds.includes(tag.id)
    },
    toggleTag: function (tag: ITagModel): void {
      if (this.isAdded(tag)) {
        this.tagIds = this.tagIds.filter(t => t !== tag.id)
      } else {
        this.tagIds.push(tag.id)
      }
    },

    // Step 2

    addUser: function (user: IAlgoliaUserModel): void {
      (this.step2.userIds as any).push(user.id)
      this.selectedUsers.push(user)
    },
    removeUser: function (userId: string): void {
      this.step2.userIds = this.step2.userIds.filter((u) => u !== userId)
      this.selectedUsers = this.selectedUsers.filter((u) => u.id !== userId)
    },
    searchUsers: async function (): Promise<void> {
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        query: this.usersSearchFilter,
        hitsPerPage: 10000
      })
      console.debug('searchUsers', { users: this.users })
    }
  }
})
