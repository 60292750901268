
import { defineComponent } from 'vue'
import { PrimaryButton, SecondaryButton } from '@/components/buttons'
import { AddEditCommunicationDialog, DeleteCommunicationDialog } from '@/components/dialogs'
import { RelatedDocumentsSection, Loader } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ICompanyCommunicationsStoreState } from '@/interfaces'
import moment from 'moment'
import { AlertType, DocumentScope } from '@/enums'
const { mapState } = createNamespacedHelpers('communications')

export default defineComponent({
  name: 'CompanyCommunication',

  components: {
    Loader, PrimaryButton, SecondaryButton, AddEditCommunicationDialog, DeleteCommunicationDialog, RelatedDocumentsSection
  },

  data () {
    return {
      isShowingDeleteDialog: false,
      isShowingEditDialog: false,
      loading: false,
      DocumentScope
    }
  },

  computed: {
    ...mapState<ICompanyCommunicationsStoreState>({
      communication: (state: ICompanyCommunicationsStoreState) => state.communication,
      commDocuments: (state: ICompanyCommunicationsStoreState) =>
        state.communication === undefined || state.communication.documents === undefined || typeof state.communication.documents !== 'object'
          ? []
          : Object.values(state.communication.documents)
    })
  },

  created: function () {
    (this as any).moment = moment
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindCompanyCommunication()
  },

  async beforeUnmount () {
    await this.unbindCompanyCommunication()
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindCompanyCommunication()
        await this.bindCompanyCommunication()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onToggleArchive: async function (): Promise<void> {
      console.log('CompanyCommunication - onToggleArchive', { communication: (this as any).communication })
      this.loading = true

      // eslint-disable-next-line eqeqeq
      if ((this as any).communication == undefined || (this as any).communication.id == undefined) {
        // New Communication case
        console.log('AddEditCommunicationDialog - error')
        this.loading = false
        return
      }

      const isArchived = (this as any).communication.isArchived

      try {
        const result = await this.$store.dispatch(
          'communications/updateCommunication',
          {
            id: (this as any).communication.id,
            isArchived: !isArchived
          }
        )

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', { message: isArchived ? 'ripristino comunicazione fallita' : 'archiviazione comunicazione fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: isArchived ? 'ripristino comunicazione fallita' : 'archiviazione comunicazione riuscita', type: AlertType.success })

        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('communications/fetchCommunications')
        }, 4000)

        this.$emit('close')
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: isArchived ? 'ripristino comunicazione fallita' : 'archiviazione comunicazione fallita', type: AlertType.error })
      }
    },
    bindCompanyCommunication: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('communications/bindCommunicationRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindCommunicationRef')
        console.error(e)
      }
    },
    unbindCompanyCommunication: async function () {
      try {
        await this.$store.dispatch('communications/unbindCommunicationRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindCommunicationRef')
        console.error(e)
      }
    }
  }

})
