import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-daec5268"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-site-timing-card mb-2 grid grid-cols-2 text-left overflow-hidden" }
const _hoisted_2 = { class: "font-bold timing-tab-element self-center uppercase" }
const _hoisted_3 = { class: "working-site-timings-buttons flex text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.durationHumanized), 1),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 0,
              class: "font-bold timing-tab-element delete-button ml-auto cursor-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTimingDelete && _ctx.onTimingDelete(...args)))
            }, "Elimina"))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          message: "Salvataggio..."
        }))
      : _createCommentVNode("", true)
  ], 64))
}