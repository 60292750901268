
import { defineComponent } from 'vue'
import { PrimaryButton, MenuButton, NotificationCard, Loader } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ICompanyNotificationsStoreState } from '@/interfaces'
import { INotificationModel, NotificationType } from '@sultan/shared'
import moment from 'moment'
const { mapState, mapActions } = createNamespacedHelpers('companyNotifications')

export default defineComponent({
  name: 'Notifications',

  components: {
    MenuButton, PrimaryButton, NotificationCard, Loader
  },

  data: function () {
    return {
      loading: false,
      filteringDocumentsToBeApproved: false,
      filteringWorkingSiteUpdates: false
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      await this.refreshNotifications()
    } catch (e) {
      console.error('error reloading the notifications')
      console.error(e)
    }
  },

  methods: {
    ...mapActions(['refreshNotifications', 'paginateNotifications']),
    groupTitle: function (group: string): string {
      if (group === moment().format('DD/MM/YYYY')) { return 'Oggi' }
      if (group === moment().subtract(1, 'day').format('DD/MM/YYYY')) { return 'Ieri' }

      return group
    },
    onPaginate: async function (): Promise<void> {
      console.log('Notifications - onPaginate')
      this.loading = true
      await this.paginateNotifications()
      this.loading = false
    }
  },

  computed: {
    ...mapState<ICompanyNotificationsStoreState>({
      notificationsGrouped: function (state: ICompanyNotificationsStoreState) {
        let nots: INotificationModel[] = []

        if ((this as any).filteringDocumentsToBeApproved || (this as any).filteringWorkingSiteUpdates) {
          if ((this as any).filteringDocumentsToBeApproved) {
            nots = state.notifications.filter(n => n.type === NotificationType.WORKING_SITE_UNAPPROVED_DOCUMENT_CREATED)
          }
          if ((this as any).filteringWorkingSiteUpdates) {
            nots = [...nots, ...state.notifications.filter(n =>
              n.type === NotificationType.WORKING_SITE_ARCHIVED
              || n.type === NotificationType.WORKING_SITE_UNARCHIVED
              || n.type === NotificationType.WORKING_SITE_CREATED
              || n.type === NotificationType.WORKING_SITE_DELETED
              || n.type === NotificationType.WORKING_SITE_STARTED
              || n.type === NotificationType.WORKING_SITE_EXPIRED
            )]
          }
        } else {
          nots = state.notifications
        }

        return nots.reduce(
          (acc: { [key: string]: INotificationModel[] }, notification: INotificationModel) => {
            const day = moment.unix(notification.createdTs).format('DD/MM/YYYY')
            if (Object.keys(acc).includes(day)) {
              acc[day].push(notification)
            } else {
              acc[day] = [notification]
            }

            return acc
          },
          {}
        )
      }
    })
  }
})
