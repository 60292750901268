
import { defineComponent } from 'vue'
import { CalendarView } from 'vue-simple-calendar'
import 'vue-simple-calendar/dist/style.css'
import CustomCalendarViewHeader from '@/components/CustomCalendarViewHeader.vue'
import { IWorkingSiteModel } from '@sultan/shared'
import { createNamespacedHelpers } from 'vuex'
import { IWorkingSitesStoreState } from '@/interfaces'
import { mapOptions } from '@/utilities'
const { mapState } = createNamespacedHelpers('workingSites')

interface IMarker {
  position: {
    lat: number;
    lng: number;
  };
  workingSite: Partial<IWorkingSiteModel>
}

export default defineComponent({
  name: 'Map',

  components: {
    CalendarView, CustomCalendarViewHeader
  },

  data () {
    return {
      center: { lat: 45.9058226, lng: 13.4637651 },
      openedMarkerID: '',
      mapOptions: mapOptions,
      selectedMarker: undefined as IMarker | undefined,
      infowindow: { lat: 10, lng: 10.0 },
      markerWindow: false
    }
  },

  methods: {
    openWindow () {
      console.log(this)
      this.markerWindow = true
    },
    onMarkerWindowClose () {
      console.log('onMarkerWindowClose')
      this.markerWindow = false
    },
    onMarkerClick (marker: IMarker) {
      console.log('onMarkerClick', { position: marker.position })
      this.center = marker.position
      this.infowindow = marker.position
      this.selectedMarker = marker
      this.openWindow()
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.workingSites)
      await this.$store.dispatch('workingSites/reloadWorkingSites', { hitsPerPage: 999, facetFilters: 'isArchived:false' })
    } catch (e) {
      console.error('error dispatching the firestore mutation bindWorkingSitesRef')
      console.error(e)
    }
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      markers: function (state: IWorkingSitesStoreState) {
        return state.workingSites
          .reduce(
            (acc: IMarker[], ws: IWorkingSiteModel): IMarker[] => {
              console.log('i', { acc, ws })
              // eslint-disable-next-line eqeqeq
              if (ws.address != undefined) {
                // eslint-disable-next-line eqeqeq
                if (ws.address.lat != undefined && ws.address.lng != undefined) {
                  acc.push({
                    position: { lat: ws.address.lat, lng: ws.address.lng },
                    workingSite: ws
                  })
                }
              }

              return acc
            },
            [
              {
                position: {
                  lat: 45.9058226, lng: 13.4637651
                },
                workingSite: {
                  name: 'Sultan Headquarter',
                  address: {
                    address: 'Via Michele Pompanin, 3, 34070 Zona Artigianale-industriale GO'
                  }
                }
              } as IMarker
            ]
          )
      }
    })
  }
})
