import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9d282c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "first flex-grow font-bold user-tab-element self-center truncate" }
const _hoisted_2 = { class: "second flex-grow user-tab-element self-center truncate" }
const _hoisted_3 = { class: "third working-site-users-buttons flex self-center" }
const _hoisted_4 = { class: "fourth working-site-users-buttons flex self-center" }
const _hoisted_5 = { class: "font-bold user-tab-element suspend mr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_RemoveToolUserAssignment = _resolveComponent("RemoveToolUserAssignment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["user-assigned-working-site mb-2 flex text-left overflow-hidden", { 'bg-primary-50': _ctx.modelValue.isEnabled, 'bg-secondary-50': !_ctx.modelValue.isEnabled }])
    }, [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.modelValue.toolItem.primaryField), 1),
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.modelValue.toolItem.secondaryField), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.moment.unix(_ctx.modelValue.createdTs).format("DD/MM/YYYY")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "ml-auto",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleUserAssignmentSospension()))
        }, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(!_ctx.modelValue.isEnabled ? 'Annulla sospensione' : 'Sospendi'), 1)
        ]),
        _createElementVNode("button", null, [
          _createElementVNode("h3", {
            class: "font-bold user-tab-element delete-button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showRemoveAssignmentDialog && _ctx.showRemoveAssignmentDialog(...args)))
          }, "Elimina")
        ])
      ])
    ], 2),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          message: "Salvataggio..."
        }))
      : _createCommentVNode("", true),
    (_ctx.isShowingRemoveAssignment)
      ? (_openBlock(), _createBlock(_component_RemoveToolUserAssignment, {
          key: 1,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hideRemoveAssignmentDialog())),
          "tool-item-id": _ctx.modelValue.toolItem.id,
          "tool-id": _ctx.modelValue.toolItem.toolId,
          "tool-name": `${_ctx.modelValue.toolItem.primaryField} ${_ctx.modelValue.toolItem.secondaryField}`,
          "user-id": _ctx.modelValue.userId
        }, null, 8, ["tool-item-id", "tool-id", "tool-name", "user-id"]))
      : _createCommentVNode("", true)
  ], 64))
}