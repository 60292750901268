
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { SuccessIcon } from '@/components/icons'
import { AlertType } from '@/enums'
import { IUserModel } from '@sultan/shared'

export default defineComponent({
  name: 'ArchivieUserDocument',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, SuccessIcon
  },

  props: {
    modelValue: {
      type: Object as () => IUserModel,
      required: true
    }
  },

  data: function () {
    return {
      loading: false
    }
  },

  methods: {
    onArchive: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'users/updateUser', { id: this.modelValue.id, isArchived: true, isEnabled: false }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'archiviazione utente fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'archiviazione utente riuscita',
          type: AlertType.success
        })

        this.$emit('close')
      } catch (error) {
        console.error('error archiving the user', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'archiviazione utente fallita',
          type: AlertType.error
        })
      }
    }
  }
})
