
import { defineComponent } from 'vue'

import { PrimaryButton } from '@/components/buttons'
import { MenuButton } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { IUsersStoreState } from '@/interfaces'
import Checkbox from '@/components/Checkbox.vue'
import router from '@/router'
const { mapState } = createNamespacedHelpers('users')

export default defineComponent({
  name: 'Reports',

  components: {
    MenuButton, PrimaryButton, Checkbox
  },

  data () {
    return {
      transitionName: '',
      searchQuery: '',
      isActive: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.users)
      this.searchQuery = this.$store.state.users.usersSearchQuery
      await this.$store.dispatch('users/reloadUsers')
    } catch (e) {
      console.error('error reloading the users')
      console.error(e)
    }
  },

  computed: {
    ...mapState<IUsersStoreState>({
      users: (state: any) => state.users,
      usersSearchQuery: (state: any) => state.usersSearchQuery
    })
  },

  methods: {
    selectUsers: function () {
      this.isActive = !this.isActive
      router.push('/reports/select-report')
    },
    userDetails: function () {
      this.isActive = !this.isActive
      router.go(-1)
    },
    search: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('users/paginateUsers', { forceReload: true, query: this.searchQuery })
    },
    paginate: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('users/paginateUsers', { forceReload: false, query: this.searchQuery })
    }
  },

  watch: {
    usersSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    }
  }
})
