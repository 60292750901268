
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { ChatIcon } from '@heroicons/vue/solid'
import { IUsersStoreState } from '@/interfaces'
import { PrimaryButton } from '@/components/buttons'
import { Loader } from '@/components'
import { EditUserModalDialog, DownloadReportDialog } from '@/components/dialogs'
import { AlertType } from '@/enums'

const { mapState } = createNamespacedHelpers('users')

export default defineComponent({
  name: 'ReportSelection',

  components: {
    ChatIcon, PrimaryButton, EditUserModalDialog, DownloadReportDialog, Loader
  },

  data () {
    return {
      downloadReport: false,
      loading: false
    }
  },

  computed: {
    ...mapState<IUsersStoreState>({
      employee: (state: IUsersStoreState) => state.user
    })

  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindUser()
        await this.bindUser()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindUser()
  },

  async beforeUnmount () {
    await this.unbindUser()
  },

  methods: {
    toggleEnabling: async function (enabled: boolean) {
      console.log('toggleEnabling', enabled)

      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      this.loading = true
      const logStartMessage = `${enabled ? 'abilitazione' : 'disabilitazione'} utente`
      try {
        const result = await this.$store.dispatch(
          'users/updateUser',
          {
            id: this.$route.params.id,
            isEnabled: enabled
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch('alerts/create', { message: `${logStartMessage} fallita`, type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: `${logStartMessage} riuscita`, type: AlertType.success })
        await this.$store.dispatch('users/reloadUsers')
        this.$emit('close')
      } catch (error) {
        console.error('error updating the user', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: `${logStartMessage} fallita`, type: AlertType.error })
      }
    },
    bindUser: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('users/bindUserRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindUserRef')
        console.error(e)
      }
    },
    unbindUser: async function () {
      try {
        await this.$store.dispatch('users/unbindUserRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindUserRef')
        console.error(e)
      }
    },
    downloadSelectedReport: function () {
      this.downloadReport = true
    },
    hideDownloadReportDialog: function () {
      this.downloadReport = false
    }
  }
})
