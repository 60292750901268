
import { defineComponent } from 'vue'
import { DocumentCard, ViewMoreDocuments } from '@/components'
import { UploadDocumentModalDialog } from '@/components/dialogs'

export default defineComponent({
  name: 'RelatedDocumentsSection',

  components: {
    DocumentCard, ViewMoreDocuments, UploadDocumentModalDialog
  },

  data () {
    return {
      addNewDocument: false
    }
  },

  methods: {
    showNewRelatedDocumentDialog: function () {
      this.addNewDocument = true
    },
    hideNewRelatedDocumentDialog: function () {
      this.addNewDocument = false
    }
  }

})
