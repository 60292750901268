
import { defineComponent } from 'vue'
import UserExpenseCard from '@/components/UserExpenseCard.vue'
import { IUserModel, IWorkingSiteModel } from '@sultan/shared'
import { createNamespacedHelpers } from 'vuex'
import { IUserExpenseModelsStoreState } from '@/interfaces'
import { UserExpenseScope } from '@/enums'
const { mapState } = createNamespacedHelpers('userExpenses')

export default defineComponent({
  name: 'UserExpensesSection',

  components: {
    UserExpenseCard
  },

  props: {
    scope: { type: Number as () => UserExpenseScope, required: true },
    user: { type: Object as () => IUserModel | undefined, required: false },
    workingSite: { type: Object as () => IWorkingSiteModel | undefined, required: false }
  },

  data () {
    return {
      isShowingClockingInDialog: false,
      UserExpenseScope
    }
  },

  async mounted () {
    this.loadUserExpenses()
  },

  computed: {
    ...mapState<IUserExpenseModelsStoreState>({
      userExpenses: (state: IUserExpenseModelsStoreState) => state.userExpenses
    }),
    userExpensesUrl: function (): string | undefined {
      switch (this.scope) {
        case UserExpenseScope.workingSite: return `/working-sites/${(this.workingSite as any).id}/expenses` as string
        case UserExpenseScope.user: return `/users/${(this.user as any).id}/expenses` as string
        default: return undefined
      }
    }
  },

  methods: {
    onManagePPE: function () { this.$router.push(`${this.$route.path}/ppes`) },
    loadUserExpenses: async function () {
      await this.$store.dispatch('userExpenses/paginateUserExpenses', {
        userId: this.user !== undefined ? (this as any).user.id : undefined,
        workingSiteId: this.workingSite !== undefined ? (this as any).workingSite.id : undefined,
        forceReload: true,
        limit: 3
      })
      console.debug('loadUserExpenses', { userExpenses: (this as any).userExpenses })
    },
    reloadUserExpenses: function () {
      setTimeout(() => { this.loadUserExpenses() }, 4000)
    }
  }
})
