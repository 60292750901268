
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { AddEditClockingInDialog } from '@/components/dialogs'
import { LeftArrow } from '@/components/icons'
import { ClockingInCard } from '@/components'
import { IClockingInsStoreState, IUsersStoreState } from '@/interfaces'
import { createNamespacedHelpers } from 'vuex'
import { ClockingInScope } from '@/enums'
const clockingInsHelper = createNamespacedHelpers('clockingIns')
const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'ClockingIns',

  components: { LeftArrow, PrimaryButton, ClockingInCard, AddEditClockingInDialog },

  data () {
    return {
      searchQuery: '',
      isShowingUserAssignment: false,
      isShowingClockingInDialog: false,
      ClockingInScope
    }
  },

  props: {
    scope: {
      type: Number as () => ClockingInScope,
      required: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log('ClockingIns - beforeMount', { params: this.$route.params })

    try {
      console.log(this.$store.clockingIns)
      this.searchQuery = this.$store.state.clockingIns.clockingInsSearchQuery
      await this.$store.dispatch('clockingIns/reloadClockingIns', { facetFilters: this.facetFilters })
    } catch (e) {
      console.error('error reloading the clocking ins')
      console.error(e)
    }
  },

  computed: {
    ...clockingInsHelper.mapState<IClockingInsStoreState>({
      clockingIns: (state: any) => state.clockingIns,
      clockingInsSearchQuery: (state: any) => state.clockingInsSearchQuery
    }),
    ...usersHelper.mapState<IUsersStoreState>(['user']),
    id: function (): string | undefined {
      switch (this.scope) {
        case ClockingInScope.workingSite: return this.$route.params.workingSiteId as string
        case ClockingInScope.user: return this.$route.params.userId as string
        default: return undefined
      }
    },
    facetFilters: function (): string[] {
      switch (this.scope) {
        case ClockingInScope.workingSite: return [`workingSiteId:${this.id}`]
        case ClockingInScope.user: return [`userId:${this.id}`]
        default: return []
      }
    }
  },

  methods: {
    goBack () {
      return this.$router.go(-1)
    },
    async search () {
      await this.$store.dispatch(
        'clockingIns/paginateClockingIns',
        { forceReload: true, query: this.searchQuery, facetFilters: this.facetFilters }
      )
      // console.debug('search clockin ins', { clockingIns: this.clockingIns })
    },
    paginate: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('clockingIns/paginateClockingIns', { forceReload: false, query: this.searchQuery, facetFilters: this.facetFilters })
    },
    bindUser: async function (id: string): Promise<void> {
      console.log('bindUser', { id })

      // eslint-disable-next-line eqeqeq
      if ((this as any).user != undefined && (this as any).user.id === id) { return }

      try {
        await this.$store.dispatch('users/bindUserRef', { id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindUserRef')
        console.error(e)
      }
    }
  },

  watch: {
    clockingInsSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    },
    '$route.params.userId': {
      handler: async function (id) {
        console.log(id)
        await this.bindUser(id)
      },
      deep: true,
      immediate: true
    }
  }
})
