
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { DatePicker } from 'v-calendar'
import { AlertType, ClockingInScope } from '@/enums'
import { IClockingInModel, IUserModel, IWorkingSiteModel } from '@sultan/shared'
import useVuelidate from '@vuelidate/core'
import { v4 as UUID } from 'uuid'
import { required, requiredIf } from '@vuelidate/validators'
import { createNamespacedHelpers } from 'vuex'
import { humanizedClockingInTiming } from '@/utilities'
const { mapGetters } = createNamespacedHelpers('auth')

export default defineComponent({
  name: 'AddEditClockingInDialog',

  emits: ['close', 'created'],

  components: {
    ModalDialog, PrimaryButton, Loader, DatePicker
  },

  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      time: { required },
      clockingInType: { required },
      userId: { requiredIf: requiredIf(this.scope === ClockingInScope.workingSite) },
      workingSiteId: { requiredIf: requiredIf(this.scope === ClockingInScope.user) },
      timing: { requiredIf: requiredIf(this.clockingInType === 'travelling') }
    }
  },

  props: {
    id: { type: String, required: true },
    scope: {
      type: Number as () => ClockingInScope,
      required: true
    },
    user: { type: Object as () => IUserModel | undefined, required: false },
    workingSite: { type: Object as () => IWorkingSiteModel | undefined, required: false }
  },

  data () {
    return {
      loading: false,
      clockingInType: 'in' as 'in' | 'out' | 'travelling',
      timing: undefined as number | undefined,
      timings: [] as number[],
      workingSites: [] as IWorkingSiteModel[],
      users: [] as IUserModel[],
      workingSiteId: undefined as string | undefined,
      userId: undefined as string | undefined,
      time: new Date() as Date | undefined,
      ClockingInScope,
      humanizedClockingInTiming
    }
  },

  computed: {
    ...mapGetters({
      authUser: 'getAuthUser'
    })
  },

  async mounted () {
    console.debug('AddEditClockingInDialog', { scope: this.scope, id: this.id })
    // eslint-disable-next-line eqeqeq
    if (this.scope == ClockingInScope.user && this.id != undefined) {
      this.userId = this.id as string
      this.loadWorkingSites()
    }
    // eslint-disable-next-line eqeqeq
    if (this.scope == ClockingInScope.workingSite && this.id != undefined) {
      this.workingSiteId = this.id as string
      this.loadUsers()
    }
    await this.loadWorkingSiteTimings()
  },

  watch: {
    workingSiteId: async function (value: string) {
      console.log('received a workingSiteId', { value })
      await this.loadWorkingSiteTimings()
    }
  },

  methods: {
    onCreate: async function () {
      console.log('on confirm')
      this.v$.$touch()

      console.log('error', { error: this.v$.error, user: this.userId })
      // eslint-disable-next-line eqeqeq
      if (this.v$.error || this.userId == undefined) {
        this.loading = false
        return
      }

      this.loading = true

      const doc: IClockingInModel = {
        id: UUID(),
        position: {},
        userId: this.userId,
        createdByUserId: this.authUser !== undefined ? this.authUser.uid : undefined,
        workingSiteId: this.workingSiteId,
        createdTs: ((this.time !== undefined ? this.time : new Date()).valueOf() / 1000),
        createdDate: (this as any).time.toISOString()
      }

      if (this.clockingInType === 'travelling') {
        doc.timing = this.timing
      } else {
        doc.isClockingOut = this.clockingInType === 'out'
      }

      try {
        const result = await this.$store.dispatch('users/createClockingIn', doc)

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'creazione timbratura fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'creazione timbratura riuscita', type: AlertType.success })

        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('clockingIns/reloadClockingIns')
        }, 4000)
        // this.$emit('close')

        this.$emit('created')
        this.$emit('close')
      } catch (error) {
        console.error('error creating the clocking in', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'creazione timbratura fallita', type: AlertType.error })
      }
    },
    loadWorkingSites: async function () {
      if (this.user === undefined || this.user.workSiteAssignments === undefined) { return }
      this.workingSites = await this.$store.dispatch('workingSites/instantWorkingSites',
        Object.values(this.user.workSiteAssignments)
          .filter(ws => ws.isEnabled)
          .map(ws => ws.id)
      )
      console.debug('loadWorkingSites', { workingSites: this.workingSites })
    },
    loadUsers: async function () {
      console.debug('loadUsers', { workingSiteId: this.workingSiteId })
      if (this.workingSiteId === undefined) { return }
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        facetFilters: `workSiteAssignmentIds:${this.workingSiteId}`,
        hitsPerPage: 999,
        page: 0
      })
      console.debug('loadUsers', { users: this.users })
    },
    loadWorkingSiteTimings: async function () {
      console.log('loadWorkingSiteTimings', { workingSiteId: this.workingSiteId })

      // eslint-disable-next-line eqeqeq
      if (this.workingSiteId == undefined) {
        this.timings = []
        return
      }

      let ws = this.workingSite

      // eslint-disable-next-line eqeqeq
      if (ws == undefined) {
        ws = await this.$store.dispatch('workingSites/getWorkingSite', this.workingSiteId) as IWorkingSiteModel
      }

      // eslint-disable-next-line eqeqeq
      this.timings = ws.timings != undefined
        ? ws.timings
        : []
    }
  }
})
