
import { defineComponent } from 'vue'
import { SecondaryButton } from '@/components/buttons'
import { AddEditEventModalDialog, DeleteEventDialog } from '@/components/dialogs'
import { createNamespacedHelpers } from 'vuex'
import { IEventsStoreState } from '@/interfaces/events-store-state.interface'
import moment from 'moment'
import { EventScope } from '@/enums'
import { IAssignmentModel, IUserModel, IEventModel, IEventCheckModel } from '@sultan/shared'
import { mapOptions } from '@/utilities'
const eventsHelper = createNamespacedHelpers('events')
const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'CalendarEvent',

  components: {
    SecondaryButton, AddEditEventModalDialog, DeleteEventDialog, EventScope
  },

  data () {
    return {
      mapOptions: { ...mapOptions, zoomControl: false, disableDefaultUi: true },
      editEvent: false,
      deleteEventIsShown: false,
      recipients: [] as IUserModel[],
      id: [] as IUserModel[],
      checks: undefined as IEventCheckModel[] | undefined
    }
  },

  props: {
    modelValue: Object as () => IAssignmentModel & { user: IUserModel },
    user: { type: Object as () => IUserModel | undefined, required: false },
    scope: { type: Number as () => EventScope, required: true },
    circleColor: {
      type: String,
      default: '#FC0F0F',
      required: true
    }
  },

  computed: {
    ...eventsHelper.mapState<IEventsStoreState>({
      event: (state: IEventsStoreState) => state.event
    })
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindEvent()
        await this.bindEvent()
      },
      deep: true,
      immediate: true
    },
    event: {
      handler: async function (e?: IEventModel) {
        console.log('event watch', { e })
        // eslint-disable-next-line eqeqeq
        this.recipients = e == undefined
          ? []
          : (
            await this.instantGetUsers(e.recipients)
          ).filter((u: IUserModel) => u !== undefined && u.name !== undefined && u.surname !== undefined)
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindEvent()
  },

  async beforeUnmount () {
    await this.unbindEvent()
  },

  created: function () {
    (this as any).moment = moment
  },

  methods: {
    ...usersHelper.mapActions(['instantGetUsers']),
    onMapClick: function () {
      if (
        (this as any).event.address === undefined
        || (this as any).event.address.address === undefined
      ) { return }

      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURI((this as any).event.address.address)}`
      window.open(url, '_blank')
    },
    bindEvent: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('events/bindEventRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindEventRef')
        console.error(e)
      }

      try {
        this.checks = await this.$store.dispatch('events/getEventChecks', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindEventRef')
        console.error(e)
      }
    },
    unbindEvent: async function () {
      try {
        await this.$store.dispatch('events/unbindEventRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindEventRef')
        console.error(e)
      }
    },
    editEventDialog: function () {
      this.editEvent = true
    },
    hideEditEventDialog: function () {
      this.editEvent = false
    },
    deleteEventDialog: function () {
      this.deleteEventIsShown = true
    },
    hideDeleteEventDialog: function () {
      this.deleteEventIsShown = false
      this.$router.push({ path: '/calendar' })
    },
    goToUser: function () { this.$router.push(`/users/${this.id}`) },
    userHasCheckedEvent: function (userId: string): boolean {
      return this.checks === undefined
        ? false
        : this.checks.findIndex(c => c.userId === userId) !== -1
    }
  }
})

