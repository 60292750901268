
import { defineComponent } from 'vue'
import { Loader } from '@/components'
import { RemoveToolUserAssignment } from '@/components/dialogs'
import { AlertType } from '@/enums'
import { IAssignmentModel, IToolItemModel } from '@sultan/shared'
import moment from 'moment'

export default defineComponent({
  name: 'UserAssignedTool',

  components: {
    Loader, RemoveToolUserAssignment
  },

  emits: ['update'],

  props: {
    modelValue: Object as () => IAssignmentModel & { toolItem: IToolItemModel }
  },

  created: function () {
    (this as any).moment = moment
  },

  data () {
    return {
      isShowingRemoveAssignment: false,
      loading: false
    }
  },

  methods: {
    showRemoveAssignmentDialog: function () {
      this.isShowingRemoveAssignment = true
    },
    hideRemoveAssignmentDialog: function () {
      this.isShowingRemoveAssignment = false

      // Add a delay to ensure that the Algolia Index is updated
      setTimeout(() => {
        // Then we reload the users
        this.$emit('update')
      }, 4000)
    },
    toggleUserAssignmentSospension: async function () {
      if (!this.modelValue) { return }
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'tools/updateUserAssignmentStatus',
          {
            toolItemId: this.modelValue.id,
            userId: this.modelValue.userId,
            isEnabled: !this.modelValue.isEnabled // the opposite of the opposite
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch(
            'alerts/create',
            {
              message: 'errore nell\'aggiornamento dello stato di assegnazione dello strumento all\'utente',
              type: AlertType.error
            }
          )
          return
        }

        await this.$store.dispatch(
          'alerts/create',
          {
            message: this.modelValue.isEnabled
              ? 'assegnazione dello strumento all\'utente sospesa'
              : 'sospensione assegnazione dello strumento all\'utente annullata',
            type: AlertType.success
          }
        )
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$emit('update')
        }, 4000)
      } catch (error) {
        console.error('error updating the user assignment status', error)
        this.loading = false
        await this.$store.dispatch(
          'alerts/create',
          {
            message: 'errore nell\'aggiornamento dello stato di assegnazione dello strumento all\'utente',
            type: AlertType.error
          }
        )
      }
    }
  }
})
