
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { DeleteIcon } from '@/components/icons'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'DeleteTool',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, DeleteIcon
  },

  props: {
    toolId: { type: String, required: true }
  },

  data: function () {
    return {
      loading: false
    }
  },

  methods: {
    onDelete: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'tools/deleteTool',
          { toolId: this.toolId }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'eliminazione tipologia strumento fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione tipologia strumento riuscita',
          type: AlertType.success
        })

        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('tools/reloadTools')
        }, 4000)

        this.$emit('close')
      } catch (error) {
        console.error('error deleting the tool', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione tipologia strumento fallita',
          type: AlertType.error
        })
      }
    }
  }
})
