import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ceff841e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "second flex-grow user-tab-element self-center" }
const _hoisted_2 = { class: "third working-site-users-buttons flex self-center col-span-2" }
const _hoisted_3 = { class: "svg self-center ml-auto" }
const _hoisted_4 = { class: "font-bold user-tab-element suspend mr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ForemanIcon = _resolveComponent("ForemanIcon")!
  const _component_WorkerIcon = _resolveComponent("WorkerIcon")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_RemoveWorkingSiteUserAssignment = _resolveComponent("RemoveWorkingSiteUserAssignment")!
  const _component_ChangeUserRoleDialog = _resolveComponent("ChangeUserRoleDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["working-site-assigned-user mb-2 grid grid-cols-4 text-left overflow-hidden", { 'bg-primary-50': !_ctx.isSuspended, 'bg-secondary-50': _ctx.isSuspended }])
    }, [
      _createVNode(_component_router_link, {
        class: "first flex-grow font-bold user-tab-element self-center",
        to: `/users/${_ctx.userId}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.userName), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.isForeman ? 'Capo Cantiere' : 'Operaio'), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isForeman)
            ? (_openBlock(), _createBlock(_component_ForemanIcon, {
                key: 0,
                class: "role-icon self-center cursor-pointer mr-4",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isChangeUserRole()))
              }))
            : (_openBlock(), _createBlock(_component_WorkerIcon, {
                key: 1,
                class: "role-icon self-center cursor-pointer mr-4",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isChangeUserRole()))
              }))
        ]),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleUserAssignmentSospension()))
        }, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.isSuspended ? 'Annulla sospensione' : 'Sospendi'), 1)
        ]),
        _createElementVNode("button", null, [
          _createElementVNode("h3", {
            class: "font-bold user-tab-element delete-button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showRemoveUserDialog()))
          }, "Elimina")
        ])
      ])
    ], 2),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          message: "Salvataggio..."
        }))
      : _createCommentVNode("", true),
    (_ctx.removeUser)
      ? (_openBlock(), _createBlock(_component_RemoveWorkingSiteUserAssignment, {
          key: 1,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hideRemoveUserDialog())),
          "working-site-id": _ctx.workingSiteId,
          "working-site-name": _ctx.workingSiteName,
          "user-id": _ctx.userId,
          "user-name": _ctx.userName,
          "is-foreman": _ctx.isForeman
        }, null, 8, ["working-site-id", "working-site-name", "user-id", "user-name", "is-foreman"]))
      : _createCommentVNode("", true),
    (_ctx.changeUserRole)
      ? (_openBlock(), _createBlock(_component_ChangeUserRoleDialog, {
          key: 2,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hideChangeUserRole())),
          "user-name": _ctx.userName,
          "is-foreman": _ctx.isForeman,
          "user-id": _ctx.userId,
          "working-site-id": _ctx.workingSiteId
        }, null, 8, ["user-name", "is-foreman", "user-id", "working-site-id"]))
      : _createCommentVNode("", true)
  ], 64))
}