/* eslint-disable operator-linebreak */
import { ICompanyCommunicationModel } from '@sultan/shared'
import { db, companyCommunicationsCollection } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { ICompanyCommunicationsStoreState } from '../interfaces'
import { v4 as UUID } from 'uuid'

export const CommunicationsStore: Module<ICompanyCommunicationsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    communication: undefined as ICompanyCommunicationModel | undefined,
    communications: [] as ICompanyCommunicationModel[]
  },

  mutations: {
    ...vuexfireMutations,

    setCommunications (state, { communications }) {
      console.log('DocumentsStore - setCommunications', { communications })
      state.communications = communications
    }
  },

  actions: {
    async fetchCommunications (context: ActionContext<ICompanyCommunicationsStoreState, unknown>): Promise<void> {
      console.log('CommunicationsStore - fetchCommunications')

      try {
        const communications = (await companyCommunicationsCollection.orderBy('createdTs', 'desc').get()).docs.map(d => d.data())
        context.commit('setCommunications', { communications })
      } catch (error) {
        console.error('CommunicationsStore - error fetching the events', { context, error })
      }
    },

    // Communication
    bindCommunicationRef: firestoreAction((context, payload) => {
      console.debug(payload)
      console.debug('bindCommunicationRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('communication', companyCommunicationsCollection.doc(payload.id))
    }),
    unbindCommunicationRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindCommunicationRef')
      unbindFirestoreRef('communication')
    }),
    createNewCommunication: firestoreAction(async (context, payload: {
      title: string,
      subtitle: string,
      description: string,
      documents?: string[],
    }): Promise<ICompanyCommunicationModel | undefined> => {
      console.debug('createCommunication')
      if (payload === undefined || payload.title === undefined) { return }

      const communication: ICompanyCommunicationModel = {
        title: payload.title,
        subtitle: payload.subtitle,
        description: payload.description,
        id: UUID(),
        createdTs: (new Date()).valueOf() / 1000,
        isArchived: false,
        documents: {}
      }

      try {
        await companyCommunicationsCollection.doc(communication.id).set(communication)

        console.debug('createCommunication - success')
        return communication
      } catch (error) {
        console.error('CommunicationsStore - error creating the communication', { context, error })
      }

      return undefined
    }),
    updateCommunication: firestoreAction(async (context, payload: {
      id: string,
      title: string,
      subtitle: string,
      isArchived: boolean,
      description: string,
      documents?: string[],
    }): Promise<boolean> => {
      console.debug('updateCommunication', { payload })
      if (payload === undefined || payload.id === undefined) { return false }

      const communication: { [key: string]: any } = {
        id: payload.id
      }

      if (payload.title !== undefined) { communication.title = payload.title }
      if (payload.subtitle !== undefined) { communication.subtitle = payload.subtitle }
      if (payload.description !== undefined) { communication.description = payload.description }
      if (payload.isArchived !== undefined) { communication.isArchived = payload.isArchived }

      try {
        await companyCommunicationsCollection.doc(communication.id).update(communication)

        console.debug('updateCommunication - success')
        return true
      } catch (error) {
        console.error('CommunicationsStore - error updating the communication', { context, error })
      }

      return false
    }),
    deleteCommunication: firestoreAction(async (
      context,
      payload: { communicationId: string }
    ): Promise<boolean> => {
      console.debug('DocumentsStore - deleteDocument', { payload })
      if (payload === undefined || payload.communicationId === undefined) { return false }

      const destination = companyCommunicationsCollection.doc(payload.communicationId)
      if (destination === undefined) { return false }

      try {
        await destination.delete()
        context.dispatch('fetchCommunications', payload)
      } catch (error) {
        console.error('DocumentsStore - error removing the working site document', { context, workingSiteId: payload.communicationId, error })

        return false
      }

      console.debug('DocumentsStore - deleteDocument - success')

      return true
    }),
    archiveCommunication: firestoreAction(async (context, payload: {
      communicationId: string
    }): Promise<boolean> => {
      console.debug('archiveCommunication', { payload })
      if (
        payload === undefined
        || payload.communicationId === undefined
      ) { return false }

      console.log('CommunicationsStore - reloadCommunications')
      try {
        await companyCommunicationsCollection.doc(payload.communicationId).update({
          isArchived: true
        })

        context.commit('reloadCommunications')
      } catch (error) {
        console.error('CommunicationsStore - error searching inside the communications index', { context, error })
        return false
      }

      console.debug('archiveCommunication - success')
      return true
    })
  }
}
