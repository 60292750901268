import { createStore } from 'vuex'
import { vuexfireMutations } from '@xquick-code/vuexfire'

import { IStoreState } from '../interfaces'

import { AlertsStore } from './alerts.store'
import { AuthenticationStore } from './authentication.store'
import { ClockingInsStore } from './clocking-ins.store'
import { CompanyNotificationsStore } from './company-notifications.store'
import { DocumentsStore } from './documents.store'
import { EventsStore } from './events.store'
import { ToolsStore } from './tools.store'
import { UsersStore } from './users.store'
import { WorkingSitesStore } from './working-sites.store'
import { CommunicationsStore } from './company-communications.store'
import { WorkReportsStore } from './work-reports.store'
import { UserExpensesStore } from './user-expenses.store'
import { ChatStore } from './chat.store'

export default createStore<IStoreState>({
  modules: {
    alerts: AlertsStore,
    auth: AuthenticationStore,
    chat: ChatStore,
    clockingIns: ClockingInsStore,
    communications: CommunicationsStore,
    companyNotifications: CompanyNotificationsStore,
    documents: DocumentsStore,
    events: EventsStore,
    tools: ToolsStore,
    userExpenses: UserExpensesStore,
    users: UsersStore,
    workingSites: WorkingSitesStore,
    workReports: WorkReportsStore
  },

  mutations: vuexfireMutations
})
