
import { defineComponent } from 'vue'
import ModalDialog from './dialogs/ModalDialog.vue'
import PrimaryButton from './buttons/PrimaryButton.vue'
import Loader from './Loader.vue'
import UploadedDocumentPreview from '@/components/UploadedDocumentPreview.vue'
import { IDocumentModel } from '@sultan/shared'

export default defineComponent({
  name: 'UploadForm',

  emits: ['close', 'update:modelValue'],

  components: {
    ModalDialog, PrimaryButton, Loader, UploadedDocumentPreview
  },

  props: {
    modelValue: {
      type: Object as () => { f?: File, d?: IDocumentModel },
      required: false,
      default: undefined
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    onDelete: function () {
      console.log('on delete')

      this.$emit('update:modelValue', undefined)
    },

    onUploadClick: function () {
      (this.$refs.file as any).click()
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    onUploadingFile: function (event: any) {
      console.log('onUploadingFile', { event })
    },

    onChange: function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      console.log('onChange', { addingFiles: (this.$refs.file as any).files })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      const filelist = [
        ...(this.$refs.file as any).files
      ].map((f: File) =>
        ({
          d: {
            title: f.name,
            mimeType: f.type
          },
          f
        })
      ) as any[]

      if (filelist.length === 1) {
        this.$emit('update:modelValue', filelist[0])
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    onDragEnter: function (event: any) {
      event.preventDefault()
      console.log('onDragEnter', { event })
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    onDragOver: function (event: any) {
      event.preventDefault()
      console.log('onDragOver', { event })
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    onDragLeave: function (event: any) {
      console.log('onDragLeave', { event })
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    onDrop: function (event: any) {
      event.preventDefault();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      (this.$refs.file as any).files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    }
  }
})
