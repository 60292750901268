import { usersWorkReportsCollection, workReportsCollectionGroup } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { vuexfireMutations } from '@xquick-code/vuexfire'
import { IWorkReportsStoreState } from '@/interfaces/work-reports-store-state.interface'
import { IWorkReport } from '@sultan/shared'
import firebase from 'firebase'

export const WorkReportsStore: Module<IWorkReportsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    workReports: [] as IWorkReport[],
    workReportRawDocuments: [] as firebase.firestore.QueryDocumentSnapshot[]
  },

  mutations: {
    ...vuexfireMutations,
    setWorkReports (state, { workReports, rawData, forceReload }) {
      console.log('WorkReportsStore - setWorkReports', { workReports, rawData, forceReload })
      if (forceReload) {
        state.workReports = workReports
        state.workReportRawDocuments = rawData
      } else {
        state.workReports = [...state.workReports, ...workReports]
        state.workReportRawDocuments = [...state.workReportRawDocuments, ...rawData]
      }
    },
    resetWorkReport (state) {
      state.workReports = []
      state.workReportRawDocuments = []
    }
  },

  actions: {
    async paginateWorkReports (
      context: ActionContext<IWorkReportsStoreState, unknown>,
      params: { workingSiteId?: string; userId?: string; limit?: number; forceReload?: boolean }
    ): Promise<void> {
      console.log('WorkReportsStore - paginateWorkReports', { params })

      let workReportRawDocuments: firebase.firestore.QueryDocumentSnapshot[] = []
      let workReportDocuments: IWorkReport[] = []

      try {
        let query
        if (params.userId !== undefined) {
          query = usersWorkReportsCollection(params.userId)
        } else {
          query = workReportsCollectionGroup
        }

        if (params.workingSiteId !== undefined) {
          query = query.where('workingSiteId', '==', params.workingSiteId)
        }
        if (params.limit !== undefined) {
          query = query.limit(params.limit)
        }

        query = query.orderBy('createdTs', 'desc')

        if (params.forceReload !== true && context.state.workReportRawDocuments.length > 0) {
          query = query.startAfter(context.state.workReportRawDocuments[
            (context.state.workReportRawDocuments as any).length - 1
          ])
        }

        workReportRawDocuments = (await query.get()).docs
        workReportDocuments = [...workReportRawDocuments].map(doc => doc.data()) as IWorkReport[]
      } catch (error) {
        console.error('WorkReportsStore - error fetching the workReports', { context, error })
      }

      context.commit('setWorkReports', { workReports: workReportDocuments, rawData: workReportRawDocuments, forceReload: params.forceReload })
    }
  }
}
