
import { defineComponent } from 'vue'
import { RemoveWorkingSiteUserAssignment, Loader } from '@/components'
import { AlertType } from '@/enums'
import { IAssignmentModel, IWorkingSiteModel } from '@sultan/shared'
import moment from 'moment'

export default defineComponent({
  name: 'UserAssignedWorkingSite',

  components: {
    RemoveWorkingSiteUserAssignment, Loader
  },

  emits: ['update'],

  props: {
    modelValue: Object as () => IAssignmentModel & { workingSite: IWorkingSiteModel }
  },

  created: function () {
    (this as any).moment = moment
  },

  data () {
    return {
      removeUser: false,
      loading: false
    }
  },

  methods: {
    showRemoveUserDialog: function () {
      this.removeUser = true
    },
    hideRemoveUserDialog: function () {
      this.removeUser = false

      // Add a delay to ensure that the Algolia Index is updated
      setTimeout(() => {
        // Then we reload the users
        this.$emit('update')
      }, 4000)
    },
    toggleUserAssignmentSospension: async function () {
      if (!this.modelValue) { return }
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'workingSites/updateWorkingSiteAssignmentStatus',
          {
            workingSiteId: this.modelValue.id,
            userId: this.modelValue.userId,
            isEnabled: !this.modelValue.isEnabled // the opposite of the opposite
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch(
            'alerts/create',
            {
              message: 'errore nell\'aggiornamento dello stato di assegnazione utente al cantiere',
              type: AlertType.error
            }
          )
          return
        }

        await this.$store.dispatch(
          'alerts/create',
          {
            message: this.modelValue.isEnabled
              ? 'utente sospeso dal cantiere'
              : 'sospensione utente dal cantiere annullata',
            type: AlertType.success
          }
        )
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$emit('update')
        }, 4000)
      } catch (error) {
        console.error('error updating the user assignment status', error)
        this.loading = false
        await this.$store.dispatch(
          'alerts/create',
          {
            message: 'errore nell\'aggiornamento dello stato di assegnazione utente al cantiere',
            type: AlertType.error
          }
        )
      }
    }
  }
})
