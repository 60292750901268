
import { defineComponent } from '@vue/runtime-core'
import { Alert } from '@/components'
import { IAlertsStoreState, IStoreState } from '@/interfaces'
import { createNamespacedHelpers } from 'vuex'
import store from './store'
import { auth, companyNotificationsCollection } from './utilities'
import firebase from 'firebase'
import { AlertType } from '@/enums'
import { INotificationModel, IUserModel, UserRole } from '@sultan/shared'
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const sound = require('@/assets/audio/notification.mp3')
// const audio = new Audio(sound)
// audio.pause()
const { mapActions } = createNamespacedHelpers('auth')
const { mapState } = createNamespacedHelpers('alerts')
const chatHelper = createNamespacedHelpers('chat')

export default defineComponent({
  name: 'App',

  components: {
    Alert
  },

  store: store,

  data: function () {
    return {
      companyNotificationsListener: undefined as any | undefined
    }
  },

  methods: {
    // Include the AuthenticationStore actions
    ...mapActions(['bindAuthUser']),
    ...chatHelper.mapActions(['bindChatGroupsRef', 'unbindChatGroupsRef']),

    listenCompanyNotifications: function () {
      this.companyNotificationsListener = companyNotificationsCollection
        .orderBy('createdTs', 'desc')
        .onSnapshot(
          { includeMetadataChanges: true },
          async (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>): Promise<void> => {
            console.log('Home - new notification', { snapshot })
            const last: INotificationModel = await snapshot.docs[0].data() as INotificationModel

            console.log('Home - last', { last })

            await this.$store.dispatch('alerts/create', { message: last.title, type: AlertType.info })
            //   audio.play()
            await this.$store.commit('companyNotifications/appendLastNotifications', { notification: last })
          }
        )
    },
    unlistenCompanyNotifications: function () {
      if (this.companyNotificationsListener !== undefined) {
        this.companyNotificationsListener()
      }
    }
  },

  mounted () {
    // Bind and listen the Firebase Auth authentication status
    console.debug('AuthenticationStore - bindAuthUser')
    auth
      .onAuthStateChanged(async (authUser) => {
        console.debug('AuthenticationStore - bindAuthUser - received value', { authUser })
        if (authUser && typeof authUser === 'object') {
          this.$store.commit('auth/setAuthUser', authUser)
          this.listenCompanyNotifications()

          const user: IUserModel = await this.$store.dispatch('users/getUser', authUser.uid)
          console.debug('AuthenticationStore - bindAuthUser - user', { authUser, user })

          // eslint-disable-next-line eqeqeq
          if (user == undefined) {
            this.$store.commit('auth/setAuthUser', null)
            this.unlistenCompanyNotifications()
            this.unbindChatGroupsRef()
            await auth.signOut()

            return
          }
          if (user.role < UserRole.ADMIN) {
            this.$store.commit('auth/setAuthUser', null)
            this.unlistenCompanyNotifications()
            this.unbindChatGroupsRef()
            this.$router.replace({ name: 'Login' })
            await this.$store.dispatch('alerts/create', { message: 'Accesso non consetito', type: AlertType.error })
            await auth.signOut()

            return
          }

          this.bindChatGroupsRef()

          // eslint-disable-next-line eqeqeq
          if (this.$route.query != undefined && this.$route.query.redirect != undefined) {
            this.$router.replace({ path: this.$route.query.redirect as string })
          } else {
            this.$router.replace({ name: 'Home' })
          }
        } else {
          this.$store.commit('auth/setAuthUser', null)
          this.unlistenCompanyNotifications()
          this.unbindChatGroupsRef()
        }
      })
  },

  computed: {
    ...mapState<IAlertsStoreState>({
      alerts: (state: IStoreState) => state.alerts
    })
  },

  watch: {
    $route (to, from): void {
      console.log(`to ${to.name} - from ${from.name}`)
      // react to route changes...
    }
  }
})
