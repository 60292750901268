
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import { UserExpenseCard } from '@/components'
import { IClockingInsStoreState } from '@/interfaces'
import { createNamespacedHelpers } from 'vuex'
import { UserExpenseScope } from '@/enums'
const { mapState } = createNamespacedHelpers('userExpenses')

export default defineComponent({
  name: 'UserExpenses',

  components: { LeftArrow, PrimaryButton, UserExpenseCard },

  data () {
    return {
      searchQuery: '',
      isShowingUserAssignment: false
    }
  },

  props: {
    scope: {
      type: Number as () => UserExpenseScope,
      required: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log('ClockingIns - beforeMount', { params: this.$route.params })
    this.paginate(true)
  },

  computed: {
    ...mapState<IClockingInsStoreState>({
      userExpenses: (state: any) => state.userExpenses
    }),
    id: function (): string | undefined {
      switch (this.scope) {
        case UserExpenseScope.workingSite: return this.$route.params.workingSiteId as string
        case UserExpenseScope.user: return this.$route.params.userId as string
        default: return undefined
      }
    },
    facetFilters: function (): string[] {
      switch (this.scope) {
        case UserExpenseScope.workingSite: return [`workingSiteId:${this.id}`]
        case UserExpenseScope.user: return [`userId:${this.id}`]
        default: return []
      }
    }
  },

  methods: {
    goBack () {
      return this.$router.go(-1)
    },
    paginate: async function (forceReload = false): Promise<void> {
      console.log('search', { query: this.searchQuery })

      await this.$store.dispatch('userExpenses/paginateUserExpenses', {
        userId: this.$route.params.userId,
        workingSiteId: this.$route.params.workingSiteId,
        forceReload,
        limit: 15
      })
    }
  },

  watch: {
    userExpensesSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    }
  }
})
