
import { defineComponent } from 'vue'
import SultanLogo from '@/components/SultanLogo.vue' // @ is an alias to /src
import { createNamespacedHelpers } from 'vuex'
import { AlertType } from '@/enums'
import { promiseTimeout } from '@/utilities'
const { mapActions } = createNamespacedHelpers('auth')

export default defineComponent({
  name: 'Login',

  components: {
    SultanLogo
  },

  data () {
    return {
      email: '',
      password: '',
      validationErrors: [] as string[],
      isAuthenticating: false
    }
  },

  methods: {
    // Include the AuthenticationStore actions
    ...mapActions(['signIn']),

    validate () {
      // Clear the errors before we validate again
      // this.resetError();

      // email validation
      if (!this.email) {
        this.validationErrors.push('<strong>E-mail</strong> cannot be empty.')
      }
      if (/.+@.+/.test(this.email) !== true) {
        this.validationErrors.push('<strong>E-mail</strong> must be valid.')
      }
      // password validation
      if (!this.password) {
        this.validationErrors.push('<strong>Password</strong> cannot be empty')
      }
      if (/.{6,}/.test(this.password) !== true) {
        this.validationErrors.push(
          '<strong>Password</strong> must be at least 6 characters long'
        )
      }
    },
    async performLogin (): Promise<void> {
      this.isAuthenticating = true
      await promiseTimeout(500)
      console.debug('performLogin')

      this.validate()

      // when valid then sign in
      if (this.validationErrors.length > 0) {
        await this.$store.dispatch('alerts/create', { message: 'verifica i dati inseriti', type: AlertType.warning })
        console.error('auth not performed due to validation error', { errors: this.validationErrors })
        this.isAuthenticating = false
        return
      }

      const result = await this.signIn({ email: this.email, password: this.password })

      if (result === true) {
        await this.$store.dispatch('alerts/create', { message: 'autenticazione riuscita', type: AlertType.success })
        console.debug('performLogin - ok')
      } else {
        await this.$store.dispatch('alerts/create', { message: 'autenticazione fallita', type: AlertType.error })
        console.error('auth error')
      }

      this.isAuthenticating = false
    }
  }
})
