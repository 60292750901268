import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-753e7b74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fixed z-10 inset-0 bg-black bg-opacity-50 overflow-hidden z-40",
  ref: "modal-backdrop"
}
const _hoisted_2 = { class: "blur-container flex items-start justify-center text-center backdrop-filter backdrop-blur-sm block overflow-hidden" }
const _hoisted_3 = { class: "header text-center" }
const _hoisted_4 = { class: "text-bold left cursor-pointer" }
const _hoisted_5 = { class: "text-bold text-gray-400" }
const _hoisted_6 = { class: "text-bold" }
const _hoisted_7 = { class: "text-gray-600" }
const _hoisted_8 = { class: "block content overflow-y-scroll middle-content" }
const _hoisted_9 = { class: "footer absolute text-right block w-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#dialog-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["main-container bg-white rounded-lg text-left block overflow-hidden relative", _ctx.type]),
          role: "dialog",
          ref: "modal",
          "aria-modal": "true",
          "aria-labelledby": "modal-headline"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", _hoisted_4, [
              _renderSlot(_ctx.$slots, "h4", {}, undefined, true)
            ]),
            _createElementVNode("h3", _hoisted_5, [
              _renderSlot(_ctx.$slots, "h3", {}, undefined, true)
            ]),
            _createElementVNode("h1", _hoisted_6, [
              _renderSlot(_ctx.$slots, "h1", {}, undefined, true)
            ]),
            _createElementVNode("h2", _hoisted_7, [
              _renderSlot(_ctx.$slots, "h2", {}, undefined, true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
          ])
        ], 2)
      ])
    ], 512)
  ]))
}