import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c06e0f06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "content overflow-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "f-to-w"
}
const _hoisted_5 = {
  key: 1,
  class: "w-to-f"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_ForemanToWorkerIcon = _resolveComponent("ForemanToWorkerIcon")!
  const _component_WorkerToForemanIcon = _resolveComponent("WorkerToForemanIcon")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_ModalDialog = _resolveComponent("ModalDialog")!

  return (_openBlock(), _createBlock(_component_ModalDialog, { type: "modal-alert" }, {
    h1: _withCtx(() => [
      _createElementVNode("span", null, "Stai cambiando il ruolo di " + _toDisplayString(_ctx.userName), 1)
    ]),
    h2: _withCtx(() => []),
    h3: _withCtx(() => [
      (_ctx.isForeman)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Rimuovi il ruolo di capocantiere e assegna il ruolo di operaio"))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, "Rimuovi il ruolo di operaio e assegna il ruolo di capocantiere"))
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_PrimaryButton, {
        class: "button bg-white text-primary-900 undo",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Annulla")
        ]),
        _: 1
      }),
      _createVNode(_component_PrimaryButton, {
        class: "button bg-primary-900 text-white",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeRole()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Cambia")
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isForeman)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_ForemanToWorkerIcon)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_WorkerToForemanIcon)
            ]))
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            message: "Salvataggio..."
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}