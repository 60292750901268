
import { AlertType } from '@/enums'
import { IAlgoliaToolItemModel, IUserModel } from '@sultan/shared'
import moment from 'moment'
import { defineComponent } from 'vue'
import DeleteToolItem from './dialogs/DeleteToolItem.vue'
import Loader from '@/components/Loader.vue'

export default defineComponent({
  name: 'ToolItemCard',

  emits: ['update'],

  components: { DeleteToolItem, Loader },

  created: function () {
    (this as any).moment = moment
  },

  props: {
    modelValue: { type: Object as () => IAlgoliaToolItemModel, required: true },
    searchQuery: { type: String, require: false, default: '' }
  },

  data: function () {
    return {
      isShowingToolItemDeleteDialog: false,
      isCollapsed: false,
      loading: false
    }
  },

  methods: {
    onToolItem (): void {
      console.debug('onToolItem')
    },
    isSuspended (user: IUserModel): boolean {
      if (
        // eslint-disable-next-line eqeqeq
        user.toolAssignments != undefined
        && Object.keys(user.toolAssignments).includes(this.modelValue.id)
        && user.toolAssignments[this.modelValue.id].isEnabled
      ) { return false }

      return true
    },
    async toggleAssignmentSuspension (userAssignment: any) {
      console.debug('ToolItemCard - toggleAssignmentSuspension', { userAssignment })
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'tools/updateUserAssignmentStatus',
          {
            toolItemId: this.modelValue.id,
            userId: userAssignment.id,
            isEnabled: !userAssignment.isEnabled
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch(
            'alerts/create',
            {
              message: 'errore nell\'aggiornamento dello stato di assegnazione strumento all\'utente',
              type: AlertType.error
            }
          )
          return
        }

        await this.$store.dispatch(
          'alerts/create',
          {
            message: userAssignment.isEnabled
              ? 'assegnazione strumento all\'utente sospesa'
              : 'sospensione assegnazione strumento all\'utente annullata',
            type: AlertType.success
          }
        )

        this.$emit('update')
      } catch (error) {
        console.error('error updating the user assignment status', error)
        this.loading = false
        await this.$store.dispatch(
          'alerts/create',
          {
            message: 'errore nell\'aggiornamento dello stato di assegnazione strumento all\'utente',
            type: AlertType.error
          }
        )
      }
    },
    async onDeleteUserAssignment (userAssignment: any) {
      console.debug('ToolItemCard - onDeleteUserAssignment', { userAssignment })
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'tools/removeUserAssignment',
          {
            toolItemId: this.modelValue.id,
            userId: userAssignment.id
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch(
            'alerts/create',
            {
              message: 'errore nell\'eliminazione della assegnazione dello strumento all\'utente',
              type: AlertType.error
            }
          )
          return
        }

        await this.$store.dispatch(
          'alerts/create',
          {
            message: 'eliminata assegnazione strumento all\'utente',
            type: AlertType.success
          }
        )

        this.$emit('update')
      } catch (error) {
        console.error('error deleting the user assignment', error)
        this.loading = false
        await this.$store.dispatch(
          'alerts/create',
          {
            message: 'errore nell\'eliminazione della assegnazione dello strumento all\'utente',
            type: AlertType.error
          }
        )
      }
    }
  },

  watch: {
    isCollapsed: function (value) {
      console.log('isCollapsed change: ' + value)
    }
  }
})
