import { companyNotificationsCollection } from '@/utilities'
import { Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { ICompanyNotificationsStoreState } from '@/interfaces'
import { INotificationModel } from '@sultan/shared'
import firebase from 'firebase'

export const CompanyNotificationsStore: Module<ICompanyNotificationsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    lastNotificationFetched: undefined as firebase.firestore.DocumentSnapshot | undefined,
    notification: undefined as INotificationModel | undefined,
    notifications: [] as INotificationModel[]
  },

  mutations: {
    ...vuexfireMutations,
    appendLastNotifications (state, { notification }) {
      console.log('NotificationsStore - appendLastNotifications', { notification })
      state.notifications.pop()
      state.notifications = [notification, ...state.notifications].reduce(
        (acc, not) => {
          if (acc.find((n: any) => n.id === not.id) === undefined) {
            acc.push(not)
          }

          return acc
        },
        []
      )
    },
    appendNotificationsDocuments (state, { documents }) {
      console.log('NotificationsStore - appendNotificationsDocuments', { documents })
      state.notifications = [...state.notifications, ...documents.map((doc: firebase.firestore.DocumentSnapshot) => doc.data() as INotificationModel)]
        .reduce(
          (acc, not) => {
            if (acc.find((n: any) => n.id === not.id) === undefined) {
              acc.push(not)
            }

            return acc
          },
          []
        )
      state.lastNotificationFetched = (documents as firebase.firestore.DocumentSnapshot[]).length > 0
        ? (documents[documents.length - 1] as firebase.firestore.DocumentSnapshot)
        : undefined
    },
    setNotificationsDocuments (state, { documents }) {
      console.log('NotificationsStore - setNotificationsDocuments', { documents })
      state.notifications = documents.map((doc: firebase.firestore.DocumentSnapshot) => doc.data() as INotificationModel)
        .reduce(
          (acc: INotificationModel[], not: INotificationModel) => {
            if (acc.find((n: any) => n.id === not.id) === undefined) {
              acc.push(not)
            }

            return acc
          },
          []
        )
      state.lastNotificationFetched = (documents as firebase.firestore.DocumentSnapshot[]).length > 0
        ? (documents[documents.length - 1] as firebase.firestore.DocumentSnapshot)
        : undefined
    },
    resetNotification (state) {
      state.notifications = []
    }
  },

  actions: {
    refreshNotifications: firestoreAction(async (context): Promise<void> => {
      console.log('NotificationsStore - refreshNotifications')
      if ((context.state.notifications as INotificationModel[]).length < 1 || context.state.lastNotificationFetched === undefined) {
        console.log('NotificationsStore - refreshNotifications - empty list')
        await context.dispatch('paginateNotifications')
        return
      }
      console.log('NotificationsStore - refreshNotifications - existing list')

      let notificationDocuments: firebase.firestore.DocumentSnapshot[] = []

      try {
        console.log('NotificationsStore - refreshNotifications - last', { doc: context.state.lastNotificationFetched })
        notificationDocuments = (await companyNotificationsCollection
          .orderBy('createdTs', 'desc')
          .endAt(context.state.lastNotificationFetched)
          .get())
          .docs
      } catch (error) {
        console.error('NotificationsStore - error refreshing the notifications', { context, error })
      }

      context.commit('setNotificationsDocuments', { documents: notificationDocuments })
    }),

    paginateNotifications: firestoreAction(async (context): Promise<void> => {
      console.log('NotificationsStore - paginateNotifications')
      let notificationDocuments: firebase.firestore.DocumentSnapshot[] = []

      try {
        let query = companyNotificationsCollection
          .orderBy('createdTs', 'desc')

        if (context.state.lastNotificationFetched !== undefined) {
          query = query.startAfter(context.state.lastNotificationFetched)
        }

        notificationDocuments = (await query.limit(15).get()).docs
      } catch (error) {
        console.error('NotificationsStore - error fetching the notifications', { context, error })
      }

      context.commit('appendNotificationsDocuments', { documents: notificationDocuments })
    }),

    // Notification
    bindNotificationRef: firestoreAction((context, payload) => {
      console.debug(payload)
      console.debug('bindNotificationRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('notification', companyNotificationsCollection.doc(payload.id))
    }),
    unbindNotificationRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindNotificationRef')
      unbindFirestoreRef('notification')
    })
  }
}
