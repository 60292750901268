
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { NotificationDocument } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ICompanyNotificationsStoreState } from '@/interfaces'
import moment from 'moment'
import { IDocumentModel, NotificationType } from '@sultan/shared'
const { mapState } = createNamespacedHelpers('companyNotifications')

export default defineComponent({
  name: 'Notification',

  components: {
    PrimaryButton, NotificationDocument
  },

  data: () => {
    return {
      document: undefined as IDocumentModel | undefined
    }
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindCompanyNotification()
        await this.bindCompanyNotification()
      },
      deep: true,
      immediate: true
    },
    notification: async function (notification) {
      // eslint-disable-next-line eqeqeq
      if (notification != undefined && notification.data != undefined && notification.type === NotificationType.WORKING_SITE_UNAPPROVED_DOCUMENT_CREATED) {
        await this.getDocumentData({
          documentId: notification.data.documentId,
          workingSiteId: notification.data.workingSiteId
        })

        return
      }

      this.document = undefined
    }
  },

  computed: {
    ...mapState<ICompanyNotificationsStoreState>({
      notification: (state: ICompanyNotificationsStoreState) => state.notification,
      notificationTypeString: (state: ICompanyNotificationsStoreState) => {
        if (state.notification === undefined) { return '' }

        switch (state.notification.type) {
          case NotificationType.ADMIN_LOGGED_IN: return 'Amministratore collegato'
          case NotificationType.USER_APP_ACCESS_DISABLED: return 'Accesso app utente disabilito'
          case NotificationType.USER_APP_ACCESS_ENABLED: return 'Accesso app utente abilitato'
          case NotificationType.USER_ARCHIVED: return 'Utente archiviato'
          case NotificationType.USER_ASSIGNED_AS_FOREMAN_TO_WORKING_SITE: return 'Utente assegnato al ruolo di capocantiere'
          case NotificationType.USER_ASSIGNED_TO_WORKING_SITE: return 'Utente aggiunto ad un cantiere'
          case NotificationType.USER_CREATED: return 'Utente creato'
          case NotificationType.USER_DISABLED: return 'Utente disabilitato'
          case NotificationType.USER_DOCUMENT_SIGNATURE: return 'Documento utente firmato'
          case NotificationType.USER_ENABLED: return 'Utente abilitato'
          case NotificationType.USER_EVENT_VIEW: return 'Presa visione evento di un utente'
          case NotificationType.USER_ROLE_CHANGED: return 'Ruolo utente alterato'
          case NotificationType.USER_UNASSIGNED_AS_FOREMAN_FROM_WORKING_SITE: return 'Utente rimosso dal ruolo di capocantiere'
          case NotificationType.USER_UNASSIGNED_FROM_WORKING_SITE: return 'Utente rimosso da un cantiere'
          case NotificationType.TOOL_CREATED: return 'Tipologia strumento creata'
          case NotificationType.TOOL_DELETED: return 'Tipologia strumento rimossa'
          case NotificationType.TOOL_ITEM_ASSIGNED_TO_USER: return 'Strumento assegnato ad un utente'
          case NotificationType.TOOL_ITEM_CREATED: return 'Strumento creato'
          case NotificationType.TOOL_ITEM_DELETED: return 'Strumento rimosso'
          case NotificationType.TOOL_ITEM_UNASSIGNED_FROM_USER: return 'Strumento rimosso da un utente'
          case NotificationType.TOOL_ITEM_UPDATED: return 'Strumento aggiornato'
          case NotificationType.TOOL_UPDATED: return 'Tipologia strumento aggiornata'
          case NotificationType.USER_CLOCKING_IN_CREATED: return 'Timbratura utente'
          case NotificationType.USER_CLOCKING_IN_OUT_REMINDER: return 'Promemoria trimbratura uscita utente'
          case NotificationType.USER_CLOCKING_IN_REMINDER: return 'Promemoria trimbratura ingresso utente'
          case NotificationType.USER_CLOCKING_IN_UPDATED: return 'Timbratura utente aggiornata'
          case NotificationType.USER_CLOCKING_OUT_REMINDER: return 'Promemoria trimbratura uscita utente'
          case NotificationType.EVENT_ASSIGNED_TO_USER: return 'Evento assegnato ad un utente'
          case NotificationType.EVENT_CREATED: return 'Evento creato'
          case NotificationType.EVENT_DELETED: return 'Evento eliminato'
          case NotificationType.EVENT_ENDED: return 'Evento terminato'
          case NotificationType.EVENT_STARTED: return 'Evento iniziato'
          case NotificationType.EVENT_UNASSIGNED_FROM_USER: return 'Assegnazione evento ad un utente rimossa'
          case NotificationType.EVENT_UPDATED: return 'Evento aggiornato'
          case NotificationType.COMPANY_COMMUNICATION_CREATED: return 'Comunicazione aziendale creata'
          case NotificationType.COMPANY_COMMUNICATION_DELETED: return 'Comunicazione aziendale rimossa'
          case NotificationType.COMPANY_COMMUNICATION_DOCUMENT_ADDED: return 'Documento aggiunto a comunicazione aziendale'
          case NotificationType.COMPANY_COMMUNICATION_DOCUMENT_REMOVED: return 'Documento rimosso da comunicazione aziendale'
          case NotificationType.COMPANY_COMMUNICATION_UPDATED: return 'Comunicazione aziendale aggiornata'
          case NotificationType.COMPANY_DOCUMENT_CREATED: return 'Documento aziendale creato'
          case NotificationType.COMPANY_DOCUMENT_DELETED: return 'Documento aziendale rimosso'
          case NotificationType.WORKING_SITE_APPROVED_DOCUMENT_CREATED: return 'Documento cantiere creato'
          case NotificationType.WORKING_SITE_ARCHIVED: return 'Cantiere archiviato'
          case NotificationType.WORKING_SITE_CREATED: return 'Cantiere creato'
          case NotificationType.WORKING_SITE_DELETED: return 'Cantiere rimosso'
          case NotificationType.WORKING_SITE_DOCUMENT_APPROVED: return 'Documento cantiere approvato'
          case NotificationType.WORKING_SITE_DOCUMENT_DELETED: return 'Documento cantiere rimosso'
          case NotificationType.WORKING_SITE_DOCUMENT_TAG_CREATED: return 'Tag documento cantiere creato'
          case NotificationType.WORKING_SITE_DOCUMENT_TAG_DELETED: return 'Tag documento cantiere rimosso'
          case NotificationType.WORKING_SITE_DOCUMENT_UPDATED: return 'Documento cantiere aggiornato'
          case NotificationType.WORKING_SITE_EXPIRED: return 'Cantiere scaduto'
          case NotificationType.WORKING_SITE_PPE_CREATED: return 'Strumento cantiere creato'
          case NotificationType.WORKING_SITE_PPE_DELETED: return 'Strumento cantiere eliminato'
          case NotificationType.WORKING_SITE_STARTED: return 'Inizio cantiere'
          case NotificationType.WORKING_SITE_UNAPPROVED_DOCUMENT_CREATED: return 'Documento cantiere da approvare caricato'
          case NotificationType.WORKING_SITE_UNARCHIVED: return 'Cantiere riprisitnato'
          default: return ''
        }
      }
    })
  },
  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindCompanyNotification()
  },

  async mounted () {
    // eslint-disable-next-line eqeqeq
    if ((this as any).notification != undefined) {
      await this.getDocumentData({
        documentId: (this as any).notification.data.documentId,
        workingSiteId: (this as any).notification.data.workingSiteId
      })
    }
  },

  async beforeUnmount () {
    await this.unbindCompanyNotification()
  },

  methods: {
    getDocumentData: async function (payload: {documentId: string; workingSiteId: string }) {
      console.log('getDocumentData', { payload })
      try {
        this.document = await this.$store.dispatch(
          'documents/getWorkingSiteDocument',
          {
            documentId: payload.documentId,
            workingSiteId: payload.workingSiteId
          }
        )
        return
      } catch (e) {
        console.error('error dispatching the firestore mutation bindNotificationRef')
        console.error(e)
      }

      this.document = undefined
    },
    bindCompanyNotification: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('companyNotifications/bindNotificationRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindNotificationRef')
        console.error(e)
      }
    },
    unbindCompanyNotification: async function () {
      try {
        await this.$store.dispatch('companyNotifications/unbindNotificationRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindNotificationRef')
        console.error(e)
      }
    },

    datePrettyPrinted: function (): string {
      const not = (this as any).notification
      if (not === undefined) { return '' }
      if (moment().isSame(moment.unix(not.createdTs), 'day')) { return 'Oggi' }
      if (moment().subtract(1, 'day').isSame(moment.unix(not.createdTs), 'day')) { return 'Ieri' }

      return moment.unix(not.createdTs).format('DD/MM/YYYY')
    },

    timePrettyPrinted: function (): string {
      return (this as any).notification === undefined
        ? ''
        : moment.unix((this as any).notification.createdTs).format('HH:mm')
    }
  }
})
