
import { defineComponent } from 'vue'
import { PrimaryButton, SecondaryButton, Loader } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { IUsersStoreState } from '@/interfaces'
import { IUserModel, UserRole } from '@sultan/shared'
import { EditUserModalDialog, ArchivieUserDocument } from '@/components/dialogs'
import { EditUserModalDialogMode } from '@/enums'
const { mapState } = createNamespacedHelpers('users')

export default defineComponent({
  name: 'SettingUsers',

  components: {
    PrimaryButton, SecondaryButton, Loader, EditUserModalDialog, ArchivieUserDocument
  },

  data () {
    return {
      userDialogIsPresented: false,
      userArchiveDialogIsPresented: false,
      userDialogMode: EditUserModalDialogMode.addUser,
      searchQuery: '',
      userDialogUser: undefined as IUserModel | undefined
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.users)
      this.searchQuery = this.$store.state.users.usersSearchQuery
      await this.$store.dispatch('users/reloadUsers')
    } catch (e) {
      console.error('error reloading the users')
      console.error(e)
    }
  },

  computed: {
    ...mapState<IUsersStoreState>({
      users: (state: IUsersStoreState) => state.users.sort((a, b) => b.role - a.role),
      usersSearchQuery: (state: IUsersStoreState) => state.usersSearchQuery
    })
  },

  methods: {
    showAddNewUserDialog: function () {
      this.userDialogMode = EditUserModalDialogMode.addUser
      this.userDialogIsPresented = true
    },
    hideUserDialog: function () {
      this.userDialogIsPresented = false
    },
    hideRemoveUserDialog: function () {
      this.userArchiveDialogIsPresented = false
    },
    search: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('users/paginateUsers', { forceReload: true, query: this.searchQuery })
    },
    paginate: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('users/paginateUsers', { forceReload: false, query: this.searchQuery })
    },
    parseUserRole: (role: UserRole): string => {
      if (role >= UserRole.SUPER_ADMIN) { return 'Super amministratore' }
      if (role >= UserRole.ADMIN) { return 'Amministratore' }
      if (role >= UserRole.WORKER) { return 'Dipendente' }
      if (role >= UserRole.USER) { return 'Utente' }

      return 'Ospite'
    },
    onEditUser: function (user: IUserModel) {
      console.log('SettingUsers - onEditUser', { user })
      this.userDialogMode = EditUserModalDialogMode.editUser
      this.userDialogUser = user
      this.userDialogIsPresented = true
    },
    onRemoveUser: function (user: IUserModel) {
      this.userDialogUser = user
      this.userArchiveDialogIsPresented = true
    },
    onUserInfo: function (user: IUserModel) {
      this.userDialogUser = user
      this.userArchiveDialogIsPresented = true
    }
  },

  watch: {
    usersSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    }
  }
})
