
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import WorkingSitePpeCard from '@/components/WorkingSitePpeCard.vue'
import { createNamespacedHelpers } from 'vuex'
import { IWorkingSitesStoreState } from '@/interfaces'
import Loader from '@/components/Loader.vue'
import { AlertType } from '@/enums'

const { mapState, mapActions } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSitePpe',

  components: {
    LeftArrow, PrimaryButton, WorkingSitePpeCard, Loader
  },

  data () {
    return {
      name: '',
      loading: false
    }
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      ppes: function (state: IWorkingSitesStoreState) {
        // eslint-disable-next-line eqeqeq
        return state.workingSite == undefined || state.workingSite.ppe == undefined
          ? []
          : Object.values(state.workingSite.ppe).sort((t1, t2) => t2.localeCompare(t1))
      }
    }),
    id: function () {
      return this.$route.params.workingSiteId
    }
  },

  watch: {
    '$route.params.workingSiteId': {
      handler: async function (id) {
        console.log(id)
        await this.bindWorkingSite()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.workingSiteId}`)
    await this.bindWorkingSite()
  },

  async beforeUnmount () {
    await this.unbindWorkingSite()
  },

  methods: {
    ...mapActions(['addWorkingSitePpe']),
    async goBack () {
      await this.$store.dispatch('alerts/create', {
        message: '<p style="font-size: 11px">É necessario rigenerare il QR code ad ogni modifica del materiale richiesto.</p>',
        type: AlertType.info
      })
      return this.$router.back()
    },

    bindWorkingSite: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.workingSiteId == undefined || typeof this.$route.params.workingSiteId !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('workingSites/bindWorkingSiteRef', { id: this.$route.params.workingSiteId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSiteRef')
        console.error(e)
      }
    },
    unbindWorkingSite: async function () {
      try {
        await this.$store.dispatch('workingSites/unbindWorkingSiteRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },

    onAddPpe: async function () {
      this.loading = true

      try {
        const result = await this.addWorkingSitePpe({ ppe: this.name, workingSiteId: this.id })

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione dpi fallita',
            type: AlertType.error
          })
          return
        }

        this.name = ''
        await this.$store.dispatch('alerts/create', { message: 'creazione dpi riuscita', type: AlertType.success })
      } catch (error) {
        console.error('error creating the event', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'creazione dpi fallita',
          type: AlertType.error
        })
      }
    }
  }

})
