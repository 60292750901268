
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { IAlgoliaUserModel } from '@sultan/shared'
import { AlertType } from '@/enums'
import Checkbox from '@/components/Checkbox.vue'

export default defineComponent({
  name: 'AssignUser',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, Checkbox
  },

  props: {
    workingSiteId: { type: String, required: true }
  },

  data () {
    return {
      users: [],
      defaultUsers: [],
      usersSearchFilter: '',
      userIds: [],
      foremanIds: [],
      loading: false,
      assigments: []
    }
  },

  async mounted () {
    this.searchUsers()
  },

  computed: {

    unselectedUsers: function (): IAlgoliaUserModel[] {
      return this.users.filter((u: IAlgoliaUserModel) => {
        return !(this.userIds as any).includes(u.id)
      })
    },
    selectedUsers: function (): IAlgoliaUserModel[] {
      return this.defaultUsers.filter((u: IAlgoliaUserModel) => {
        return (this.userIds as any).includes(u.id)
      })
    }
  },

  methods: {
    isForeman: function (id: string): boolean {
      return (this.foremanIds as any).includes(id)
    },
    // Step 2
    addUser: function (userId: string): void {
      (this.userIds as any).push(userId)
    },
    removeUser: function (userId: string): void {
      this.userIds = this.userIds.filter((u) => u !== userId)
    },
    toggleForman: function (userId: string): void {
      console.log(`toggleForman - ${userId}`)
      if ((this.foremanIds as any).includes(userId)) {
        this.foremanIds = this.foremanIds.filter(
          (id) => id !== userId
        )
      } else {
        (this.foremanIds as any).push(userId)
      }
    },
    searchUsers: async function (): Promise<void> {
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        query: this.usersSearchFilter
      })
      this.defaultUsers = await this.$store.dispatch('users/instantSearchUsers', { })

      console.debug('searchUsers', { users: this.users })
    },

    addUsers: async function (): Promise<void> {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'workingSites/updateWorkingSite',
          {
            id: this.workingSiteId,
            userIds: this.userIds,
            foremanIds: this.foremanIds
          }
        )

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'aggiunta utenti fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'aggiunta utenti riuscita',
          type: AlertType.success
        })
        this.$emit('close')
      } catch (error) {
        console.error('error creating the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'aggiunta utenti fallita',
          type: AlertType.error
        })
      }
    }
  }
})
