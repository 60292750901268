
import { defineComponent } from 'vue'
import { AssignUser, UserAssignedWorkingSite, MenuButton } from '@/components'
import { IAssignmentModel, IWorkingSiteModel } from '@sultan/shared'

export default defineComponent({
  name: 'UserAssignedWorkingSites',

  components: {
    AssignUser, UserAssignedWorkingSite, MenuButton
  },

  props: {
    modelValue: {
      type: Array as () => IAssignmentModel[],
      required: true
    }
  },

  data () {
    return {
      assignedWorkingSites: [] as Array<IAssignmentModel & { workingSite: IWorkingSiteModel }>
    }
  },

  mounted () {
    console.log('UserAssignedWorkingSites - mounted', { modelValue: this.modelValue })
    this.enrichWorkingSitesData()
  },

  methods: {
    enrichWorkingSitesData: async function () {
      try {
        console.log('UserAssignedWorkingSites - mounted', { ids: (this as any).modelValue.map((mv: any) => mv.id) })
        const workingSites = await this.$store.dispatch(
          'workingSites/instantWorkingSites', (this as any).modelValue.map((mv: any) => mv.id)
        )
        console.log('UserAssignedWorkingSites - mounted - working sites', { workingSites })

        this.assignedWorkingSites = (this as any).modelValue.map((mv: any) => {
          const workingSite = workingSites.find((ws: any) => ws.id === mv.id)
          if (workingSite === undefined) { return mv }

          return { ...mv, workingSite }
        })
      } catch (error) {
        console.log('error fetching the user assigned working sites')
      }
    }
  },

  watch: {
    modelValue: {
      handler: async function (value) {
        console.log(value)
        await this.enrichWorkingSitesData()
      },
      deep: true,
      immediate: true
    }
  }
})
