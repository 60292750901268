
import { defineComponent } from 'vue'

import { PrimaryButton, MenuButton, EditUserModalDialog } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { IUsersStoreState } from '@/interfaces'
import { EditUserModalDialogMode } from '@/enums'
const { mapState } = createNamespacedHelpers('users')

export default defineComponent({
  name: 'Users',

  components: {
    MenuButton, PrimaryButton, EditUserModalDialog
  },

  data () {
    return {
      transitionName: '',
      addUserDialogIsPresented: false,
      searchQuery: '',
      EditUserModalDialogMode
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.users)
      this.searchQuery = this.$store.state.users.usersSearchQuery
      await this.$store.dispatch('users/reloadUsers', { forceReload: true })
    } catch (e) {
      console.error('error reloading the users')
      console.error(e)
    }
  },

  computed: {
    ...mapState<IUsersStoreState>({
      users: (state: any) => state.users,
      usersSearchQuery: (state: any) => state.usersSearchQuery
    })
  },

  methods: {
    showNewUserDialog: function () {
      this.addUserDialogIsPresented = true
    },
    hideNewUserDialog: function () {
      this.addUserDialogIsPresented = false
    },
    search: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch(
        'users/paginateUsers',
        { forceReload: true, query: this.searchQuery }
      )
    },
    paginate: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('users/paginateUsers', { forceReload: false, query: this.searchQuery })
    }
  },

  watch: {
    usersSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    }
  }
})
