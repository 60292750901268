
import { defineComponent } from 'vue'
import ModalDialog from '@/components/dialogs/ModalDialog.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import UploadForm from '@/components/UploadForm.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { v4 as UUID } from 'uuid'
import { IToolItemModel, IToolModel } from '@sultan/shared'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'AddNewToolItemModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, UploadForm
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data: function () {
    return {
      primaryField: '',
      secondaryField: '',
      loading: false
    }
  },

  validations () {
    return {
      primaryField: { required },
      secondaryField: { required }
    }
  },

  props: {
    modelValue: {
      type: Object as () => IToolModel,
      required: true
    }
  },

  methods: {
    onConfirm: async function (): Promise<void> {
      console.log('on confirm')
      this.v$.$touch()

      if (this.v$.error) {
        this.loading = false
        return
      }

      this.loading = true

      const doc: IToolItemModel = {
        id: UUID(),
        toolId: this.modelValue.id,
        primaryField: this.primaryField,
        secondaryField: this.secondaryField,
        createdTs: (new Date()).valueOf() / 1000
      }

      try {
        const result = await this.$store.dispatch('tools/addToolItem', doc)

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'creazione strumeto fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'creazione strumeto riuscita', type: AlertType.success })
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('tools/reloadToolItems', { facetFilters: `toolId:${this.modelValue.id}` })
        }, 4000)

        this.$emit('close')
      } catch (error) {
        console.error('error creating the tool item', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'creazione strumeto fallita', type: AlertType.error })
      }
    },
    onClose: function () {
      console.log('on close')
      this.$emit('close')
    }
  }
})
