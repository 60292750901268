import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df5db3b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-site-tag-card mb-2 grid grid-cols-2 text-left overflow-hidden" }
const _hoisted_2 = {
  key: 1,
  class: "font-bold user-tab-element self-center"
}
const _hoisted_3 = { class: "working-site-users-buttons flex text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isEditing)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            class: "font-bold user-tab-element self-center",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.name) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.modelValue.name]
          ])
        : (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.modelValue.name), 1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 0,
              class: "font-bold user-tab-element suspend mr-3 ml-auto",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSave && _ctx.onSave(...args)))
            }, "Salva"))
          : _createCommentVNode("", true),
        (!_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 1,
              class: "font-bold user-tab-element suspend mr-3 ml-auto",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTagEdit && _ctx.onTagEdit(...args)))
            }, "Modifica"))
          : _createCommentVNode("", true),
        (!_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 2,
              class: "font-bold user-tab-element delete-button",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onTagDelete && _ctx.onTagDelete(...args)))
            }, "Elimina"))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          message: "Salvataggio..."
        }))
      : _createCommentVNode("", true)
  ], 64))
}