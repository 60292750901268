
import { defineComponent } from 'vue'
import ModalDialog from '@/components/dialogs/ModalDialog.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { ICompanyCommunicationModel } from '@sultan/shared'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'AddEditCommunicationDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data: function () {
    return {
      title: '',
      subtitle: '',
      description: '',
      loading: false
    }
  },

  validations () {
    return {
      title: { required }
    }
  },

  props: {
    modelValue: {
      type: Object as () => ICompanyCommunicationModel | undefined,
      requried: false,
      default: undefined
    }
  },

  computed: {
    titleText: function (): string {
      return (this.modelValue as any) === undefined
        ? 'Crea nuova comunicazione'
        : 'Modifica comunicazione'
    },
    actionText: function (): string {
      return (this.modelValue as any) === undefined
        ? 'Crea'
        : 'Aggiorna'
    }
  },

  mounted () {
    // eslint-disable-next-line eqeqeq
    if (this.modelValue != undefined) {
      // eslint-disable-next-line vue/no-mutating-props
      this.title = this.modelValue.title
      this.description = this.modelValue.description
      this.subtitle = this.modelValue.subtitle
    }
  },

  methods: {
    onConfirm: async function (): Promise<void> {
      console.log('on confirm', { modelValue: this.modelValue })
      this.v$.$touch()

      if (this.v$.error) {
        this.loading = false
        return
      }

      if (
        // eslint-disable-next-line eqeqeq
        (this.modelValue == undefined || this.modelValue.id == undefined)
      ) {
        // New Communication case
        console.log('AddEditCommunicationDialog - New communication')
        this.loading = true

        try {
          const result = await this.$store.dispatch(
            'communications/createNewCommunication',
            {
              title: this.title,
              subtitle: this.subtitle,
              description: this.description
            }
          )

          this.loading = false
          if (result === undefined) {
            await this.$store.dispatch('alerts/create', { message: 'creazione comunicazione fallita', type: AlertType.error })
            return
          }

          await this.$store.dispatch('alerts/create', { message: 'creazione comunicazione riuscita', type: AlertType.success })

          // Add a delay to ensure that the Algolia Index is updated
          setTimeout(() => {
            // Then we reload the users
            this.$store.dispatch('communications/fetchCommunications')
          }, 4000)

          this.$emit('close')
        } catch (error) {
          console.error('error creating the document', error)
          this.loading = false
          await this.$store.dispatch('alerts/create', { message: 'creazione comunicazione fallita', type: AlertType.error })
        }
      } else {
        // Update case
        console.log('AddEditCommunicationDialog - Update', { m: this.modelValue })

        // eslint-disable-next-line eqeqeq
        if (this.modelValue == undefined || this.modelValue.id == undefined) {
          this.loading = false
          await this.$store.dispatch('alerts/create', { message: 'creazione comunicazione fallita', type: AlertType.error })
          return
        }

        // New Upload case
        this.loading = true

        try {
          const result = await this.$store.dispatch(
            'communications/updateCommunication',
            {
              ...this.modelValue,
              title: this.title,
              subtitle: this.subtitle,
              description: this.description
            } as ICompanyCommunicationModel
          )

          this.loading = false
          if (result === undefined) {
            await this.$store.dispatch('alerts/create', { message: 'aggiornamento comunicazione fallito', type: AlertType.error })
            return
          }

          await this.$store.dispatch('alerts/create', { message: 'aggiornamento comunicazione riuscito', type: AlertType.success })

          // Add a delay to ensure that the Algolia Index is updated
          setTimeout(() => {
            // Then we reload the users
            this.$store.dispatch('communications/fetchCommunications')
          }, 4000)

          this.$emit('close')
        } catch (error) {
          console.error('error creating the document', error)
          this.loading = false
          await this.$store.dispatch('alerts/create', { message: 'aggiornamento comunicazione fallito', type: AlertType.error })
        }
      }
    },
    onClose: function () {
      console.log('on close')
      this.$emit('close')
    }
  }
})
