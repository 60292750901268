import { ActionContext, Module } from 'vuex'
import { IAuthenticationStoreState } from '../interfaces'
import { auth } from '@/utilities'
import firebase from 'firebase'

export const AuthenticationStore: Module<IAuthenticationStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    authUser: undefined as firebase.User | undefined | null
  },

  getters: {
    getAuthUser (state): firebase.User | undefined | null {
      return state.authUser
    },
    isAuthenticated (state): boolean {
      return typeof state.authUser === 'object'
    }
  },

  mutations: {
    setAuthUser (state, payload) {
      console.debug('AuthenticationStore - setAuthUser', { state, payload })
      state.authUser = payload
    }
    /* setError(state, payload) {
      state.error = payload;
    } */
  },

  actions: {
    // Authentication actions
    async signIn (
      context: ActionContext<IAuthenticationStoreState, unknown>,
      params: { email: string, password: string }
    ): Promise<boolean> {
      try {
        await auth
          .signInWithEmailAndPassword(params.email, params.password)
        // commit("setUser", null)
      } catch (error) {
        // commit("setError", error.message);
        console.error('AuthenticationStore - signIn error', { context, params, error })
        return false
      }

      return true
    },

    async signOut (
      context: ActionContext<IAuthenticationStoreState, unknown>
    ): Promise<void> {
      try {
        await auth.signOut()
        context.commit('setAuthUser', undefined)
      } catch (error) {
        // commit("setError", error.message);
        console.error('AuthenticationStore - signOut error', { error })
      }
    }
  }
}
