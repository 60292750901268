
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { IUsersStoreState } from '@/interfaces'
import { AlertType, DocumentScope, EditUserModalDialogMode, ClockingInScope, WorkReportScope, UserExpenseScope } from '@/enums'
import { usersEmployeesFilters } from '@/utilities'
import { IUserModel, UserRole } from '@sultan/shared'
import {
  PrimaryButton, EditUserModalDialog, Toggle, Loader, UserAssignedTools,
  WorkingSiteClockingInsSection, UserAssignedWorkingSites, UserWorkReportsSection,
  UserExpensesSection, SecondaryButton
} from '@/components'
import { AddEditDocumentModalDialog } from '@/components/dialogs'

const { mapState } = createNamespacedHelpers('users')
const chatHelper = createNamespacedHelpers('chat')

export default defineComponent({
  name: 'User',

  components: {
    PrimaryButton, SecondaryButton, EditUserModalDialog, Toggle, Loader, AddEditDocumentModalDialog, UserAssignedTools, WorkingSiteClockingInsSection, UserAssignedWorkingSites, UserWorkReportsSection, UserExpensesSection
  },

  props: {
    modelValue: { type: Object, required: true }
  },

  data () {
    return {
      editUserDialogIsPresented: false,
      editUserDialogMode: EditUserModalDialogMode.editEmployee,
      isShowingAddNewDocument: false,
      loading: false,
      approvedDocuments: undefined as number | undefined,
      unapprovedDocuments: undefined as number | undefined,
      DocumentScope,
      ClockingInScope,
      WorkReportScope,
      UserExpenseScope,
      UserRole
    }
  },

  computed: {
    ...mapState<IUsersStoreState>({
      employee: (state: IUsersStoreState) => state.user,
      workingSiteAssignments: (state: IUsersStoreState) => {
        console.log('workingSiteAssignments', { user: state.user })
        // eslint-disable-next-line eqeqeq
        return state.user == undefined ? [] : Object.values(state.user.workSiteAssignments).sort((t1, t2) => t2.createdTs - t1.createdTs)
      },
      toolItemsAssignments: (state: IUsersStoreState) => {
        console.log('toolItemsAssignments', { user: state.user })
        // eslint-disable-next-line eqeqeq
        return state.user == undefined ? [] : Object.values(state.user.toolAssignments).sort((t1, t2) => t2.createdTs - t1.createdTs)
      }
    })
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindUser()
        await this.bindUser()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindUser()
  },

  async beforeUnmount () {
    await this.unbindUser()
  },

  methods: {
    ...chatHelper.mapActions(['getWorkerChatOrCreateIt']),
    onManageTools: function () { this.$router.push(`${this.$route.path}/ppes`) },
    onDocuments: function () { this.$router.push(`${this.$route.path}/documents`) },
    toggleEnabling: async function (enabled: boolean) {
      console.log('toggleEnabling', enabled)

      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      this.loading = true
      const logStartMessage = `${enabled ? 'abilitazione' : 'disabilitazione'} utente`
      try {
        const result = await this.$store.dispatch(
          'users/updateUser',
          {
            id: this.$route.params.id,
            isEnabled: enabled
          }
        )

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch('alerts/create', { message: `${logStartMessage} fallita`, type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: `${logStartMessage} riuscita`, type: AlertType.success })
        setTimeout(async () => {
          // Then we reload the users
          await this.$store.dispatch('users/reloadUsers', { forceReload: true, filters: usersEmployeesFilters })
        }, 4000)
        this.$emit('close')
      } catch (error) {
        console.error('error updating the user', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: `${logStartMessage} fallita`, type: AlertType.error })
      }
    },
    bindUser: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      // eslint-disable-next-line eqeqeq
      if ((this as any).employee != undefined && (this as any).employee.id === this.$route.params.id) { return }

      try {
        await this.$store.dispatch('users/bindUserRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindUserRef')
        console.error(e)
      }
    },
    unbindUser: async function () {
      try {
        await this.$store.dispatch('users/unbindUserRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindUserRef')
        console.error(e)
      }
    },
    editUserDialog: function () {
      this.editUserDialogMode = EditUserModalDialogMode.editEmployee
      this.editUserDialogIsPresented = true
    },
    hideEditUserDialog: function () {
      this.editUserDialogIsPresented = false
    },
    editUserDialogIsPresentedUserDialog: function () {
      this.editUserDialogMode = EditUserModalDialogMode.editEmployeeCredentials
      this.editUserDialogIsPresented = true
    },
    hideCredentialsEditUserDialog: function () {
      this.editUserDialogIsPresented = false
    },
    isShowingAddNewDocumentDialog: function () {
      this.isShowingAddNewDocument = true
    },
    hideAddFileDialog: function () {
      this.isShowingAddNewDocument = false
    },
    editTheUserPassword: async function () {
      const changedValues: Partial<IUserModel> = {
        id: (this as any).employee.id,
        isPasswordChangeRequested: true
      }

      try {
        const result = await this.$store.dispatch('users/updateUser', changedValues)

        this.loading = false
        if (result !== true) {
          await this.$store.dispatch('alerts/create', { message: 'aggiornamento utente fallito', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'aggiornamento utente riuscito', type: AlertType.success })
        setTimeout(async () => {
          // Then we reload the users
          await this.$store.dispatch('users/reloadUsers', { forceReload: true, filters: usersEmployeesFilters })
        }, 4000)
      } catch (error) {
        console.error('error update the user', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'aggiornamento utente fallito', type: AlertType.error })
      }
    },

    async onChatClick () {
      console.debug('User - onChatClick')
      // eslint-disable-next-line eqeqeq
      if ((this as any).employee == undefined) { return }
      console.log('Employee:', (this as any).employee)
      this.loading = true
      const chatGroup = await this.getWorkerChatOrCreateIt({ user: (this as any).employee })
      console.debug('User - onChatClick - chatGroup', { chatGroup })
      this.loading = false

      // eslint-disable-next-line eqeqeq
      if (chatGroup == undefined) { return }
      this.$router.replace({ path: `/chats/${chatGroup.id}` })
    }
  }
})
