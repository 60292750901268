
import { defineComponent } from 'vue'
import { WorkingSiteAssignedUser, MenuButton } from '@/components'
import { AssignUser } from '@/components/dialogs'

export default defineComponent({
  name: 'WorkingSiteAssignedUsersSection',

  components: {
    AssignUser, WorkingSiteAssignedUser, MenuButton
  },

  props: {
    workingSiteId: { type: String, required: true },
    workingSiteName: { type: String, required: true }
  },

  data () {
    return {
      isShowingUserAssignment: false,
      users: []
    }
  },

  async mounted () {
    this.loadUsers()
  },

  methods: {
    loadUsers: async function () {
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        facetFilters: `workSiteAssignmentIds:${this.workingSiteId}`,
        hitsPerPage: 3,
        page: 0
      })
      console.debug('searchUsers', { users: this.users })
    },
    showUserAssignmentDialog: function () {
      this.isShowingUserAssignment = true
    },
    hideUserAssignmentDialog: function () {
      // Add a delay to ensure that the Algolia Index is updated
      setTimeout(() => {
        // Then we reload the users
        this.loadUsers()
      }, 4000)
      this.isShowingUserAssignment = false
    }
  }
})
