
import { defineComponent } from 'vue'

import { PrimaryButton } from '@/components/buttons'
import { AddNewToolModalDialog } from '@/components/dialogs'
import { MenuButton } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { IToolsStoreState } from '@/interfaces'
const { mapState } = createNamespacedHelpers('tools')

export default defineComponent({
  name: 'Tools',

  components: {
    MenuButton, PrimaryButton, AddNewToolModalDialog
  },

  data () {
    return {
      isShowingAddNewToolDialog: false,
      transitionName: '',
      addTool: false,
      searchQuery: '',
      toolsFilter: ''
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.tools)
      this.searchQuery = this.$store.state.tools.toolsSearchQuery
      await this.$store.dispatch('tools/reloadTools')
    } catch (e) {
      console.error('error reloading the tools')
      console.error(e)
    }
  },

  computed: {
    ...mapState<IToolsStoreState>({
      tools: function (state: IToolsStoreState) {
        let t = state.tools

        // eslint-disable-next-line eqeqeq
        if ((this as any).toolsFilter != undefined && (this as any).toolsFilter.length > 0) {
          t = t.filter(tool => tool.name.toLowerCase().includes(((this as any).toolsFilter as string).toLowerCase()))
        }

        return t
      }
    })
  },

  methods: {
    search: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      this.toolsFilter = this.searchQuery
    },
    paginate: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('tools/paginateTools', { forceReload: false, query: this.searchQuery })
    }
  },

  watch: {
    toolsSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    }
  }
})
