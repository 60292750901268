import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_ModalDialog = _resolveComponent("ModalDialog")!

  return (_openBlock(), _createBlock(_component_ModalDialog, { type: "modal-alert" }, {
    h1: _withCtx(() => [
      _createElementVNode("span", null, "Sei sicuro di voler rimuovere l'assegnazione dello strumento " + _toDisplayString(_ctx.toolName != '' ? `"${_ctx.toolName}"` : ''), 1)
    ]),
    h3: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, "Rimuovi assegnazione")
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_PrimaryButton, {
        class: "button bg-white text-primary-900 undo",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Annulla")
        ]),
        _: 1
      }),
      _createVNode(_component_PrimaryButton, {
        class: "button bg-red-600 text-white m-auto text-center",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeAssignment()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Rimuovi")
        ]),
        _: 1
      }),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            message: "Salvataggio..."
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}