
import { defineComponent } from 'vue'
import WorkReportCard from '@/components/WorkReportCard.vue'
import { IUserModel, IWorkingSiteModel } from '@sultan/shared'
import { createNamespacedHelpers } from 'vuex'
import { IWorkReportsStoreState } from '@/interfaces'
import { WorkReportScope } from '@/enums'
const { mapState } = createNamespacedHelpers('workReports')

export default defineComponent({
  name: 'UserWorkReportsSection',

  components: {
    WorkReportCard
  },

  props: {
    scope: { type: Number as () => WorkReportScope, required: true },
    user: { type: Object as () => IUserModel | undefined, required: false },
    workingSite: { type: Object as () => IWorkingSiteModel | undefined, required: false }
  },

  data () {
    return {
      isShowingClockingInDialog: false,
      WorkReportScope
    }
  },

  async mounted () {
    this.loadWorkReports()
  },

  computed: {
    ...mapState<IWorkReportsStoreState>({
      workReports: (state: IWorkReportsStoreState) => state.workReports
    }),
    workReportsUrl: function (): string | undefined {
      switch (this.scope) {
        case WorkReportScope.workingSite: return `/working-sites/${(this.workingSite as any).id}/work-reports` as string
        case WorkReportScope.user: return `/users/${(this.user as any).id}/work-reports` as string
        default: return undefined
      }
    }
  },

  methods: {
    onManagePPE: function () { this.$router.push(`${this.$route.path}/ppes`) },
    loadWorkReports: async function () {
      await this.$store.dispatch('workReports/paginateWorkReports', {
        userId: this.user !== undefined ? (this as any).user.id : undefined,
        workingSiteId: this.workingSite !== undefined ? (this as any).workingSite.id : undefined,
        forceReload: true,
        limit: 3
      })
      console.debug('loadWorkReports', { workReports: (this as any).workReports })
    },
    reloadWorkReports: function () {
      setTimeout(() => { this.loadWorkReports() }, 4000)
    }
  }
})
