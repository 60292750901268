import { usersExpensesCollection, usersExpensesCollectionGroup } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { vuexfireMutations } from '@xquick-code/vuexfire'
import { IUserExpenseModelsStoreState } from '@/interfaces'
import { IUserExpenseModel } from '@sultan/shared'
import firebase from 'firebase'

export const UserExpensesStore: Module<IUserExpenseModelsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    userExpenses: [] as IUserExpenseModel[],
    userExpenseRawDocuments: [] as firebase.firestore.QueryDocumentSnapshot[]
  },

  mutations: {
    ...vuexfireMutations,
    setUserExpenses (state, { userExpenses, rawData, forceReload }) {
      console.log('UserExpensesStore - setUserExpenses', { userExpenses, rawData, forceReload })
      if (forceReload) {
        state.userExpenses = userExpenses
        state.userExpenseRawDocuments = rawData
      } else {
        state.userExpenses = [...state.userExpenses, ...userExpenses]
        state.userExpenseRawDocuments = [...state.userExpenseRawDocuments, ...rawData]
      }
    },
    resetUserExpense (state) {
      state.userExpenses = []
      state.userExpenseRawDocuments = []
    }
  },

  actions: {
    async paginateUserExpenses (
      context: ActionContext<IUserExpenseModelsStoreState, unknown>,
      params: { workingSiteId?: string; userId?: string; limit?: number; forceReload?: boolean }
    ): Promise<void> {
      console.log('UserExpensesStore - paginateUserExpenses', { params })

      let userExpenseRawDocuments: firebase.firestore.QueryDocumentSnapshot[] = []
      let userExpenseDocuments: IUserExpenseModel[] = []

      try {
        let query
        if (params.userId !== undefined) {
          query = usersExpensesCollection(params.userId)
        } else {
          query = usersExpensesCollectionGroup
        }

        if (params.workingSiteId !== undefined) {
          query = query.where('workingSiteId', '==', params.workingSiteId)
        }
        if (params.limit !== undefined) {
          query = query.limit(params.limit)
        }

        query = query.orderBy('createdTs', 'desc')

        if (params.forceReload !== true && context.state.userExpenseRawDocuments.length > 0) {
          query = query.startAfter(context.state.userExpenseRawDocuments[
            (context.state.userExpenseRawDocuments as any).length - 1
          ])
        }

        userExpenseRawDocuments = (await query.get()).docs
        userExpenseDocuments = [...userExpenseRawDocuments].map(doc => doc.data()) as IUserExpenseModel[]
      } catch (error) {
        console.error('UserExpensesStore - error fetching the userExpenses', { context, error })
      }

      context.commit('setUserExpenses', { userExpenses: userExpenseDocuments, rawData: userExpenseRawDocuments, forceReload: params.forceReload })
    }
  }
}
