
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { AlertType } from '@/enums'
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { IAlgoliaUserModel, IEventModel } from '@sultan/shared'
import Checkbox from '@/components/Checkbox.vue'
import { LeftArrow, SuccessIcon } from '@/components/icons'
import moment from 'moment'
import Toggle from '../Toggle.vue'
import { DatePicker } from 'v-calendar'

export default defineComponent({
  title: 'AddEditEventModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, LeftArrow, SuccessIcon, Checkbox, Toggle, DatePicker
  },

  setup () {
    return { v$: useVuelidate() }
  },

  props: {
    modelValue: {
      type: Object,
      required: false,
      default: undefined
    },
    selectedDate: {
      type: Date,
      required: false,
      default: new Date()
    }
  },

  data () {
    return {
      isShowingStep1: true,
      isShowingStep2: false,
      isShowingStep3: false,
      hideDelete: true,
      users: [],
      usersSearchFilter: '',
      id: '',
      step1: {
        title: '',
        address: '',
        lat: undefined,
        lng: undefined,
        startDate: new Date() as Date | undefined,
        endDate: new Date() as Date | undefined,
        description: ''
      },
      step2: {
        userIds: [] as string[],
        isGlobal: false
      },
      loading: false,
      disabled: false
    }
  },

  computed: {
    isEditing: function () { return (this as any).modelValue !== undefined },
    unselectedUsers: function (): IAlgoliaUserModel[] {
      return this.users.filter((u: IAlgoliaUserModel) => {
        return !(this.step2.userIds as any).includes(u.id)
      })
    },
    selectedUsers: function (): IAlgoliaUserModel[] {
      return this.users.filter((u: IAlgoliaUserModel) => {
        return (this.step2.userIds as any).includes(u.id)
      })
    },

    wsaPlaceholderIsAddress: function (): boolean {
      // eslint-disable-next-line eqeqeq
      return this.step1.address != undefined && this.step1.address != ''
    },
    workingSiteAddressPlaceholder: function (): string {
      // eslint-disable-next-line eqeqeq
      return this.step1.address != undefined && this.step1.address != '' ? this.step1.address : 'Inserisci l\'indirizzo o il nome del Porto d\'imbarco'
    }
  },

  validations () {
    return {
      step1: {
        title: { required },
        // TODO: Print the message on `maxLength` error
        description: { maxLength: maxLength(355) },
        startDate: { required },
        endDate: { required }
      }
    }
  },

  mounted () {
    console.log('AddEditEventModalDialog - mounted', { modelValue: this.modelValue, selectedDate: this.selectedDate })
    if (this.modelValue) {
      const event: IEventModel = this.modelValue as IEventModel
      this.id = event.id
      this.step1.title = event.title ?? ''
      if (event.address) {
        this.step1.address = event.address.address ?? ''
      }
      this.step1.description = event.description ?? ''
      // eslint-disable-next-line eqeqeq
      this.step1.startDate = event.startTs != undefined
        ? moment.unix(event.startTs).toDate()
        : undefined
      // eslint-disable-next-line eqeqeq
      this.step1.endDate = event.endTs != undefined
        ? moment.unix(event.endTs).toDate()
        : undefined
      this.step2.userIds = event.recipients
      this.step2.isGlobal = event.isGlobal
    } else if (this.selectedDate !== undefined) {
      const now = moment()
      this.step1.startDate = moment(this.selectedDate)
        .set('hours', now.get('hours'))
        .set('minutes', now.get('minutes'))
        .toDate()
      this.step1.endDate = moment(this.selectedDate)
        .set('hours', now.get('hours'))
        .set('minutes', now.get('minutes'))
        .add(1, 'hours')
        .toDate()
    }
  },

  methods: {
    onGmapAutocompleteAddress: function (evt: any) {
      console.log('onGmapAutocompleteAddress', { evt })
      // eslint-disable-next-line eqeqeq
      if (evt != undefined) {
        this.step1.address = evt.formatted_address !== undefined ? evt.formatted_address : ''
        if (evt.geometry !== undefined && evt.geometry.location !== undefined) {
          this.step1.lat = evt.geometry.location.lat()
          this.step1.lng = evt.geometry.location.lng()
        } else {
          this.step1.lat = undefined
          this.step1.lng = undefined
        }
      } else {
        this.step1.address = ''
        this.step1.lat = undefined
        this.step1.lng = undefined
      }
    },

    showStepTwoDialog: function () {
      // Validate the fields
      this.v$.step1.$touch()
      if (this.v$.step1.$error) {
        return
      }
      this.searchUsers()

      this.isShowingStep1 = false
      this.isShowingStep2 = true
    },
    hideStepTwoDialog: function () {
      this.isShowingStep1 = true
      this.isShowingStep2 = false
    },
    navigateToWorkingSite: function () {
      this.isShowingStep3 = true
      this.isShowingStep2 = false
      this.hideDelete = false
    },

    // Step 2
    addUser: function (userId: string): void {
      (this.step2.userIds as any).push(userId)
    },
    removeUser: function (userId: string): void {
      this.step2.userIds = this.step2.userIds.filter((u) => u !== userId)
    },
    searchUsers: async function (): Promise<void> {
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        query: this.usersSearchFilter
      })
      console.debug('searchUsers', { users: this.users })
    },

    create: async function () {
      this.loading = true

      // Validate the fields
      this.v$.step1.$touch()
      if (this.v$.step1.$error) {
        this.loading = false
        return
      }

      try {
        const result = await this.$store.dispatch(
          'events/createNewEvent',
          {
            title: this.step1.title,
            address: this.step1.address,
            addressLatitude: this.step1.lat,
            addressLongitude: this.step1.lng,
            startDate: this.step1.startDate,
            endDate: this.step1.endDate,
            description: this.step1.description,
            isGlobal: this.step2.isGlobal,
            recipients: this.step2.userIds
          }
        )

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione evento fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'creazione evento riuscita',
          type: AlertType.success
        })
        this.$store.dispatch('events/fetchEvents')
        this.$emit('close')
      } catch (error) {
        console.error('error creating the event', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'creazione evento fallita',
          type: AlertType.error
        })
      }
    },
    update: async function () {
      this.loading = true

      if (this.id === '') {
        this.loading = false
        return
      }

      try {
        const endTs = moment(this.step1.endDate as any, 'DD-MM-YYYY HH:mm')
        const updatePayload = {
          id: this.id,
          title: this.step1.title,
          address: this.step1.address,
          addressLatitude: this.step1.lat,
          addressLongitude: this.step1.lng,
          startTs: moment(this.step1.startDate as any, 'DD-MM-YYYY HH:mm').unix(),
          description: this.step1.description,
          isGlobal: this.step2.isGlobal,
          recipients: this.step2.userIds,
          endTs: endTs.isValid() ? endTs.unix() : null
        }

        const result = await this.$store.dispatch(
          'events/updateEvent', updatePayload
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'aggiornamento evento fallito', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'aggiornamento evento riuscito', type: AlertType.success })
        await this.$store.dispatch('events/fetchEvents')
        this.$emit('close')
      } catch (error) {
        console.error('error updating the event', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'aggiornamento evento fallito', type: AlertType.error })
      }
    }
  }
})
