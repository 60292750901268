
import { defineComponent } from 'vue'
import { Loader } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { AlertType } from '@/enums'
const { mapActions } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSitePpeCard',

  components: {
    Loader
  },

  data () {
    return {
      deleteRelatedDocument: false,
      loading: false
    }
  },

  props: {
    modelValue: {
      type: String,
      required: true
    }
  },

  computed: {
    id: function (): string {
      return (this.$route as any).params.workingSiteId
    }
  },

  methods: {
    ...mapActions(['deleteWorkingSitePpe']),
    onPpeDelete: async function () {
      console.log('onPpeDelete')
      this.loading = true

      try {
        const result = await this.deleteWorkingSitePpe({ workingSiteId: this.id, ppe: this.modelValue })

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'eliminazione dpi fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'eliminazione dpi riuscita', type: AlertType.success })
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'eliminazione dpi fallita', type: AlertType.error })
      }
    }
  }
})
