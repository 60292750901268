
import { defineComponent } from 'vue'
import ModalDialog from '@/components/dialogs/ModalDialog.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import UploadForm from '@/components/UploadForm.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { v4 as UUID } from 'uuid'
import { IToolModel } from '@sultan/shared'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'AddNewToolModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, UploadForm
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data: function () {
    return {
      title: '',
      primaryFieldName: '',
      secondaryFieldName: '',
      loading: false
    }
  },

  validations () {
    return {
      title: { required },
      primaryFieldName: { required },
      secondaryFieldName: { required }
    }
  },

  props: {
    workingSiteId: {
      type: String,
      required: true
    }
  },

  methods: {
    onConfirm: async function (): Promise<void> {
      console.log('on confirm')
      this.v$.$touch()

      if (this.v$.error) {
        this.loading = false
        return
      }

      this.loading = true

      const doc: IToolModel = {
        id: UUID(),
        name: this.title,
        primaryFieldName: this.primaryFieldName,
        secondaryFieldName: this.secondaryFieldName,
        createdTs: (new Date()).valueOf() / 1000
      }

      try {
        const result = await this.$store.dispatch('tools/addTool', doc)

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'caricamento documento fallito', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'caricamento documento riuscito', type: AlertType.success })
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('tools/reloadTools')
        }, 4000)

        this.$emit('close')
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'caricamento documento fallito', type: AlertType.error })
      }
    },
    onClose: function () {
      console.log('on close')
      this.$emit('close')
    }
  }
})
