import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-211ee8c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mx-auto" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  class: "justify-between items-center pl-8 pr-8 cursor-pointer select-none text-right",
  for: "chckMore"
}
const _hoisted_4 = {
  key: 0,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkingSiteAssignedUser = _resolveComponent("WorkingSiteAssignedUser")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          class: "primary w-full absolute z-10 cursor-pointer opacity-0 h-16 left-0 top-1 bg-white",
          id: "chckMore",
          type: "checkbox",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMoreUsers()))
        }),
        _createElementVNode("header", _hoisted_3, [
          (_ctx.hideButton)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_4, "Vedi tutti i dipendenti"))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.showMore)
      ? (_openBlock(), _createBlock(_component_WorkingSiteAssignedUser, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createBlock(_component_WorkingSiteAssignedUser, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}