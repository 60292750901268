
import { defineComponent } from 'vue'
import { SecondaryButton } from '@/components'
import Vue3MarkdownIt from 'vue3-markdown-it'

export default defineComponent({
  name: 'SettingInfo',

  components: {
    SecondaryButton, Vue3MarkdownIt
  },

  data () {
    return {
      version: '2.2.0',
      changelog: '',
      options: {
        breaks: true,
        html: true,
        linkify: true,
        typographer: true
      }
    }
  },

  mounted () {
    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      if (xhr.response !== undefined && typeof xhr.response === 'string') {
        this.changelog = xhr.response
      }
    })
    xhr.open('GET', '/CHANGELOG.md')
    xhr.send()
  }
})
