
import { defineComponent } from 'vue'
import ModalDialog from '@/components/dialogs/ModalDialog.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Loader from '@/components/Loader.vue'
import UploadForm from '@/components/UploadForm.vue'

export default defineComponent({
  name: 'UploadDocumentModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, UploadForm
  },

  data () {
    return {
      name: '',
      surname: '',
      phone: '',
      email: '',
      password: '',
      loading: false
    }
  },

  methods: {
    create: async function () {
      this.loading = true
      try {
        await this.$store.dispatch(
          'users/createNewWorker',
          {
            name: this.name,
            surname: this.surname,
            phone: this.phone,
            email: this.email,
            password: this.password
          }
        )
      } catch (error) {
        console.error('error creating the user', error)
        alert('Errore nel salvataggio')
      }
      this.$emit('close')
      this.loading = false
    }
  }
})
