
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import {
  ViewMoreUsers, RelatedDocumentsSection,
  Loader, ToolItemCard
} from '@/components'
import { PrimaryButton, SecondaryButton } from '@/components/buttons'
import { ClientIconSmall, DocumentIconSmall } from '@/components/icons'
import { AddNewToolItemModalDialog, DeleteTool } from '@/components/dialogs'
import moment from 'moment'
import { IToolsStoreState } from '@/interfaces'
import { undeletableTools } from '@/utilities'

const { mapState, mapActions } = createNamespacedHelpers('tools')

export default defineComponent({
  name: 'ToolItem',

  components: {
    ViewMoreUsers, PrimaryButton, SecondaryButton, RelatedDocumentsSection, ClientIconSmall, DocumentIconSmall, AddNewToolItemModalDialog, Loader, ToolItemCard, DeleteTool
  },

  created: function () {
    (this as any).moment = moment
  },

  data () {
    return {
      editDocument: false,
      isShowingAddNewToolItemDialog: false,
      isShowingDeleteToolDialog: false,
      approvedDocuments: undefined as number | undefined,
      unapprovedDocuments: undefined as number | undefined,
      loading: false,
      searchQuery: '',
      isShowingArchiveToolItem: false
    }
  },

  computed: {
    ...mapState<IToolsStoreState>({
      tool: function (state: IToolsStoreState) { return state.tools.find(t => t.id === (this as any).$route.params.id) },
      toolItems: (state: IToolsStoreState) => state.toolItems
    }),
    id: function () {
      return this.$route.params.id
    },
    isProtectedTool: function () { return typeof this.$route.params.id === 'string' ? undeletableTools.includes(this.$route.params.id as any) : false }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.search()
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.search()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions(['paginateToolItems']),
    search: async function (): Promise<void> {
      console.log('search', { searchQuery: this.searchQuery })
      await this.paginateToolItems({ forceReload: true, query: this.searchQuery, facetFilters: `toolId:${this.id}` })
    },
    onToolItemAssignmentUpdate: async function (): Promise<void> {
      setTimeout(() => { this.search() }, 8000)
    }
  }
})
