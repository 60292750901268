/* eslint-disable eqeqeq */
import { IStoreState, ITimeReportEntry } from '@/interfaces'
import { IClockingInModel, IUserModel, IWorkingSiteModel } from '@sultan/shared'
import { Store } from 'vuex'
import moment from 'moment'
import isSameDay from 'date-fns/isSameDay'
import { findLastIndex } from './find-last-index'
import Holidays from 'date-holidays'

export const userTimeReportComputer = async (params: { store: Store<IStoreState>, userId: string, startDate: Date, endDate: Date }): Promise<ITimeReportEntry[]> => {
  console.debug('userTimeReportComputer', { params })
  // 0. We prepare the variables
  const startPeriod = moment(params.startDate).startOf('day')
  const endPeriod = moment(params.endDate).endOf('day')

  // 1. We collect the user clocking ins
  let clockingIns: IClockingInModel[] = await params.store.dispatch(
    'clockingIns/instantGetUserClockingInsInPeriod',
    {
      userId: params.userId,
      from: startPeriod.toDate(),
      to: endPeriod.toDate()
    }
  )
  clockingIns = clockingIns.sort((a, b) => a.createdTs - b.createdTs)

  console.debug('userTimeReportComputer', { clockingIns })

  // 2. We get the inquired user document
  const user = await params.store.dispatch('users/getUser', params.userId)

  const reports: ITimeReportEntry[] = await clockingIns.reduce(
    async (promisedAcc, clockIn): Promise<ITimeReportEntry[]> => {
      const acc = await promisedAcc
      const clkInDate = moment.unix(clockIn.createdTs).toDate()

      const idx = findLastIndex(acc, accClkIn => isSameDay(accClkIn.day, clkInDate))

      let createdByUser: IUserModel | undefined
      if (clockIn.createdByUserId == undefined || clockIn.createdByUserId == params.userId) {
        createdByUser = user
      } else {
        createdByUser = await params.store.dispatch('users/getUser', clockIn.createdByUserId)
      }

      const newEntry: ITimeReportEntry = {
        day: clkInDate,
        in: clockIn.isClockingOut || clockIn.timing != undefined ? undefined : clkInDate,
        inUser: clockIn.isClockingOut || clockIn.timing != undefined ? undefined : createdByUser,
        out: clockIn.isClockingOut && clockIn.timing == undefined ? clkInDate : undefined,
        outUser: clockIn.isClockingOut && clockIn.timing == undefined ? createdByUser : undefined,
        diffMinutes: 0,
        diffMinutesRounded: 0,
        foodStamps: 0,
        foodStampsRounded: 0,
        overtime25: 0,
        overtime25Rounded: 0,
        overtime50: 0,
        overtime50Rounded: 0,
        travellingSeconds: clockIn.timing != undefined ? clockIn.timing : 0,
        travellingSecondsRounded: 0,
        workingHours: 0,
        workingHoursRounded: 0,
        workingSiteId: clockIn.workingSiteId != undefined ? clockIn.workingSiteId : undefined
      }

      // Round the current in and out values
      newEntry.inRounded = newEntry.in instanceof Date ? moment(newEntry.in).round(15, 'minutes').toDate() : newEntry.in
      newEntry.outRounded = newEntry.out instanceof Date ? moment(newEntry.out).round(15, 'minutes').toDate() : newEntry.out
      if (newEntry.travellingSeconds) {
        const start = moment().startOf('day')
        const startAndDiff = moment(start).add(newEntry.travellingSeconds, 'seconds').round(15, 'minutes')
        newEntry.travellingSecondsRounded = startAndDiff.diff(start, 'seconds')
        console.debug(`newEntry.travellingSeconds(${newEntry.travellingSeconds}) --> newEntry.travellingSecondsRounded(${newEntry.travellingSecondsRounded})  ===>  start(${start.format()})  --  startAndDiff(${startAndDiff.format()})  --  newEntry.travellingSecondsRounded(${newEntry.travellingSecondsRounded})`)
      }

      if (idx === -1) {
        acc.push(newEntry)
      } else {
        if (clockIn.timing != undefined) {
          // Travelling Clocking in case
          acc[idx].travellingSeconds += newEntry.travellingSeconds
          acc[idx].travellingSecondsRounded += newEntry.travellingSecondsRounded
        } else if (clockIn.isClockingOut) {
          // Clocking out case
          if (acc[idx].out != undefined) {
            // Another clocking out for same day
            acc.push(newEntry)
          } else {
            // Add clocking out to the same day
            acc[idx].outUser = createdByUser
            acc[idx].out = newEntry.out
            acc[idx].outRounded = newEntry.outRounded
          }
        } else {
          // Clocking in case
          if (acc[idx].in != undefined) {
            // Another clocking in for same day
            acc.push(newEntry)
          } else {
            // Add clocking in to the same day
            acc[idx].inUser = createdByUser
            acc[idx].in = newEntry.in
            acc[idx].inRounded = newEntry.inRounded
          }
        }
      }

      return acc
    },
    Promise.resolve([] as ITimeReportEntry[])
  )

  const workingSites: IWorkingSiteModel[] = []

  const hd = new Holidays()
  hd.init('IT')

  // 3. We compute the timings and add the working site name

  let lastDay: Date | undefined
  let regularHours = 0
  let regularHoursRounded = 0
  let overtime25 = 0
  let overtime25Rounded = 0
  let overtime50 = 0
  let overtime50Rounded = 0
  let foodStampAlreadyGiven = false
  let foodStampAlreadyGivenRounded = false

  for (const entry of reports) {
    // 3.1 Working site name
    let ws: IWorkingSiteModel | undefined = workingSites.find(ws => ws.id === entry.workingSiteId)
    if (ws != undefined) {
      entry.workingSiteName = ws.name
    } else {
      ws = await params.store.dispatch(
        'workingSites/getWorkingSite', entry.workingSiteId
      )

      if (ws != undefined) {
        entry.workingSiteName = ws.name
        workingSites.push(ws)
      }
    }

    // 3.2 Diff hours
    if (entry.in != undefined && entry.out != undefined) {
      const duration = moment.duration(moment(entry.out).diff(moment(entry.in)))
      entry.diffMinutes = (duration.asHours()) * 60

      // 3.2.2 Diff hours Rounded
      const durationRounded = moment.duration(moment(entry.outRounded).diff(moment(entry.inRounded)))
      entry.diffMinutesRounded = (durationRounded.asHours()) * 60
    }

    // 3.3 Working hours
    if (entry.in != undefined && entry.out != undefined) {
      // We are able to compute the working hours

      const weekDay = moment(entry.day).weekday()
      const duration = moment.duration(moment(entry.out).diff(moment(entry.in)))
      const durationRounded = moment.duration(moment(entry.outRounded).diff(moment(entry.inRounded)))
      const hours = duration.asHours()
      const hoursRounded = durationRounded.asHours()

      if (lastDay == undefined || !isSameDay(lastDay, entry.day)) {
        lastDay = entry.day
        regularHours = 0
        regularHoursRounded = 0
        overtime25 = 0
        overtime25Rounded = 0
        foodStampAlreadyGiven = false
        foodStampAlreadyGivenRounded = false
      }
      /* Test examples
      Data:

      prev 0    2   2   8   0   0
      curr 4    8   10  5   5   3
      e25  0    0   0   0   2   1

      Result expected:

      reg  4    6   6   0   0   0
      e25  0    2   2   2   0   1
      e50  0    0       3   5   2
      */

      if (hd.isHoliday(entry.day) || weekDay === 7) {
        entry.overtime50 = hours
        entry.overtime50Rounded = hoursRounded
      } else if (weekDay === 6) {
        const arrival = 2 - overtime25
        const arrivalRounded = 2 - overtime25Rounded

        if (arrival < hours) {
          entry.overtime25 = arrival
          overtime25 = 2
          if ((hours - arrival) > 0) {
            entry.overtime50 = hours - arrival
          }
        } else {
          entry.overtime25 = hours
          overtime25 = overtime25 + hours
        }

        // For rounded values
        if (arrivalRounded < hoursRounded) {
          entry.overtime25Rounded = arrivalRounded
          overtime25Rounded = 2
          if ((hoursRounded - arrivalRounded) > 0) {
            entry.overtime50Rounded = hoursRounded - arrivalRounded
          }
        } else {
          entry.overtime25Rounded = hoursRounded
          overtime25Rounded = overtime25Rounded + hoursRounded
        }
      } else {
        if (overtime50 > 0 || overtime25 >= 2) {
          entry.overtime50 = hours
          overtime50 += hours
        } else if (overtime25 > 0 || regularHours >= 8) {
          const arrival = 2 - overtime25

          if (arrival < hours) {
            entry.overtime25 = arrival
            overtime25 = 2
            if ((hours - arrival) > 0) {
              entry.overtime50 = hours - arrival
              overtime50 += entry.overtime50
            }
          } else {
            entry.overtime25 = hours
            overtime25 = overtime25 + hours
          }
        } else {
          if (regularHours + hours > 8) {
            const arrival = 8 - regularHours

            if (arrival < hours) {
              entry.workingHours = arrival
              regularHours = 8
              if ((hours - arrival) > 0) {
                entry.overtime25 = hours - arrival

                if (entry.overtime25 > 2) {
                  entry.overtime50 = entry.overtime25 - 2
                  entry.overtime25 = 2

                  overtime25 = 2
                  overtime50 = entry.overtime50
                }
              }
            } else {
              entry.workingHours = hours
              regularHours += hours
            }
          } else {
            regularHours += hours
            entry.workingHours = hours
          }
        }

        // For rounded values
        if (overtime50Rounded > 0 || overtime25Rounded >= 2) {
          entry.overtime50Rounded = hoursRounded
          overtime50Rounded += hoursRounded
        } else if (overtime25Rounded > 0 || regularHoursRounded >= 8) {
          const arrivalRounded = 2 - overtime25Rounded

          if (arrivalRounded < hoursRounded) {
            entry.overtime25Rounded = arrivalRounded
            overtime25Rounded = 2
            if ((hoursRounded - arrivalRounded) > 0) {
              entry.overtime50Rounded = hoursRounded - arrivalRounded
              overtime50Rounded += entry.overtime50Rounded
            }
          } else {
            entry.overtime25Rounded = hoursRounded
            overtime25Rounded = overtime25Rounded + hoursRounded
          }
        } else {
          if (regularHoursRounded + hoursRounded > 8) {
            const arrivalRounded = 8 - regularHoursRounded

            if (arrivalRounded < hoursRounded) {
              entry.workingHoursRounded = arrivalRounded
              regularHoursRounded = 8
              if ((hoursRounded - arrivalRounded) > 0) {
                entry.overtime25Rounded = hoursRounded - arrivalRounded

                if (entry.overtime25Rounded > 2) {
                  entry.overtime50Rounded = entry.overtime25Rounded - 2
                  entry.overtime25Rounded = 2

                  overtime25Rounded = 2
                  overtime50Rounded = entry.overtime50Rounded
                }
              }
            } else {
              entry.workingHoursRounded = hoursRounded
              regularHoursRounded += hoursRounded
            }
          } else {
            regularHoursRounded += hoursRounded
            entry.workingHoursRounded = hoursRounded
          }
        }
      }

      // Foor stamps
      if (!foodStampAlreadyGiven && (regularHours + overtime25 + overtime50) > 6) {
        entry.foodStamps = 1
        foodStampAlreadyGiven = true
      } else {
        entry.foodStamps = 0
      }

      // Foor stamps rounded
      if (!foodStampAlreadyGivenRounded && (regularHoursRounded + overtime25Rounded + overtime50Rounded) > 6) {
        entry.foodStampsRounded = 1
        foodStampAlreadyGivenRounded = true
      } else {
        entry.foodStampsRounded = 0
      }

      lastDay = entry.day
    }
  }

  console.debug('userTimeReportComputer', { reports })

  return reports
}
