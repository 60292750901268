import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d862354"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-row flex items-start p-4 bg-white mb-4 rounded-lg group hover:bg-primary-200" }
const _hoisted_2 = { class: "left flex items-start justify-between" }
const _hoisted_3 = { class: "chat-content pl-2" }
const _hoisted_4 = { class: "title text-left group-hover:text-white" }
const _hoisted_5 = { class: "chat-title font-bold text-xl w-72 truncate" }
const _hoisted_6 = {
  key: 0,
  class: "last-message text-left text-xs truncate w-72 text-primary-300 group-hover:text-white"
}
const _hoisted_7 = {
  key: 0,
  class: "last-message-text"
}
const _hoisted_8 = {
  key: 1,
  class: "last-message-document flex items-center text-xs"
}
const _hoisted_9 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_10 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_11 = {
  key: 2,
  class: "last-message-photo flex items-center text-xs"
}
const _hoisted_12 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_13 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_14 = {
  key: 3,
  class: "last-message-audio flex items-center text-xs"
}
const _hoisted_15 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_16 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_17 = {
  key: 4,
  class: "last-message-video flex items-center text-xs"
}
const _hoisted_18 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_19 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_20 = {
  key: 5,
  class: "last-message-link flex items-center text-xs"
}
const _hoisted_21 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_22 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_23 = {
  key: 6,
  class: "last-message-link flex items-center text-xs"
}
const _hoisted_24 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_25 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_26 = {
  key: 7,
  class: "last-message-link flex items-center text-xs"
}
const _hoisted_27 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_28 = {
  key: 1,
  class: "pl-1"
}
const _hoisted_29 = {
  key: 1,
  class: "no-messages-yet text-left text-xs truncate w-72 group-hover:text-white"
}
const _hoisted_30 = {
  key: 0,
  class: "right w-full"
}
const _hoisted_31 = { class: "last-message text-xs text-right text-primary-300 group-hover:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "chat-card w-full",
    to: `/chats/${_ctx.modelValue.id}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.chatName), 1)
            ]),
            (_ctx.modelValue.lastMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.modelValue.lastMessage.type == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'file']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_10, "File"))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'image']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_13, "Immagine"))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'microphone-lines']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_16, "Audio"))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 4)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'video']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_19, "Video"))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 5)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'link']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_22, "Link"))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 6)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'user']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_25, "Contatto"))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.lastMessage.type == 7)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "h-3 w-3",
                          icon: ['fas', 'location-dot']
                        }),
                        (_ctx.modelValue.lastMessage.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(_ctx.modelValue.lastMessage.text), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_28, "Posizione"))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_29, "Ancora nessun messaggio in questa chat."))
          ])
        ]),
        (_ctx.modelValue.lastMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.moment(_ctx.modelValue.lastMessage.createdTs).format('DD/MM/YYYY HH:mm')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}