
import { defineComponent } from 'vue'
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import { CalendarView } from 'vue-simple-calendar'
import 'vue-simple-calendar/dist/style.css'
import { IEventsStoreState } from '@/interfaces/events-store-state.interface'
import CustomCalendarViewHeader from '@/components/CustomCalendarViewHeader.vue'
import { AddEditEventModalDialog } from '@/components/dialogs'

const { mapState } = createNamespacedHelpers('events')

export default defineComponent({
  name: 'CalendarEvents',

  components: {
    CalendarView, CustomCalendarViewHeader, AddEditEventModalDialog
  },

  computed: {
    ...mapState<IEventsStoreState>({
      events: (state: IEventsStoreState) => state.events.map(event => {
        return {
          id: event.id,
          startDate: moment.unix(event.startTs).toDate(),
          endDate: event.endTs ? moment.unix(event.endTs).toDate() : moment.unix(event.startTs).toDate(),
          classes: ['standard'],
          title: `<h4 class="event-time">${moment.unix(event.startTs).format('HH:mm')} ${
            event.endTs ? `- ${moment.unix(event.endTs).format('HH:mm')}`
            : ''
          }</h4><h2 class="event-title-card">${event.title}</h2>`
        }
      })
    })
  },

  watch: {
    showDate: function (value) {
      // fetch current month events
      console.log('show date has changed')
      this.queryEvents()
    },
    displayPeriodUom: function () {
      console.log('display period uom has changed')
      this.queryEvents()
    }
  },

  created: function () {
    (this as any).moment = moment
  },

  mounted () {
    this.queryEvents()
  },

  data () {
    const month = new Date().getMonth()
    const year = new Date().getFullYear()

    console.log('---------------------------')
    console.log(
      moment()
        .set('date', 5)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .toDate()
    )
    console.log(new Date(year, month, 5))
    console.log('---------------------------')

    return {
      showDate: new Date(),
      addEventDialogDate: new Date(),
      displayPeriodUom: 'month',
      addEventDialogIsPresented: false
    }
  },

  methods: {
    queryEvents: async function () {
      console.debug('queryEvents', {
        periodUom: this.displayPeriodUom,
        selectedDate: this.showDate
      })
      try {
        await this.$store.dispatch(
          'events/queryEvents',
          {
            periodUom: this.displayPeriodUom,
            selectedDate: this.showDate
          }
        )
      } catch (e) {
        console.error('error dispatching the firestore mutation bindEventRef')
        console.error(e)
      }
    },

    hideNewEventDialog: function () {
      this.addEventDialogIsPresented = false
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowDate (d: any) {
      this.showDate = d
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onItemClick (event: any) {
      console.debug(`onItemClick - ${event.id}`)
      this.$router.push({ path: `/calendar/${event.id}` })
    },
    onDayClick (day: Date) {
      console.debug(`onDayClick - ${day.valueOf()}`)
      console.debug(moment(day).format('L'))
      this.addEventDialogIsPresented = true
      this.addEventDialogDate = day

      // TODO: Open the dialog
    },
    onDateSelection (dates: any) {
      console.log('onDateSelection', { dates })
    }
  }
})
