import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e022560"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex overflow-hidden",
  id: "map"
}
const _hoisted_2 = {
  key: 0,
  class: "marker text-left"
}
const _hoisted_3 = {
  key: 0,
  class: "color-primary-900"
}
const _hoisted_4 = { class: "flex justify-center" }
const _hoisted_5 = {
  key: 1,
  class: "cruise-mapper"
}
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GMapInfoWindow = _resolveComponent("GMapInfoWindow")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GMapMap, {
      class: "h-full flex-grow h-full w-full",
      center: _ctx.center,
      zoom: 7,
      "map-type-id": "roadmap",
      style: {"width":"500px","height":"300px"},
      options: _ctx.mapOptions
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (m, index) => {
          return (_openBlock(), _createBlock(_component_GMapMarker, {
            key: index,
            position: m.position,
            clickable: true,
            draggable: false,
            onClick: ($event: any) => (_ctx.onMarkerClick(m)),
            icon: { url: '/marker-icon.png', scaledSize: {width: 30, height: 46}, labelOrigin: {x: 15, y: 0} }
          }, null, 8, ["position", "onClick", "icon"]))
        }), 128)),
        _createVNode(_component_GMapInfoWindow, {
          closeclick: true,
          onCloseclick: _ctx.onMarkerWindowClose,
          opened: _ctx.markerWindow,
          position: _ctx.infowindow,
          options: {pixelOffset: {width: 0,height: -55}}
        }, {
          default: _withCtx(() => [
            (_ctx.selectedMarker)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.selectedMarker.workingSite.name), 1),
                  (_ctx.selectedMarker.workingSite.address && _ctx.selectedMarker.workingSite.address.address)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.selectedMarker.workingSite.address.address), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.selectedMarker.workingSite.id)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "font-bold block working-site-link text-primary-400 pt-1 pr-4 pl-4 pb-1 mt-2 border-primary-400 text-center border-solid border-2 rounded-lg mr-2",
                          to: `/working-sites/${_ctx.selectedMarker.workingSite.id}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Apri Cantiere")
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedMarker.workingSite.link)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("a", {
                            class: "font-bold block working-site-link text-primary-400 pt-1 pr-4 pl-4 pb-1 mt-2 border-primary-400 text-center border-solid border-2 rounded-lg pointer",
                            href: _ctx.selectedMarker.workingSite.link,
                            target: "_blank"
                          }, "Cruise Mapper", 8, _hoisted_6)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onCloseclick", "opened", "position"])
      ]),
      _: 1
    }, 8, ["center", "options"])
  ]))
}