
import { defineComponent } from 'vue'
import { ClockingInCard } from '@/components'
import { AddEditClockingInDialog } from '@/components/dialogs'
import { ClockingInScope } from '@/enums'
import { IUserModel, IWorkingSiteModel } from '@sultan/shared'

export default defineComponent({
  name: 'WorkingSiteClockingInsSection',

  components: {
    AddEditClockingInDialog, ClockingInCard
  },

  props: {
    id: { type: String, required: true },
    scope: {
      type: Number as () => ClockingInScope,
      required: true
    },
    user: { type: Object as () => IUserModel | undefined, required: false },
    workingSite: { type: Object as () => IWorkingSiteModel | undefined, required: false }
  },

  data () {
    return {
      isShowingClockingInDialog: false,
      clockingIns: []
    }
  },

  async mounted () {
    this.loadClockingIns()
  },

  computed: {
    facetFilters: function (): string[] {
      switch (this.scope) {
        case ClockingInScope.workingSite: return [`workingSiteId:${this.id}`]
        case ClockingInScope.user: return [`userId:${this.id}`]
        default: return []
      }
    },
    clockingInsUrl: function (): string | undefined {
      switch (this.scope) {
        case ClockingInScope.workingSite: return `/working-sites/${this.id}/clocking-ins` as string
        case ClockingInScope.user: return `/users/${this.id}/clocking-ins` as string
        default: return undefined
      }
    }
  },

  methods: {
    onManagePPE: function () { this.$router.push(`${this.$route.path}/ppes`) },
    loadClockingIns: async function () {
      this.clockingIns = await this.$store.dispatch('clockingIns/instantSearchClockingIns', {
        facetFilters: this.facetFilters,
        hitsPerPage: 3,
        page: 0
      })
      console.debug('searchUsers', { clockingIns: this.clockingIns })
    },
    reloadClockingIns: function () {
      setTimeout(() => { this.loadClockingIns() }, 4000)
    }
  }
})
