
import { defineComponent } from 'vue'
import { PrimaryButton, SecondaryButton } from '@/components/buttons'
import { AddEditDocumentModalDialog, DeleteDocumentDialog } from '@/components/dialogs'
import { RelatedDocumentsSection, Loader, DocumentCard } from '@/components'
import moment from 'moment'
import { AlertType, DocumentScope } from '@/enums'
import { IDocumentModel } from '@sultan/shared'

export default defineComponent({
  name: 'DocumentDetails',

  components: {
    Loader, PrimaryButton, SecondaryButton, AddEditDocumentModalDialog, DeleteDocumentDialog, RelatedDocumentsSection, DocumentCard
  },

  data () {
    return {
      isShowingDeleteDialog: false,
      isShowingEditDialog: false,
      loading: false,
      doc: undefined as IDocumentModel | undefined,
      DocumentScope
    }
  },

  props: {
    scope: {
      type: Number as () => DocumentScope,
      required: false,
      default: DocumentScope.companyDocument
    }
  },

  created: function () {
    (this as any).moment = moment
  },

  async mounted () {
    console.log('DocumentDetails - mounted', { scope: this.scope })
    console.log(`got id: ${this.$route.params.id}`)
    await this.fetchDocument()
  },

  computed: {
    documentId: function (): string | undefined { return this.$route.params.id as string }
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.fetchDocument()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onToggleArchive: async function (): Promise<void> {
      console.log('DocumentDetails - onToggleArchive', { doc: (this as any).doc })
      this.loading = true

      // eslint-disable-next-line eqeqeq
      if ((this as any).doc == undefined || (this as any).doc.id == undefined) {
        // New Communication case
        console.log('AddEditCommunicationDialog - error')
        this.loading = false
        return
      }

      const isArchived = (this as any).doc.isArchived

      try {
        const result = await this.$store.dispatch(
          'docs/updateCommunication',
          {
            id: (this as any).doc.id,
            isArchived: !isArchived
          }
        )

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', { message: isArchived ? 'ripristino comunicazione fallita' : 'archiviazione comunicazione fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: isArchived ? 'ripristino comunicazione fallita' : 'archiviazione comunicazione riuscita', type: AlertType.success })

        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('docs/fetchCommunications')
        }, 4000)

        this.$emit('close')
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: isArchived ? 'ripristino comunicazione fallita' : 'archiviazione comunicazione fallita', type: AlertType.error })
      }
    },
    fetchDocument: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        this.doc = await this.$store.dispatch('documents/getDocument', { scope: this.scope, documentId: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation fetchDocument')
        console.error(e)
      }
    }
  }

})
