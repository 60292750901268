
/* eslint-disable object-property-newline */
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import {
  WorkingSiteClockingInsSection, ViewMoreUsers, RelatedDocumentsSection,
  WorkingSiteAssignedUsersSection, Loader, UserWorkReportsSection, UserExpensesSection
} from '@/components'
import { PrimaryButton, SecondaryButton } from '@/components/buttons'
import { ClientIconSmall, DocumentIconSmall, WorldIcon } from '@/components/icons'
import { ToggleEntityArchive, AddEditWorkingSiteModalDialog } from '@/components/dialogs'
import { IWorkingSitesStoreState } from '@/interfaces'
import { DocumentScope, EntityArchiveScope, ClockingInScope, WorkReportScope, UserExpenseScope } from '@/enums'
import moment from 'moment'

const { mapState } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSite',

  components: {
    WorkingSiteClockingInsSection, ViewMoreUsers, WorldIcon, PrimaryButton,
    SecondaryButton, RelatedDocumentsSection, AddEditWorkingSiteModalDialog,
    ClientIconSmall, DocumentIconSmall, WorkingSiteAssignedUsersSection,
    Loader, ToggleEntityArchive, UserWorkReportsSection, UserExpensesSection
  },

  created: function () {
    (this as any).moment = moment
  },

  data () {
    return {
      editDocument: false,
      documentCounter: undefined as number | undefined,
      loading: false,
      isShowingToggleEntityArchive: false,
      DocumentScope,
      EntityArchiveScope,
      ClockingInScope,
      WorkReportScope,
      UserExpenseScope
    }
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      workingSite: (state: IWorkingSitesStoreState) => state.workingSite,
      tags: function (state: IWorkingSitesStoreState) {
        // eslint-disable-next-line eqeqeq
        return state.workingSite == undefined ? {} : state.workingSite.tags
      }
    }),
    id: function () {
      return this.$route.params.id
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.id}`)
    await this.bindWorkingSite()
    await this.fetchDocumentCouters()
  },

  async beforeUnmount () {
    await this.unbindWorkingSite()
  },

  watch: {
    '$route.params.id': {
      handler: async function (id) {
        console.log(id)
        await this.unbindWorkingSite()
        await this.bindWorkingSite()
        await this.fetchDocumentCouters()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onManageTime: function () { this.$router.push(`${this.$route.path}/timings`) },
    onDocuments: function () { this.$router.push(`${this.$route.path}/documents`) },
    onManagePPE: function () { this.$router.push(`${this.$route.path}/ppes`) },
    showEditDocumentDialog: function () {
      this.editDocument = true
    },
    hideEditDocumentDialog: function () {
      this.editDocument = false
    },

    bindWorkingSite: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.id == undefined || typeof this.$route.params.id !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('workingSites/bindWorkingSiteRef', { id: this.$route.params.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSiteRef')
        console.error(e)
      }
    },
    unbindWorkingSite: async function () {
      try {
        await this.$store.dispatch('workingSites/unbindWorkingSiteRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },

    fetchDocumentCouters: async function () {
      try {
        this.documentCounter = await this.$store.dispatch('documents/listDocuments', { scope: DocumentScope.workingSiteDocument, workingSiteId: this.id })
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },

    onGenerateQrCode: async function () {
      this.loading = true
      try {
        const result = await this.$store.dispatch('workingSites/createQrCode')
        this.loading = false

        if (result.image !== undefined) {
          var image = new Image()
          image.src = 'data:image/jpg;base64,' + result.image

          var w = window.open('')
          if (w !== null) {
            w.document.write(image.outerHTML)
          }
        }
      } catch (e) {
        this.loading = false
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },

    onChatClick () {
      console.debug('WorkingSite - onChatClick')
      this.$router.replace({ path: `/chats/${this.id}` })
    }
  }
})
