
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { IAlgoliaUserModel, IAssignmentModel, IToolItemModel } from '@sultan/shared'
import { AlertType } from '@/enums'
import Checkbox from '@/components/Checkbox.vue'

export default defineComponent({
  name: 'AssignUser',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, Checkbox
  },

  props: {
    userId: { type: String, required: true },
    existingAssignments: { type: Array as () => IAssignmentModel[], required: true }
  },

  data () {
    return {
      toolItems: [],
      toolItemsSearchFilter: '',
      toolItemIds: [],
      loading: false
    }
  },

  async mounted () {
    this.onSearch()
  },

  computed: {
    unselectedToolItems: function (): IAlgoliaUserModel[] {
      return this.toolItems.filter((u: IAlgoliaUserModel) => {
        return !(this.toolItemIds as any).includes(u.id)
      })
    },
    selectedToolItems: function (): IAlgoliaUserModel[] {
      return this.toolItems.filter((u: IAlgoliaUserModel) => {
        return (this.toolItemIds as any).includes(u.id)
      })
    }
  },

  methods: {
    // Step 2
    addUser: function (toolItemId: string): void {
      (this.toolItemIds as any).push(toolItemId)
    },
    removeUser: function (toolItemId: string): void {
      this.toolItemIds = this.toolItemIds.filter((u) => u !== toolItemId)
    },
    onSearch: async function (): Promise<void> {
      this.toolItems = (
        await this.$store.dispatch('tools/instantSearchToolItems', {
          query: this.toolItemsSearchFilter
        })
      )
      console.log(this.toolItems)
      console.log(this.existingAssignments)
      if (typeof this.toolItems === 'object') {
        this.toolItems = this.toolItems
          .filter((ti: IToolItemModel) => this.existingAssignments.findIndex(ea => ea.id === ti.id) === -1)
        console.log(typeof this.toolItems)
      }
      console.debug('onSearch', { toolItems: this.toolItems })
    },

    onAdd: async function (): Promise<void> {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'tools/addToolAssignments',
          {
            userId: this.userId,
            toolItemIds: this.toolItemIds
          }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'assegnazione strumenti fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'assegnazione strumenti riuscita',
          type: AlertType.success
        })
        this.$emit('close')
      } catch (error) {
        console.error('error creating the user tool item assignments', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'assegnazione strumenti fallita',
          type: AlertType.error
        })
      }
    }
  }
})
