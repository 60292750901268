import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a4cfe52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-site-document-row mb-2 grid grid-cols-4" }
const _hoisted_2 = { class: "document col-span-2 flex items-center truncate" }
const _hoisted_3 = { class: "buttons truncate" }
const _hoisted_4 = { class: "font-bold text-left name text-left truncate w-max-24" }
const _hoisted_5 = {
  key: 0,
  class: "font-bold text-white bg-secondary-600 rounded p-1 m-1 ml-0 text-sm w-32"
}
const _hoisted_6 = {
  key: 1,
  class: "font-bold text-white bg-green-600 rounded p-1 m-1 ml-0 text-sm w-32"
}
const _hoisted_7 = {
  key: 2,
  class: "flex"
}
const _hoisted_8 = { class: "grid grid-flow-col overflow-hidden" }
const _hoisted_9 = { class: "font-bold text-left name tag text-center text-primary-600 bg-white edit-document tag" }
const _hoisted_10 = { class: "delete flex items-center justify-end" }
const _hoisted_11 = {
  key: 0,
  class: "edit flex items-center justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueDocumentIcon = _resolveComponent("BlueDocumentIcon")!
  const _component_DeleteDocumentDialog = _resolveComponent("DeleteDocumentDialog")!
  const _component_AddEditDocumentModalDialog = _resolveComponent("AddEditDocumentModalDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.modelValue.requiresSignature)
        ? (_openBlock(), _createBlock(_component_BlueDocumentIcon, {
            key: 0,
            onClick: _ctx.openSignedDocument,
            mimeTypeSlag: _ctx.mimeTypeSlag
          }, null, 8, ["onClick", "mimeTypeSlag"]))
        : (_openBlock(), _createBlock(_component_BlueDocumentIcon, {
            key: 1,
            onClick: _ctx.openDocument,
            mimeTypeSlag: _ctx.mimeTypeSlag
          }, null, 8, ["onClick", "mimeTypeSlag"])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.modelValue.title), 1),
        (_ctx.modelValue.requiresSignature && !_ctx.documentIsSigned)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Firma richiesta"))
          : _createCommentVNode("", true),
        (_ctx.modelValue.requiresSignature && _ctx.documentIsSigned)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Firmato"))
          : _createCommentVNode("", true),
        (_ctx.modelValue.requiresSignature)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", {
                class: "text-sm font-bold text-primary-600 pr-4 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDocument && _ctx.openDocument(...args)))
              }, "Vedi originale"),
              (_ctx.documentIsSigned)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "text-sm font-bold text-primary-600 cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openSignedDocument && _ctx.openSignedDocument(...args)))
                  }, "Vedi firmato"))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValueTags, (tag) => {
            return (_openBlock(), _createElementBlock("h5", _hoisted_9, _toDisplayString(tag.name), 1))
          }), 256))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (!_ctx.isDocumentToBeApproved)
        ? (_openBlock(), _createElementBlock("h4", {
            key: 0,
            class: "block float-right font-bold cursor-pointer text-primary-600 truncate",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowingEditDialog = true))
          }, "Modifica documento"))
        : _createCommentVNode("", true),
      (_ctx.isDocumentToBeApproved)
        ? (_openBlock(), _createElementBlock("h4", {
            key: 1,
            class: "block float-right font-bold cursor-pointer bg-tertiary-100 text-tertiary-900 approve-document",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onApproveDocument && _ctx.onApproveDocument(...args)))
          }, "Approva documento"))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("h4", {
            class: "block float-right delete-button font-bold cursor-pointer",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShowingDeleteDialog = true))
          }, "Elimina")
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowingDeleteDialog)
      ? (_openBlock(), _createBlock(_component_DeleteDocumentDialog, {
          key: 1,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isShowingDeleteDialog = false)),
          documentId: _ctx.modelValue.id,
          "working-site-id": _ctx.workingSiteId,
          "communication-id": _ctx.communicationId,
          scope: _ctx.scope,
          "user-id": _ctx.userId,
          title: _ctx.modelValue.title
        }, null, 8, ["documentId", "working-site-id", "communication-id", "scope", "user-id", "title"]))
      : _createCommentVNode("", true),
    (_ctx.isShowingEditDialog)
      ? (_openBlock(), _createBlock(_component_AddEditDocumentModalDialog, {
          key: 2,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isShowingEditDialog = false)),
          onUpdate: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
          "model-value": _ctx.modelValue,
          "working-site-id": _ctx.workingSiteId,
          scope: _ctx.scope,
          "user-id": _ctx.userId,
          tags: _ctx.tags
        }, null, 8, ["model-value", "working-site-id", "scope", "user-id", "tags"]))
      : _createCommentVNode("", true)
  ]))
}