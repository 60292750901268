
import { defineComponent } from 'vue'
import DocumentCard from '@/components/DocumentCard.vue'
import { AddEditDocumentModalDialog } from '@/components/dialogs'
import { IDocumentModel } from '@sultan/shared'
import { DocumentScope } from '@/enums'

export default defineComponent({
  name: 'RelatedDocumentsSection',

  components: {
    AddEditDocumentModalDialog, DocumentCard
  },

  props: {
    modelValue: { type: Object as () => [IDocumentModel], required: false, default: undefined },
    scope: { type: Number as () => DocumentScope, required: true, default: DocumentScope.workingSiteDocument },

    workingSiteId: { type: String, required: false },
    userId: { type: String, required: false },
    communicationId: { type: String, required: false }
  },

  data () {
    return {
      isShowingDocumentDialog: false
    }
  }
})
