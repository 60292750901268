// Initialize firebase
import './utilities/firebase-utility'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { apiKey } from '../google-services.json'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
import VCalendar from 'v-calendar'

import { ClientTable } from 'v-tables-3'
import 'v-tables-3/compiled/themes/tailwind'

// Theme
import './assets/theme.scss'

// Add Tailwind
import './assets/tailwind.css'

// Add FontAwesome
library.add(fas, far, fab)

// Set Moment language
moment.locale('it')

createApp(App)
  .use(store)
  .use(router)
  .use(VueGoogleMaps, { load: { key: apiKey, libraries: 'places' } })
  .use(VCalendar)

  // Vue table 3
  .use(ClientTable, {}, 'tailwind', {}, {})
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
