
import { defineComponent } from 'vue'
import { ArchiveIcon } from '@heroicons/vue/outline'

import { PrimaryButton } from '@/components/buttons'
import { MenuButton, WorkingSiteCard } from '@/components'
import { AddEditWorkingSiteModalDialog } from '@/components/dialogs'
import { createNamespacedHelpers } from 'vuex'
import { IWorkingSitesStoreState } from '@/interfaces/working-sites-store-state.interface'
const { mapState } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSites',

  components: {
    MenuButton, PrimaryButton, ArchiveIcon, AddEditWorkingSiteModalDialog, WorkingSiteCard
  },

  data () {
    return {
      transitionName: '',
      addWorkingSite: false,
      isShowingArchivedWorkingSites: false,
      searchQuery: ''
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.workingSites)
      this.searchQuery = this.$store.state.workingSites.workingSitesSearchQuery
      await this.$store.dispatch('workingSites/reloadWorkingSites')
    } catch (e) {
      console.error('error dispatching the firestore mutation bindWorkingSitesRef')
      console.error(e)
    }
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      workingSites: function (state: IWorkingSitesStoreState) {
        return state.workingSites.filter(ws => ws.isArchived === (this as any).isShowingArchivedWorkingSites)
      },
      workingSitesSearchQuery: (state: IWorkingSitesStoreState) => state.workingSitesSearchQuery
    })
  },

  methods: {
    showNewWorkingSiteDialog: function () {
      this.addWorkingSite = true
    },
    hideNewWorkingSiteDialog: function () {
      this.addWorkingSite = false
    },
    search: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('workingSites/paginateWorkingSites', { forceReload: true, query: this.searchQuery })
    },
    paginate: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
      await this.$store.dispatch('workingSites/paginateWorkingSites', { forceReload: false, query: this.searchQuery })
    }
  },

  watch: {
    workingSitesSearchQuery: function (value) {
      console.log('received a search query', { value })
      this.searchQuery = value
    }
  }
})
