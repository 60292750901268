
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { LeftArrow } from '@/components/icons'
import WorkingSiteTagCard from '@/components/WorkingSiteTagCard.vue'
import { createNamespacedHelpers } from 'vuex'
import { IWorkingSitesStoreState } from '@/interfaces'
import Loader from '@/components/Loader.vue'
import { AlertType } from '@/enums'

const { mapState, mapActions } = createNamespacedHelpers('workingSites')

export default defineComponent({
  name: 'WorkingSiteTags',

  components: {
    LeftArrow, PrimaryButton, WorkingSiteTagCard, Loader
  },

  data () {
    return {
      name: '',
      loading: false
    }
  },

  computed: {
    ...mapState<IWorkingSitesStoreState>({
      tags: function (state: IWorkingSitesStoreState) {
        // eslint-disable-next-line eqeqeq
        return state.workingSite == undefined ? [] : Object.values(state.workingSite.tags).sort((t1, t2) => t2.createdTs - t1.createdTs)
      }
    }),
    id: function () {
      return this.$route.params.workingSiteId
    }
  },

  watch: {
    '$route.params.workingSiteId': {
      handler: async function (id) {
        console.log(id)
        await this.bindWorkingSite()
      },
      deep: true,
      immediate: true
    }
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    console.log(`got id: ${this.$route.params.workingSiteId}`)
    await this.bindWorkingSite()
  },

  async beforeUnmount () {
    await this.unbindWorkingSite()
  },

  methods: {
    ...mapActions(['addWorkingSiteTag']),
    goBack () {
      return this.$router.back()
    },

    bindWorkingSite: async function () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.workingSiteId == undefined || typeof this.$route.params.workingSiteId !== 'string') {
        // TODO: Pop back
        return
      }

      try {
        await this.$store.dispatch('workingSites/bindWorkingSiteRef', { id: this.$route.params.workingSiteId })
      } catch (e) {
        console.error('error dispatching the firestore mutation bindWorkingSiteRef')
        console.error(e)
      }
    },
    unbindWorkingSite: async function () {
      try {
        await this.$store.dispatch('workingSites/unbindWorkingSiteRef')
      } catch (e) {
        console.error('error dispatching the firestore mutation unbindWorkingSiteRef')
        console.error(e)
      }
    },

    onAddTag: async function () {
      this.loading = true

      try {
        const result = await this.addWorkingSiteTag({ name: this.name, workingSiteId: this.id })

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione evento fallita',
            type: AlertType.error
          })
          return
        }

        this.name = ''
      } catch (error) {
        console.error('error creating the event', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'creazione evento fallita',
          type: AlertType.error
        })
      }
    }
  }

})
