import { IAlgoliaToolItemModel, IAssignmentModel, IToolItemModel, IToolModel } from '@sultan/shared'
import { toolItemsCollection, toolItemsIndex, toolsCollection, usersCollection } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IToolsStoreState } from '../interfaces'
import firebase from 'firebase'

const hitsPerPage = 15

export const ToolsStore: Module<IToolsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    tools: [] as IToolModel[],
    toolItem: undefined as IToolItemModel | undefined,
    toolItems: [] as IToolItemModel[],
    toolItemsSearchQuery: '',
    toolItemsPage: 0
  },

  mutations: {
    ...vuexfireMutations,

    setTools (state, tools) {
      console.log('ToolItemsStore - setToolItems', { tools })
      state.tools = tools
    },
    setToolItems (state, { toolItems, page }) {
      console.log('ToolItemsStore - setToolItems', { toolItems, page })
      state.toolItems = toolItems
      state.toolItemsPage = page
    },
    setToolItemsSearchQuery (state, toolItemsSearchQuery) {
      state.toolItemsSearchQuery = toolItemsSearchQuery
    },
    resetTool (state, toolItems) {
      state.toolItems = toolItems
      state.toolItemsSearchQuery = ''
      state.toolItemsPage = 0
    }
  },

  actions: {
    async addTool (
      context: ActionContext<IToolsStoreState, unknown>,
      tool: Partial<IToolModel>
    ): Promise<boolean> {
      console.log('ToolItemsStore - addTool', { tool })
      try {
        await toolsCollection.doc(tool.id).set(tool)
      } catch (error) {
        console.error('ToolItemsStore - error creating the tool', { tool, error })
        return false
      }

      return true
    },

    deleteTool: firestoreAction(async (context, payload: { toolId: string }): Promise<boolean> => {
      console.debug('ToolItemsStore - deleteTool', { payload })
      if (payload === undefined || payload.toolId === undefined) { return false }

      try {
        await toolsCollection
          .doc(payload.toolId)
          .delete()
      } catch (error) {
        console.error('ToolItemsStore - error removing the working site document', { context, error })

        return false
      }

      console.debug('ToolItemsStore - deleteTool - success')

      return true
    }),

    async addToolItem (
      context: ActionContext<IToolsStoreState, unknown>,
      toolItem: Partial<IToolItemModel>
    ): Promise<boolean> {
      console.log('ToolItemsStore - addToolItem', { toolItem })

      if (!toolItem.toolId) {
        return false
      }

      try {
        await toolItemsCollection(toolItem.toolId)
          .doc(toolItem.id)
          .set(toolItem as any)
      } catch (error) {
        console.error('ToolItemsStore - error creating the tool item', { toolItem, error })
        return false
      }

      return true
    },

    deleteToolItem: firestoreAction(async (context, payload: {
      toolId: string,
      toolItemId: string
    }): Promise<boolean> => {
      console.debug('ToolItemsStore - deleteToolItem', { payload })
      if (payload === undefined || payload.toolId === undefined || payload.toolItemId === undefined) { return false }

      try {
        await toolItemsCollection(payload.toolId)
          .doc(payload.toolItemId)
          .delete()
      } catch (error) {
        console.error('ToolItemsStore - error removing the working site document', { context, error })

        return false
      }

      console.debug('ToolItemsStore - deleteToolItem - success')

      return true
    }),

    async reloadTools (
      context: ActionContext<IToolsStoreState, unknown>
    ): Promise<boolean> {
      console.log('ToolItemsStore - reloadTools')
      try {
        const result = await toolsCollection.get()
        context.commit('setTools', result.docs.map(d => d.data()))
      } catch (error) {
        console.error('ToolItemsStore - error getting the tools', { context, error })
        return false
      }

      return true
    },

    async reloadToolItems (
      context: ActionContext<IToolsStoreState, unknown>,
      params?: { facetFilters?: string | readonly string[] | ReadonlyArray<readonly string[]>; }
    ): Promise<boolean> {
      console.log('ToolItemsStore - reloadToolItems')
      try {
        const result = await toolItemsIndex.search('', { hitsPerPage: hitsPerPage, cacheable: false, facetFilters: params ? params.facetFilters : undefined })
        context.commit('resetTool', result.hits)
      } catch (error) {
        console.error('ToolItemsStore - error searching inside the toolItems index', { context, error })
        return false
      }

      return true
    },

    async paginateToolItems (
      context: ActionContext<IToolsStoreState, unknown>,
      params: { forceReload?: boolean, query: string, facetFilters?: string | readonly string[] | ReadonlyArray<readonly string[]>; }
    ): Promise<void> {
      console.log('ToolItemsStore - paginateToolItems', params)
      context.commit('setToolItemsSearchQuery', params.query)

      const page = params.query === context.state.toolItemsSearchQuery && !params.forceReload
        ? context.state.toolItemsPage + 1
        : 0

      try {
        const result = await toolItemsIndex.search(params.query, { hitsPerPage: hitsPerPage, page, cacheable: false, facetFilters: params.facetFilters })
        console.log('ToolItemsStore - paginateToolItems - result', result)
        context.commit(
          'setToolItems',
          {
            page,
            toolItems: params.forceReload === true ? result.hits : [...context.state.toolItems, ...result.hits]
          }
        )
      } catch (error) {
        console.error('ToolItemsStore - error searching inside the toolItems index', { context, params, error })
      }
    },

    async instantGetToolItems (
      context: ActionContext<IToolsStoreState, unknown>,
      ids: string[]
    ): Promise<IAlgoliaToolItemModel[]> {
      console.log('ToolItemsStore - instantGetToolItems', { ids })

      try {
        const result = await toolItemsIndex.getObjects(ids, { cacheable: false })
        console.log('ToolItemsStore - instantGetToolItems - result', result)

        return result.results as IAlgoliaToolItemModel[]
      } catch (error) {
        console.error('ToolItemsStore - instantGetToolItems - error getting the toolItems from the index', { context, ids, error })
        console.error(error)

        return []
      }
    },

    async instantSearchToolItems (
      context: ActionContext<IToolsStoreState, unknown>,
      params: {
        query: string,
        facetFilters?: string | readonly string[] | undefined,
        hitsPerPage?: number | undefined,
        page?: number | undefined
      }
    ): Promise<IAlgoliaToolItemModel[]> {
      console.log('ToolItemsStore - instantSearchToolItems', params)

      try {
        const result = await toolItemsIndex.search(
          params.query,
          {
            facetFilters: params.facetFilters,
            hitsPerPage: params.hitsPerPage,
            page: params.page,
            cacheable: false
          }
        )
        console.log('ToolItemsStore - instantSearchToolItems - result', result)

        return result.hits as IAlgoliaToolItemModel[]
      } catch (error) {
        console.error('ToolItemsStore - instantSearchToolItems - error searching inside the toolItems index', { context, params, error })

        return []
      }
    },

    getToolItem: firestoreAction(async (context, params: { id: string, toolId: string }): Promise<IToolItemModel | undefined> => {
      console.debug('ToolsStore - getToolItem', { params })

      try {
        const result = await toolItemsCollection(params.toolId).doc(params.id).get()
        console.log('ToolsStore - getToolItem - result', result)

        if (!result.exists) { return undefined }

        return result.data()
      } catch (error) {
        console.error('UsersStore - getUser - error getting a working site', { params, error })
        console.error(error)

        return undefined
      }
    }),

    // Tool
    bindToolRef: firestoreAction((context, payload: { toolId: string, toolItemId: string }) => {
      console.debug(payload)
      console.debug('bindToolRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('toolItem', toolItemsCollection(payload.toolId).doc(payload.toolItemId))
    }),
    unbindToolRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindToolRef')
      unbindFirestoreRef('toolItem')
    }),

    addToolAssignments: firestoreAction(async (context, payload: {
      toolItemIds: string[],
      userId: string
    }): Promise<boolean> => {
      console.debug('updateUserAssignmentStatus', { payload })
      if (
        payload === undefined
        || payload.toolItemIds === undefined
        || payload.userId === undefined
      ) { return false }

      try {
        usersCollection.doc(payload.userId).update(
          payload.toolItemIds.reduce(
            (acc: any, id: string) => {
              acc[`toolAssignments.${id}`] = {
                createdTs: Math.round((new Date()).valueOf() / 1000),
                id,
                isEnabled: true,
                userId: payload.userId
              } as IAssignmentModel

              return acc
            },
            {}
          )
        )

        return true
      } catch (error) {
        console.error('ToolItemsStore - instantSearchToolItems - error searching inside the toolItems index', { context, error })

        return false
      }
    }),
    updateUserAssignmentStatus: firestoreAction(async (context, payload: {
      toolItemId: string,
      userId: string,
      isEnabled: boolean
    }): Promise<boolean> => {
      console.debug('updateUserAssignmentStatus', { payload })
      if (
        payload === undefined
        || payload.toolItemId === undefined
        || payload.userId === undefined
        || payload.isEnabled === undefined
      ) { return false }

      usersCollection.doc(payload.userId).update({
        [`toolAssignments.${payload.toolItemId}.isEnabled`]: payload.isEnabled
      })

      console.debug('updateUserAssignmentStatus - success')

      return true
    }),
    removeUserAssignment: firestoreAction(async (context, payload: {
      toolItemId: string,
      userId: string
    }): Promise<boolean> => {
      console.debug('removeUserAssignment', { payload })
      if (payload === undefined || payload.toolItemId === undefined || payload.userId === undefined) { return false }

      usersCollection.doc(payload.userId).update({
        [`toolAssignments.${payload.toolItemId}`]: firebase.firestore.FieldValue.delete()
      })

      console.debug('removeUserAssignment - success')

      return true
    })
  }
}
