
import { defineComponent } from 'vue'

import { PrimaryButton, MenuButton } from '@/components'

export default defineComponent({
  name: 'Settings',

  components: {
    MenuButton, PrimaryButton
  },

  data () {
    return {
      transitionName: '',
      links: [
        { url: 'users', title: 'Utenti' },
        { url: 'info', title: 'Info' }
        /* { url: 'mobile-app', title: 'Applicazione' },
        { url: 'security', title: 'Sicurezza' },
        { url: 'notifications', title: 'Notifiche' },
        { url: 'settings', title: 'Preferenze' },
        { url: 'travel-hours', title: 'Ore di viaggio' } */
      ]
    }
  }
})
