import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-623f84ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-site-ppe-card mb-2 grid grid-cols-2 text-left overflow-hidden" }
const _hoisted_2 = {
  key: 1,
  class: "font-bold user-tab-element self-center"
}
const _hoisted_3 = { class: "working-site-users-buttons flex text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isEditing)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            class: "font-bold user-tab-element self-center",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.modelValue]
          ])
        : (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.modelValue), 1)),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 0,
              class: "font-bold user-tab-element delete-button ml-auto cursor-pointer",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPpeDelete && _ctx.onPpeDelete(...args)))
            }, "Elimina"))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          message: "Salvataggio..."
        }))
      : _createCommentVNode("", true)
  ], 64))
}